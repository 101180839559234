.cc-ad {
    width: 100%;
    height: 250px;


    .swiper-slide {
        background-color: #92E0D5;
        display: flex;
        justify-content: center;
    }

    &__container {
        padding: 0 15px;
        width: 1230px;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        @media only screen and (max-width: 425px) {
            padding: 0 2.5px;
        }
    }

    &__image {
        &.-first {


            @media only screen and (max-width: 768px) {
                width: 50%;
            }
        }

        &.-third {
            width: 30%;
            height: 75px;

            @media only screen and (max-width: 768px) {
                height: 50px;
            }

            @media only screen and (max-width: 425px) {
                width: 50%;
            }
        }

        &.-close {
            position: relative;
            margin-bottom: -1.25rem;
            margin-right: 1rem;
            cursor: pointer;
            z-index: 2;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &__slide {
        height: 100%;
        display: flex;
        justify-content: space-between;

        &.-first {
            div {
                width: 50%;
            }

            h3 {
                font-size: 25px;
                color: #6A6A6A;
            }

            h2 {
                font-size: 35px;
                color: #4D4D4F;
            }

            @media only screen and (max-width: 768px) {
                h3 {
                    font-size: 20px;
                }

                h2 {
                    font-size: 30px;
                }
            }

            @media only screen and (max-width: 425px) {
                h3 {
                    font-size: 15px;
                }

                h2 {
                    font-size: 25px;
                }
            }
        }

        &.-second {

            h2 {
                font-size: 45px;
                color: #4D4D4F;
            }

            @media only screen and (max-width: 768px) {

                h2 {
                    font-size: 35px;
                }
            }

            @media only screen and (max-width: 425px) {

                h2 {
                    font-size: 30px;
                }
            }
        }

        &.-third {
            text-align: center;

            h2 {
                font-size: 25px;
            }

            h3 {
                font-size: 35px;
                color: #F8433A;
            }

            @media only screen and (max-width: 768px) {
                h2 {
                    font-size: 20px;
                }

                h3 {
                    font-size: 30px;
                }
            }

            @media only screen and (max-width: 425px) {
                h2 {
                    font-size: 15px;
                    display: none;
                }

                h3 {
                    font-size: 25px;
                }
            }
        }

        &.-fourth {

            h3 {
                font-size: 15px;
            }

            @media only screen and (max-width: 768px) {

                h3 {
                    font-size: 12.5px;
                }
            }

            @media only screen and (max-width: 425px) {

                h3 {
                    font-size: 10px;
                }
            }
        }

    }

    &__button {
        color: white;
        background-color: #F8433A;
        padding: 1rem;
        text-align: center;
        cursor: pointer;
        border-radius: 2rem;
        width: 350px;

        @media only screen and (max-width: 768px) {
            width: 200px;
        }

        @media only screen and (max-width: 425px) {
            width: 150px;
        }

        &.-third {
            @media only screen and (max-width: 425px) {
                display: none;
            }
        }

        &:hover {
            background-color: #D32527;
        }
    }

    &__logo {
        margin: 50px 0px;
        padding-right: 50px;
        border-right: 1px solid black;
        display: flex;
        flex-direction: column;
        justify-content: center;


        @media only screen and (max-width: 768px) {
            display: none !important;
        }
    }

    &__sponsors {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;

        div img {
            max-height: 100px;
            width: 220px;
        }


        @media only screen and (max-width: 768px) {

            div img {
                width: 150px !important;
                margin-top: 0.5rem;
            }

        }
    }
}

.bottom-promo {
    width: 100%;
    height: 250px;
    background-color: lightblue;

    &__container {
        padding: 0 15px;
        width: 1230px;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        @media only screen and (max-width: 425px) {
            padding: 0 2.5px;
        }
    }

    &__image {
        &.-close {
            position: relative;
            margin-top: -1.05rem;
            margin-right: 1rem;
            cursor: pointer;
            z-index: 2;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}


.top-promo {
    width: 100%;
    height: 75px;
    background-color: lightblue;

    &__container {
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media only screen and (max-width: 768px) {
            width: 100%;
        }

        @media only screen and (max-width: 425px) {
            padding: 0 2.5px;
        }
    }

    &__image {
        &.-close {
            position: relative;
            margin-top: -1.05rem;
            margin-right: 1rem;
            cursor: pointer;
            z-index: 2;

            &:hover {
                opacity: 0.8;
            }
        }
    }
}