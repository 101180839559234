// HEADER //

////// 2022 //////
/// CSC ///
.header-csc2022 {
  overflow: hidden;
  color: white;

  &.-full {
    background: no-repeat center center;
    min-height: 750px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &.-reduced {
    min-height: 150px;
  }

  &__title {
    text-shadow: 0px 0px 1rem black;
    margin-bottom: -0.5rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  &__eventName {
    margin: 0 10px;

    img {
      width: 100%;
    }
  }
}

/// CAP ///
.header-cap2022 {
  overflow: hidden;
  color: white;

  a,
  p {
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

  &.-full {
    background: no-repeat center center;
    min-height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &.-reduced {
    min-height: 150px;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }
  }

  &__info {
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__logo {
    padding-right: 1rem;
    border-right: 1px solid white;

    @media screen and (max-width: 800px) {
      margin-bottom: 1rem;

      padding-right: 0rem;
      padding-bottom: 1rem;

      border-bottom: 1px solid white;
      border-right: 0px;
    }
  }

  &__anniversary {
    width: 175px;
  }

  &__spacetime {
    padding-left: 1rem;

    @media screen and (max-width: 800px) {
      padding-left: 0rem;
      margin-bottom: 1rem;
    }
  }

  &__ufc {
    div {
      color: white;
      white-space: nowrap;
      border: 1px solid white;
      padding: 0.5rem 2rem;
    }

    @media screen and (max-width: 800px) {
      margin-bottom: 1rem;

      a {
        background-color: #005cb4;
      }
    }
  }
}

////// 2023 //////
/// CC ///
.header-cc2023 {
  overflow: hidden;

  &.-full {
    background: no-repeat center center;
    min-height: 500px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    @media screen and (max-width: 768px) {
      min-height: 350px;
    }
  }

  &.-reduced {
    min-height: 150px;
  }

  &__text {
    margin-left: 2rem;
    width: 35%;

    @media screen and (max-width: 768px) {
      img {
        max-width: 300px;
      }
    }

    @media screen and (max-width: 425px) {
      width: 90%;
      margin: 0rem 1rem;
      color: white;
      text-shadow: 0px 0px 1rem black;

      img {
        margin-top: 5rem;
        max-width: 200px;
      }
    }
  }
}

/// JAD ///
.header-jad2023 {
  overflow: hidden;

  background: no-repeat center center;
  height: 500px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 350px;
    background-position-x: -10rem;
  }

  @media screen and (max-width: 425px) {
    background-position-x: -16rem;
    height: 250px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 30rem;

    img {
      margin-bottom: 1rem;
      width: 450px;
    }

    h2 {
      font-size: 40px;
    }

    p {
      font-size: 20px;
      padding: 0 1rem;
    }

    @media screen and (max-width: 768px) {
      margin-left: 15rem;

      img {
        margin-bottom: 1rem;
        max-width: 300px;
      }

      h2 {
        font-size: 30px;
      }

      p {
        font-size: 12px;
      }
    }

    @media screen and (max-width: 425px) {
      margin-left: 0rem;

      img {
        margin-bottom: 0.25rem;
        max-width: 200px;
      }

      h2 {
        font-size: 20px;
      }
    }
  }
}

/// CSC ///
.header-csc2023 {
  overflow: hidden;

  background: no-repeat center center;
  height: 550px;
  margin-top: 3rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    height: 350px;
  }

  @media screen and (max-width: 425px) {
    height: 250px;
  }
}

/// CAP ///
.header-cap2023 {
  overflow: hidden;

  background: no-repeat center center;
  max-height: 600px;
  margin-top: 3rem;
  padding: 4rem 0rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  img {
    width: 100%;
    max-width: 650px;

    &:nth-child(2) {
      max-width: 500px;
    }
  }

  &__top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 33px;
    }

    img {
      width: 40%;
      border-right: 1px solid white;
      padding-right: 1rem;
      margin-right: 1.5rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }

    div {
      color: white;
      text-align: center;

      @media screen and (max-width: 768px) {
        margin: 1.5rem 0;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0rem;
    }
  }

  @media screen and (max-width: 768px) {
    height: 450px;
  }
}

/// SV ///
.header-sv2023 {
  overflow: hidden;

  background: no-repeat center center;
  max-height: 600px;
  margin-top: 3rem;
  padding: 2rem 0rem 4rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  color: white;

  h3 {
    font-size: 22px;
    margin: 1rem 0;

    @media only screen and (max-width: 425px) {
      font-size: 20px;
    }
  }

  &__bot {
    border: 3px solid #d1212d;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      padding: 0.5rem 1rem;

      &:nth-child(1) {
        background-color: #d1212d;
      }
    }
  }

  img {
    width: 100%;
    max-width: 480px;
  }

  @media screen and (max-width: 768px) {
    height: 450px;
  }
}

////// 2024 //////
.header-cc2024 {
  overflow: hidden;

  background: no-repeat center center;
  min-height: 500px;
  margin-top: 3rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  img {
    width: 100%;
    max-width: 650px;

    &:nth-child(2) {
      max-width: 500px;
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;

    h2 {
      font-size: 33px;
    }

    img {
      width: 40%;
      border-right: 1px solid white;
      padding-right: 1rem;
      margin-right: 1.5rem;

      @media screen and (max-width: 768px) {
        width: 100%;
        border-right: none;
        margin-right: 0;
        padding-right: 0;
      }
    }

    div {
      color: white;
      text-align: center;

      @media screen and (max-width: 768px) {
        margin: 1.5rem 0;
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 0rem;
    }
  }

  @media screen and (max-width: 768px) {
    height: 450px;
  }
}

.header-jad2024 {
  overflow: hidden;

  background: no-repeat;
  min-height: 526px;
  margin-top: 1.5rem;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;

  h2 {
    font-size: 25px;
    color: white;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

  img {
    width: 100%;
    max-width: 325px;
    align-self: flex-end;
    filter: drop-shadow(0px 5px 10px #000000);
  }

  @media screen and (max-width: 1024px) {
    min-height: 425px;
  }

  @media screen and (max-width: 768px) {
    background: no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // height: 450px;

    justify-content: flex-end;

    img {
      align-self: center;
      margin-bottom: 1rem;
    }
  }
}

.header-jad2025 {
  overflow: hidden;
  position: relative;

  background: lightgray no-repeat;
  height: 446px;
  margin-top: 1.5rem;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.74;
    background: linear-gradient(0deg, #2A6E83 16.61%, rgba(55, 55, 55, 0.00) 50.34%);
    mix-blend-mode: multiply;
  }


  @media screen and (max-width: 768px) {
    height: 350px;
    padding: 1rem;
    background-size: auto;
    background-position: center;
  }


  &__logo {
    width:100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap:20px;

    img {
      width: 326.87px;
      height: 105.164px;

      @media (max-width: 777px) {
      width: 150px;
      height: 55px;
    
    }
      
    }

    p {
      color: #D4E2E6;
      font-family: Graphik-Regular;
      font-size: 27px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px;
      text-transform: uppercase;
      opacity:0.8;
      padding-left:10px;

      @media (max-width: 777px) {
        font-size: 20px;
        }
  
        @media (max-width: 425px) {
          font-size: 16px;
        }
    }

  }

  &__text {
    color: #FFF;
    font-family: Graphik-Semibold;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 68px;
    text-align: center;
    z-index: 3;

    @media(max-width:777px) {
      font-size: 34px;
    }

    @media (max-width: 425px) {
      font-size: 24px;
    }

    span {
      color: #FFA913;
    }
  }
}

.header-cap2024 {
  overflow: hidden;
  background: no-repeat
    url("https://media.portail-assurance.ca/Events/Cap/2024/Header/cap2024-desktop-header_1Kgyjmdct.png?updatedAt=1722262196684");
  margin-top: 1.5rem;
  min-height: 370px;
  display: flex;
  align-items: center;

  &__text {
    max-width: 285px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #454545;
    margin-left: 3rem;

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 16px;
    }

    img {
      width: 350px;
    }

    @media screen and (max-width: 425px) {
      flex-direction: column;
      margin-left: 0rem;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 425px) {
    // height: 450px;
    min-height: 535px;
    align-items: flex-start;
    justify-content: center;
    background-image: url("https://media.portail-assurance.ca/Events/Cap/2024/Header/cap2024-mobile-header_EIbRU7GoP.png?updatedAt=1722262196403");
  }
}

.header-csc2024 {
  overflow: hidden;
  position: relative;
  background: no-repeat center center;
  height: 450px;
  margin-top: 3rem;
  padding: 2rem 3rem;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background: linear-gradient(180deg, #023F51 0%, #15141F 100%);

  display: flex;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    height: 500px;
  }


  @media screen and (max-width: 768px) {
    padding: 1rem;
    height: 550px;
  }

  @media screen and (max-width: 500px) {
    padding: 1rem 0;
  }

  &__people{
    position: absolute;
    bottom: 0;
    left: 50% !important;
    transform: translate(-50%);

    max-width: 630px;

    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 550px;
    }

    @media screen and (max-width: 500px) {
      padding: 0;
    }


    
  }

  &__content{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    h2{
      font-size: 40px;
      @media screen and (max-width: 768px) {
        font-size: 35px;
      }
    }

    h3{
      font-size: 30px;
      @media screen and (max-width: 768px) {
        font-size: 25px;
      }
    }
    img{
      width: 100%;
      max-width: 300px;
      margin-bottom: 0.75rem;
    }

    @media screen and (max-width: 500px) {
      text-align: center;
      flex-direction: column-reverse;
      justify-content: flex-end;
      align-items: center;
    }
  }

  div{
    z-index: 100;
  }

  // @media screen and (max-width: 768px) {
  //   height: 700px;
  // }

  // @media screen and (max-width: 425px) {
  //   height: 750px;
  // }

}

////// 2025 //////
.header-cc2025 {
  overflow: hidden;
  position: relative;
  background: no-repeat center center;
  min-height: 438px;
  height: 438px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  @media screen and (max-width: 434px) {
    min-height: 250px;
  }

  &__text {
    position: absolute;
    left:0;
    top:50%;
    transform: translateY(-50%);

    @media screen and (max-width: 996px) {
      transform: none;
      top:50px;
    }

    @media screen and (max-width: 434px) {
      transform: none;
      top:0;
    }

    h2 {
      font-size: 43px;

      @media screen and (max-width: 996px) {
        font-size: 30px;
      }

      @media screen and (max-width: 434px) {
        font-size: 18px;
      }
    }

    h3 {
      font-size: 23px;
      font-weight: 400;

      @media screen and (max-width: 996px) {
        font-size: 16px;
      }

      @media screen and (max-width: 434px) {
        font-size: 10px;
      }
    }

    &__logo {
      width: 580px;

      @media screen and (max-width: 996px) {
        width: 450px;
      }

      @media screen and (max-width: 434px) {
        width: 300px;
      }
    }

    div {
      color: white;
      transform: translate(75px, -45px);

      @media screen and (max-width: 996px) {
        transform: translate(55px, -30px);
      }

      @media screen and (max-width: 434px) {
        transform: translate(35px, -10px);

      }
    }
  }

  @media screen and (max-width: 996px) {
    height: 450px;
  }

  @media screen and (max-width: 434px) {
    height: 250px;
  }
}