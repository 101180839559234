// HEADER //

////// 2022 //////
/// CSC ///
.home-csc2022 {
  &__section {
    display: flex;
    justify-content: center;

    div:first-child {
      max-width: 750px;
      padding: 1rem 1rem;
    }

    &.-grey {
      background-color: rgb(241, 241, 241);
    }

    &.-gold {
      background-color: #ebb46e;
    }

    &.-first {
      max-width: 750px;
    }

    &.-second {
      background: no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      div {
        color: white;
        padding: 10rem 1rem;
      }
    }

    &.-forth {
      div {
        div {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: space-between;
        }

        div:first-child {
          text-align: start;

          @media screen and (max-width: 700px) {
            flex-wrap: wrap;
          }

          p {
            margin: 0rem 1rem;
          }
        }

        div:last-child {
          flex-direction: column;
        }
      }
    }

    &.-sixth {
      text-align: center;

      div {
        div {
          p {
            margin-bottom: 0rem;
          }

          a {
            color: black;
          }
        }
      }
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      padding: 1rem;
      max-width: 400px;
    }

    img {
      width: 200px;
    }

    &.-second {
      @media screen and (max-width: 800px) {
        flex-direction: column-reverse;
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      text-align: center;
    }
  }
}

/// CAP ///
.home-cap2022 {
  &__section {
    &.-first {
      margin-left: 6rem;
      margin-right: 6rem;

      @media screen and (max-width: 800px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    &.-second {
      min-height: 450px;

      display: flex;
      justify-content: center;
      align-items: center;

      background: no-repeat center center;
      background-size: cover;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;

      @media screen and (max-width: 800px) {
        margin-left: 0rem;
        margin-right: 0rem;
      }

      & > * {
        font-size: 45px;
        color: white;
        margin: 0rem 5rem;

        @media screen and (max-width: 800px) {
          font-size: 40px;
          margin: 2rem 1rem;
        }
      }
    }

    &.-third {
      margin-left: 14rem;
      margin-right: 14rem;

      @media screen and (max-width: 800px) {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      li {
        font-size: 20px;
        margin: 1rem 0rem;
      }
    }

    &.-forth {
      min-height: 350px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

////// 2023 //////
/// CC ///
.home-cc2023 {
  &__section {
    &.-first {
      padding: 5rem 10rem;

      @media only screen and (max-width: 425px) {
        padding: 5rem 2rem;
      }
    }

    &.-second {
      display: flex;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5rem 5rem;

        @media only screen and (max-width: 425px) {
          padding: 5rem 2rem;
        }

        ul {
          align-self: flex-start;
        }
      }
    }

    &.-swiper {
      margin: 5rem 0rem 0rem;
      padding: 5rem 2rem;

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }
    }
  }
}

/// JAD ///
.home-jad2023 {
  &__section {
    &.-first {
      display: flex;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 4rem 7rem;

        @media only screen and (max-width: 425px) {
          padding: 4rem 2rem;
        }

        ul {
          align-self: flex-start;
        }
      }
    }

    &.-second {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;

      padding: 4rem 10rem;

      @media only screen and (max-width: 425px) {
        padding: 4rem 2rem;
      }
    }

    &.-swiper {
      padding: 5rem 2rem 0rem;
      overflow: hidden;

      .swiper {
        overflow: visible !important;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }

      .swiper-slide {
        height: 100%;

        a {
          color: black;
          background-color: #f9f9f9;
          height: 525px;
        }

        h2 {
          color: #ffa913;
          font-size: 33px;
        }

        p {
          margin-bottom: 0;
        }

        img {
          margin-top: -5rem;
        }

        div div {
          width: 2rem;

          hr {
            margin-top: 1rem;
            margin-bottom: 1rem;
            border-top: 2px solid black;
            width: 2rem;
          }
        }

        @media only screen and (max-width: 768px) {
          .swiper {
            height: 200px;
          }
        }

        &:hover {
          a {
            text-decoration: none;
            background-color: #ffa913;
          }

          h2,
          h3,
          p {
            color: white;
          }

          hr {
            border-top: 2px solid white;
          }
        }
      }
    }

    &.-swiper2 {
      h3 {
        font-size: 35px;
      }

      h2 {
        font-size: 40px;
      }

      @media only screen and (max-width: 768px) {
        h3 {
          font-size: 23px;
        }

        h2 {
          font-size: 27px;
        }
      }

      @media only screen and (max-width: 425px) {
        h2,
        h3 {
          text-align: center;
        }

        img {
          margin-top: 0.5rem;
          width: 200px;
        }
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }

      .swiper-slide {
        height: 425px;
        width: 100%;
        padding: 1rem 7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          width: 50%;
        }

        @media only screen and (max-width: 768px) {
          padding: 1rem 4rem;
        }

        @media only screen and (max-width: 425px) {
          height: 500px;
          flex-direction: column;
          justify-content: center;

          div {
            width: 100%;
          }
        }

        &.-reverse {
          flex-direction: row-reverse;

          @media only screen and (max-width: 425px) {
            flex-direction: column;
            justify-content: center;
          }
        }
      }

      .swiper-wrapper {
        color: black;

        &:hover {
          color: black;
          text-decoration: none;
        }
      }
    }

    &.-swiper3 {
      padding: 2rem 2rem 0rem;

      .swiper-button-prev,
      .swiper-button-next {
        width: 50px;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }
    }
  }
}

/// CSC ///
.home-csc2023 {
  &__section {
    margin: 2rem 0rem;

    &.-first {
      min-height: 500px;
      color: white;
      width: 100%;

      background: url("https://media.portail-assurance.ca/Events/Csc/2023/HomePage/csc_crowd%201_q0tHXwvUf.png?updatedAt=1691002618988")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 850px) {
        flex-direction: column;
      }

      h2 {
        font-size: 100px;
        line-height: 6rem;
        text-align: end;
        margin-right: 5rem;

        @media only screen and (max-width: 850px) {
          font-size: 75px;
          text-align: center;
          margin: 1rem 0;
          line-height: 4.5rem;
        }

        @media only screen and (max-width: 425px) {
          font-size: 50px;
          line-height: 3rem;
        }
      }

      ul {
        li {
          font-size: 27px;

          @media only screen and (max-width: 425px) {
            font-size: 23px;
          }
        }
      }
    }

    &.-second {
      display: flex;
      text-align: center;
      justify-content: center;

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        align-items: center;
      }

      h3 {
        font-size: 34px;
        color: #4a4a4a;
        z-index: 2;
      }

      p {
        font-size: 20px;
        margin-bottom: 0rem;
      }

      div {
        max-width: 580px;
        min-height: 300px;
        width: 100%;

        @media only screen and (max-width: 1024px) {
          max-width: 800px;
          min-height: none;
        }
      }

      div div {
        position: relative;
        width: 100%;

        img {
          position: absolute;
          left: 50% !important;
          top: 2.5rem;
          transform: translate(-50%, -50%);
          opacity: 0.75;
          z-index: 1;
        }
      }
    }

    &.-third {
      min-height: 500px;
      width: 100%;

      background: url("https://media.portail-assurance.ca/Events/Csc/2023/HomePage/jochem-raat-s0grRYEDaL4-unsplash%202_6lmskaJjw.png?updatedAt=1691018793847")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 850px) {
        flex-direction: column;
      }

      h2 {
        font-size: 150px;
      }

      p {
        font-size: 20px;
        width: 65%;

        @media only screen and (max-width: 850px) {
          width: 100%;
        }
      }
    }

    &.-fifth {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      div {
        min-width: 32%;

        @media only screen and (max-width: 768px) {
          min-width: 75%;
          margin: 2rem 0rem;
        }
      }

      h2 {
        font-size: 150px;
        line-height: 7rem;
      }

      p {
        font-size: 34px;
        width: 65%;
        margin-bottom: 0rem;

        @media only screen and (max-width: 850px) {
          width: 100%;
        }
      }
    }

    &.-sixth {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 4rem 2rem;
      text-align: center;

      h2 {
        color: white;
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }

      a {
        text-decoration: underline;
        color: black;
      }
    }
  }

  &__content {
    display: flex;
    margin: 1rem 0rem;

    img {
      margin-right: 1rem;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.-reverse {
      display: flex;
      flex-direction: row-reverse;

      img {
        margin-right: 0;
        margin-left: 1rem;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    div {
      width: 100%;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2.5rem;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 1rem;
      }

      h2 {
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

/// CAP ///
.home-cap2023 {
  &__section {
    margin: 2rem 0rem;
    color: white;

    h2 {
      font-size: 32px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 28px !important;
      }
    }

    h3 {
      font-size: 22px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 20px;
      margin-bottom: 0;
      width: auto;
    }

    &.-first {
    }

    &.-second {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        div {
          @media only screen and (max-width: 768px) {
            flex-direction: column;
          }
        }

        p {
          @media only screen and (max-width: 768px) {
            text-align: center !important;
          }
        }
      }
    }

    &.-third {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      width: 100%;
      margin: auto;

      img {
        max-width: 250px;
        max-height: 130px;
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        justify-content: center;
      }

      .swiper-button-prev,
      .swiper-button-next {
        color: #bfe3ff;
      }
    }

    &.-fourth {
    }

    &.-fifth {
      background: url("https://media.portail-assurance.ca/Events/Cap/2023/HomePage/salon-background_quqG6XKps.jpg?updatedAt=1693423794008")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 34%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 250px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0rem;
    padding: 3rem;
    border: 1px solid #8a8a8a;
    max-width: 580px;
    height: 350px;
  }
}

/// SV ///
.home-sv2023 {
  &__section {
    margin: 2rem 0rem;
    color: white;

    h2,
    h2 > * {
      font-size: 48px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 42px !important;
      }
    }

    h3,
    h3 > * {
      font-size: 30px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 26px !important;
      }
    }

    *,
    * > * {
      font-size: 24px;
      margin-bottom: 0;
    }

    &.-first {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 1px solid #888;

      img {
        border-radius: 10rem;
        padding: 1rem;
      }
    }

    &.-second {
      background: url("https://media.portail-assurance.ca/Events/SV/HomePage/sommet-salon_JWqxlMju5.jpg?updatedAt=1694109018190")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    &.-fourth {
      background: url("https://media.portail-assurance.ca/Events/SV/HomePage/sommet-reseautage_-YXV4k2zr.jpg?updatedAt=1694109018293")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }

  &__content {
    display: flex;
    margin: 1rem 0rem;

    img {
      margin-right: 1rem;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.-reverse {
      display: flex;
      flex-direction: row-reverse;

      img {
        margin-right: 0;
        margin-left: 1rem;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    div {
      width: 100%;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2.5rem;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 1rem;
      }

      h2 {
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

////// 2024 //////
/// CC ///
.home-cc2024 {
  &__section {
    margin: 2rem 0rem;
    color: white;

    h2 {
      font-size: 32px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 28px !important;
      }
    }

    h3 {
      font-size: 25px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 22px;
      margin-bottom: 0;
      width: auto;

      @media only screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &.-kiosque {
      background: url("https://media.portail-assurance.ca/Events/Cc/2024/Home/exposants_3UU7QRNVJ.jpg?updatedAt=1702057003926")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }
  }
}

.home-jad2024 {
  &__section {
    margin: 2rem 0rem;
    color: white;

    h2 {
      font-size: 32px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 28px !important;
      }
    }

    h3 {
      font-size: 25px;
      margin: 1rem 0;

      @media only screen and (max-width: 425px) {
        font-size: 20px;
      }
    }

    p {
      font-size: 22px;
      margin-bottom: 0;
      width: auto;

      @media only screen and (max-width: 425px) {
        font-size: 16px;
      }
    }

    &.-kiosque {
      background: url("https://media.portail-assurance.ca/Events/Jad/2024/Untitled_nt4WgR6wI.png?updatedAt=1706117269951")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
    }

    &.-swiper {
      padding: 2rem 2rem 0rem;

      .swiper-button-prev,
      .swiper-button-next {
        width: 50px;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }
    }

    &.-swiper2 {
      padding: 0rem 2rem;
      overflow: hidden;

      .swiper {
        overflow: visible !important;
      }

      .swiper-button-prev:after,
      .swiper-button-next:after {
        content: "";
      }

      .swiper-slide {
        height: 100%;

        a {
          color: black;
        }

        img {
          background-color: #f9f9f9;
          border-radius: 12rem;
        }

        @media only screen and (max-width: 768px) {
          .swiper {
            height: 200px;
          }
        }

        &:hover {
          a {
            text-decoration: none;
          }

          img {
            background-color: #ffa913;
          }

          h3 {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 250px;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;

      div {
        width: 100%;
      }
    }
  }
}

.home-cap2024 {
  &__section {
    margin: 2rem 0.5rem;

    &.-first {
      h2 {
        font-size: 60px;
        margin: 1rem 0;
        text-align: center;

        @media only screen and (max-width: 768px) {
          font-size: 24px !important;
        }

        @media only screen and (max-width: 425px) {
          text-align: left;
        }
      }

      h3 {
        font-size: 58px;
        margin: 0rem 0rem 0.5rem;
      }

      p {
        max-width: 250px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &.-second {
      h2 {
        font-size: 45px;
        margin: 5rem 0 2rem;

        @media only screen and (max-width: 768px) {
          font-size: 24px !important;
        }
      }
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    div {
      max-width: 375px;
      width: 100%;

      border-radius: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 250px;
      text-align: center;
      color: white;
    }

    @media only screen and (max-width: 1125px) {
      justify-content: center;
      div {
        margin: 0.5rem;
      }
    }
  }

  &__advisers {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin: 0.5rem;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__advisers-mobile {
    display: none;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin: 0.5rem;
        width: 100%;
        max-width: 400px;
      }
    }
  }

  &__ellipse {
    position: absolute;
    z-index: -1;
    &.-first {
      top: -6rem;
      left: 0;
    }

    &.-second {
      top: 6rem;
      right: -4rem;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
}

.home-csc2024 {
  &__section {
    margin: 2rem 0rem;

    &.-first {
      min-height: 500px;
      color: white;
      width: 100%;

      background: url("https://media.portail-assurance.ca/Events/Csc/2023/HomePage/csc_crowd%201_q0tHXwvUf.png?updatedAt=1691002618988")
        no-repeat center center;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;

      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        font-size: 100px;
        line-height: 6rem;
        text-align: end;
        margin-right: 5rem;
        flex: 1;

        @media only screen and (max-width: 850px) {
          font-size: 75px;
          text-align: center;
          margin: 2rem 0;
          line-height: 4.5rem;
          text-align: start;
        }

      }

      div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1;
        max-width: 500px;
        margin-right: 4rem;
        @media only screen and (max-width: 850px) {
          display: none;
        }
      }


      @media only screen and (max-width: 850px) {
        flex-direction: column;
        min-height: 0;
        margin-bottom: 0rem;

      }

    
    }

    &.-first-mobile {
      display: none;
      padding: 1rem;
      color: white;
      @media only screen and (max-width: 850px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0rem;

      }
    }

    &.-second {
      background-color: #E3F1F4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3rem;

      h2{
        font-size: 34px;
        max-width: 600px;
      }

      img{

        @media only screen and (max-width: 850px) {
          display: none;
        }
      }

    }


    &.-fourth {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 4rem 2rem;
      text-align: center;

      h2 {
        color: white;
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }

      a {
        text-decoration: underline;
        color: black;
      }
    }


    &.-fifth {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }

      div {
        min-width: 32%;

        @media only screen and (max-width: 768px) {
          min-width: 100%;
          margin: 2rem 0rem;
        }
      }

      h2 {
        font-size: 150px;
        line-height: 7rem;
      }

      p {
        font-size: 34px;
        width: 65%;
        margin-bottom: 0rem;

        @media only screen and (max-width: 850px) {
          width: 100%;
        }
      }
    }

    &.-sixth {
      background-color: #E3F1F4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3rem;

      h2{
        font-size: 90px;
        max-width: 600px;
        
        @media only screen and (max-width: 850px) {
          font-size: 75px;
        }

        @media only screen and (max-width: 425px) {
          font-size: 50px;
        }
      }

      img{
        width: 100%;
      }

      @media only screen and (max-width: 1024px) {
        flex-direction: column;
        padding: 0.5rem;
      }

    }

  }

  &__content {
    display: flex;
    margin: 1rem 0rem;

    img {
      margin-right: 1rem;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }

    &.-reverse {
      display: flex;
      flex-direction: row-reverse;

      img {
        margin-right: 0;
        margin-left: 1rem;
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
      }
    }

    div {
      width: 100%;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 2.5rem;

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 2rem 1rem;
      }

      h2 {
        font-size: 34px;
      }

      p {
        font-size: 20px;
      }
    }
  }
}

////// 2025 //////
/// CC ///
.home-cc2025 {
  &__intro {
    font-family: Graphik-Regular;
    h2 {
        color: #0568A2;
        text-align: center;
        font-family: Graphik-Semibold;
        font-size: 44px;
        font-style: normal;
        font-weight: 600;
        line-height: 51px; 
    }

    &__content {
      padding: 35px 0;
      text-align: center;
      color: #3B2727;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
    }
  }

  &__know-more {
    position: relative;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url('https://media.portail-assurance.ca/Events/Cc/2025/cc-2025-group_AzCloDKLb.png?updatedAt=1734564407256') no-repeat center center;
    background-size: cover;
    width: 100%;
    height: 394px;
    color: white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 103, 162, 0.70);
      z-index: 1;
    }

    > * {
      position: relative;
      z-index: 2;
    }

    h3 {
      color: #65DFC0;
      text-align: center;
      font-family: Graphik-Regular;
      font-size: 25px;
      font-weight: 500;
    }

    &__text {
      color: #FFF;
      font-size: 34px;
      font-weight: 700;
      font-family: Graphik-Bold;
      padding-top: 20px;
      padding-bottom: 10px;
    }

    &__cta {
      all:unset;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      gap:6px;
      height: 61px;
      background: #65DFC0;
      border-radius: 100px;
      flex-shrink: 0;
      width: 325px;
      color: #000;
      text-align: center;
      font-family: Graphik-Semibold;
      font-size: 20px;
      font-weight: 600;
      transition: all 0.3s;

      i {
        transition: all 0.3s;
        margin-top: 6px;
      }
      
      &:hover {
        cursor: pointer;
        background: #71eecf;


        i {
          transform: translateX(4px);
        }
      }
    }
  }

}

.home-jad2025 {

  &__section {
    min-height: 525px;
    display: flex;
    justify-content: space-between;
    gap:20px;
    height: fit-content;

    &__strong {
      font-size: 28px;
      font-weight: 700;
      font-family: Graphik-Semibold;
      color: white;
    }

    &--discount {
      background: #114E62;
      height: 340px;
    }

    &--kiosk {
      height: 393px;
        background: url("https://media.portail-assurance.ca/Events/Jad/2025/Group%20290_B7vguScPU.png")
          no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }


    @media(max-width:1000px) {
      height: fit-content;
    }

    &__text-container {
      padding-left: 2rem;
      margin:auto;
      width: 580px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #171717;
      font-family: Graphik-Regular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      &--second {
        padding:2rem;
        width:fit-content;
      }

      @media(max-width:1000px) {
        padding: 2rem;
      }

      &__title {
        color: #114E62;
        font-family: Graphik-Semibold;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px; 
      }

      &__subtitle {
        color: #171717;
        font-family: Graphik-Semibold;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;

        &--second {
          margin:0;
          width:600px;

          @media(max-width:1000px) {
            width: fit-content;
          }
        }
      }

      &__light {
        color: #707070;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    &__image-container {

      @media(max-width:1000px) {
        display: none;
      }

      img {
        width: 480px;
        height: 525px;
      }

      &--second {

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

  }
}
