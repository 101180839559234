/* COLORS */

$maincolor: #ff4020;
$lightgrey: #dddfe1  ;
$lightergrey:#F4F6F9;
$grey: #70777d ;
$darkgrey: #202020;

$black: #000  ;
$white: #FFF  ;

$ja-red:#ff4020;
$ja-yellow:#f5d20a;
$ja-blue:#0b95c8;
$ja-orange:#f3712a;
$ja-green:#69a46a;

$pale-blue: #3f9ec4;

$title-black :#202020;
$grey-font:#202020;
$lightgrey-font:#a8afb6;
$darkgrey-font:#686868;

.maincolor{color: $maincolor !important;}
.whitecolor{color: $white !important;}
.greycolor{color: $grey !important;}
.lightgreycolor{color: $lightgrey !important;}
.darkgreycolor{color: $darkgrey !important;}

.mainbg{background-color: $maincolor !important;}
.greybg{background-color: $grey !important;}
.lightgreybg{background-color: $lightgrey !important;}
.lightergreybg{background-color: $lightergrey !important;}

.yellowbg{background-color: $ja-yellow !important;}
.bluebg{background-color: $ja-blue !important;}
.orangebg{background-color: $ja-orange !important;}
.greenbg{background-color: $ja-green !important;}


.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block, .has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label, .has-error.radio label, .has-error.radio-inline label{
  color: $maincolor;
}

.has-error .form-control{
  border-color: $maincolor;
}