.d1-sm {
    font-family: Graphik-Medium;
    font-size: 28px;
}

.d2,
.d2-alt,
.d2-sm,
.d2-alt2 {
    font-family: Graphik-Semibold;
    font-size: 24px;
}

.d2-alt,
.d2-sm {
    font-family: Graphik-Medium;
}

.d2-sm-alt {
    font-size: 24px;
}

.d2-lg-alt {
    font-size: 25px;
}

.d3 {
    font-family: Graphik-Medium;
    font-size: 20px;
}

.d3-alt {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.3;
}

.lead {
    font-family: Graphik-Medium;
    font-size: 18px;
}


.lead-ligth {
    font-size: 18px;
}


.lead-alt2 {
    font-family: Graphik-Medium;
    font-size: 17px;
}

.lead-alt3 {
    font-family: Graphik-Medium;
    font-size: 17.5px;
}

.text-xxs {
    font-family: Graphik-Medium;
    font-size: 11px;
}

.text-xs {
    font-family: Graphik-Regular;
    font-size: 13px;
}

.fs-12 {
    font-size: 12px;
}

.text-f13 {
    font-size: 13px;
}

.text-f14,
.fs-14 {
    font-size: 14px !important;
}

.text-f15,
.fs-15 {
    font-size: 15px !important;
}

.text-f16,
.fs-16 {
    font-size: 16px !important;
}

.text-f17,
.fs-17 {
    font-size: 17px !important;
}

.text-f18,
.fs-18 {
    font-size: 18px !important;
}

.text-f20,
.fs-20 {
    font-size: 20px !important;
}

.text-f21,
.fs-21 {
    font-size: 21px !important;
}

.text-f22,
.fs-22 {
    font-size: 22px !important;
}

.text-f24,
.fs-24 {
    font-size: 24px !important;
}

.text-f26,
.fs-26 {
    font-size: 26px !important;
}

.text-f28,
.fs-28 {
    font-size: 28px !important;
}

.fs-30 {
    font-size: 30px !important;
}

.text-f32,
.fs-32 {
    font-size: 32px !important;
}

.fs-34 {
    font-size: 34px !important;
}

.text-f35,
.fs-35 {
    font-size: 35px !important;
}

.fs-38 {
    font-size: 38px !important;
}

.fs-41 {
    font-size: 41px !important;
}

.text-f42 {
    font-size: 42px !important;
}

.text-f44,
.fs-44 {
    font-size: 44px;
}

.text-f46,
fs-46 {
    font-size: 46px;
}

.text-f52 {
    font-size: 52px;
}

.text-sm {
    font-family: Graphik-Regular;
    font-size: 14px;
}

.text-md {
    font-family: Graphik-Regular;
    font-size: 15px;
}

.text-lg,
.text-lg-alt,
.text-lg-alt2 {
    font-family: Graphik-Regular;
    font-size: 16px;
}

.text-xl {
    font-size: 24px;
    line-height: 1.3;
}

.text-xl-alt {
    font-size: 20px;
    line-height: 1.3;
}

.text-graphic-light {
    font-family: Graphik-Light !important;
}

.text-graphic-regular {
    font-family: Graphik-Regular !important;
}

.text-graphic-regularitalic {
    font-family: Graphik-RegularItalic !important;
}

.text-graphic-medium {
    font-family: Graphik-Medium;
}

.text-graphic-mediumitalic {
    font-family: Graphik-MediumItalic;
}

.text-graphic-semibold {
    font-family: Graphik-Semibold;
}

.text-graphic-semibolditalic {
    font-family: Graphik-SemiboldItalic;
}

.text-graphic-bold {
    font-family: Graphik-Bold;
}

.text-graphic-black {
    font-family: Graphik-Black;
}


// Graphic X Cond
.text-graphic-xcond-regular {
    font-family: Graphik-XCond-Regular;
}

// Graphic XX Cond
.text-graphic-xxcond-bold {
    font-family: Graphik-XXCond-Bold;
}


.text-fancy {
    font-family: 'Tinos', serif;
    font-weight: 700;
}

.text-italic {
    font-style: italic;
}

.text-line-through {
    text-decoration: line-through;
}

.text-no-transform {
    text-transform: none !important;
}

.text-no-decoration {
    text-decoration: none !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-line-through {
    text-decoration: line-through !important;
}

.line-height-0,
.lh-0 {
    line-height: 0;
}

.line-height-12,
.lh-12 {
    line-height: 1.2;
}

.line-height-14,
.lh-14 {
    line-height: 1.4;
}

.line-height-16,
.lh-16 {
    line-height: 1.6;
}

.line-height-19,
.lh-19 {
    line-height: 19px;
}

.lh-18 {
    line-height: 18px;
}

.lh-28 {
    line-height: 28px;
}

.lh-20 {
    line-height: 20px;
}

.lh-25 {
    line-height: 25px;
}

.lh-32 {
    line-height: 32px;
}

.lh-48 {
    line-height: 48px;
}

.lh-3 {
    line-height: 3;
}

.lh-4 {
    line-height: 4;
}

.line-height-24,
.lh-24 {
    line-height: 24px;
}

.line-height-custom {
    line-height: 17px;
}

/* LETTER SPACING */

.ls-3 {
    letter-spacing: 3px;
}





.text-grey {
    color: #2b2b2b;
}

.text-grey-soft {
    color: #8D8D8D;
}

.text-grey-plus {
    color: #464e58;
}

.text-grey-light {
    color: #b9b9b9;
}

.text-red {
    color: #e1201d;
}

.text-green {
    color: #27a883;
}

.text-gold {
    color: #F8C14A;
}

// .text-gold:hover {
//     color: #dd9623;
// }

.text-gold-light {
    color: #eedeba;
}

.text-red-light {
    color: #f20015;
}

.text-red-bright {
    color: #ff2002;
}

.text-sky {
    color: #7bfbff;
}

.text-link-sky {
    color: #36a8ff
}

.text-link-sky:hover {
    color: #3397e4
}

.text-light-teal {
    color: #89d4e6;
}

.text-blue-light {
    color: #199dd8;
}

.text-blue-dark {
    color: #383e64;
}

.text-blue-dark-light {
    color: #034e9d;
}

.text-grey-blue {
    color: #525775;
}

.text-grey-blue-light {
    color: #76788b;
}

.transition-all {
    transition: 500ms ease all;
}

.is-disabled {
    pointer-events: none;
    cursor: not-allowed !important;
}


/* behaviors */

.cursor-pointer {
    cursor: pointer;
}

.cursor-none {
    cursor: default;
}


/* Alerts */

.text-alert-danger {
    background-color: #f20015;
    padding: 5px 20px;
    color: white;
    border-radius: 4px;
}




/* BUTTONS */
.btn-bt {
    position: relative;
    text-transform: none;
    display: flex !important;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    align-items: center;
    justify-content: center;
    line-height: 0px;
    cursor: pointer;
}

.a-btn,
.a-link a {
    text-decoration: none !important;
}

.a-btn:hover,
.a-link a:hover {
    text-decoration: none !important;
}

.btn-lg {
    height: 52px;
    padding: 15px 30px;
}

.btn-md-plus {
    height: 45px;
    padding: 15px 30px;
}

.btn-md {
    height: 40px;
    min-width: 106px;
    padding: 10px 20px;
    width: 106px;
}

.b-md {
    height: 40px;
    min-width: 106px;
    padding: 10px 30px;
}

.b-round {
    -webkit-border-radius: 12%/50% !important;
    -moz-border-radius: 12%/50% !important;
    border-radius: 12%/50% !important;
}

.b-round.b-xl {
    -webkit-border-radius: 8%/50% !important;
    -moz-border-radius: 8%/50% !important;
    border-radius: 8%/50% !important;
}

.b-round.b-sm {
    -webkit-border-radius: 18%/50% !important;
    -moz-border-radius: 18%/50% !important;
    border-radius: 18%/50% !important;
}

.b-round:hover {
    -webkit-box-shadow: rgba(33, 33, 33, 0.2) 0 0 4px;
    -moz-box-shadow: rgba(33, 33, 33, 0.2) 0 0 4px;
    box-shadow: rgba(33, 33, 33, 0.2) 0 0 4px;
}

.b-black {
    background-color: #2b2b2b;
    color: white;

    &:hover {
        background-color: #494747;
        transition: all 0.3s ease-in-out
    }
}

.b-gold {
    background-color: #eaa63a;
    color: white;

    &:hover {
        background-color: #f1b148;
        transition: all 0.3s ease-in-out
    }
}

.btn-active {
    cursor: pointer;
    opacity: 1;
}

.btn-disable {
    cursor: default !important;
    opacity: 0.6 !important;
}

.btn.btn-red:hover {
    color: white;
}

/* TITLES */

.line-right {
    height: 22px;
    flex-grow: 1;
    width: 100%;
}

.lr-grey {
    border-bottom: 1px solid #979797;
}

.lr-dark {
    border-bottom: 1px solid #2b2b2b;
}

.line-around-md,
.line-around-sm {
    position: relative;
    margin: 0 20px;
}

.line-around-md:before,
.line-around-md:after {
    content: "";
    position: absolute;
    top: 12px;
    min-width: 370px;
    flex-grow: 1;
}

.line-around-sm:before,
.line-around-sm:after {
    content: "";
    position: absolute;
    top: 12px;
    min-width: 230px;
    flex-grow: 1;
}

.line-around-md:before {
    right: 110%;
}

.line-around-md:after {
    left: 110%;
}

.line-around-sm:before {
    right: 105%;
}

.line-around-sm:after {
    left: 105%;
}

.la-lg:before,
.la-lg:after {
    height: 5px;
}

.la-xs:before,
.la-xs:after {
    height: 1px;
}

.line-around-md.la-dark:before,
.line-around-md.la-dark:after,
.line-around-sm.la-dark:before,
.line-around-sm.la-dark:after {
    background-color: #2b2b2b;
}

.line-around-md.la-grey:before,
.line-around-md.la-grey:after,
.line-around-sm.la-grey:before,
.line-around-sm.la-grey:after {
    background-color: #979797;
}

.gp-price .table thead th {
    border-bottom: 2px solid #979797;
    border-top: none !important;
}

.top-liner-sm {
    height: 10px;
    width: 100px;
    border-bottom: 4px solid #f9af02;
}

//HR

.hr-grey-dark {
    border-top: 1px solid #4b4b4b;
}