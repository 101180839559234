// VIDEO ARTICLE CARD
.thumbnail.article-video{
  border: none;
  height: 255px;
  .caption{
    padding-top: 15px;
  }
  .thumb-cat{
    background-color: $grey-font;
  }
}

article.article-single-video{
  h1{
    margin-bottom: 20px;
  }
}

#home #video-single-page{
  padding-top: 15px;
}

.iframe-video{
  width:100%;
  min-height: 675px;
}

.video-head-body{
  hr{
    margin-top: 25px;
    margin-bottom: 25px;
  }
}

.article-related-video h2{
  margin-bottom: 20px;
}

.no-video-found{
  color: $grey-font;
  font-weight: bold;
}