.radar {
  &__container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: stretch;
    }
  }

  &__icon {
    background-color: rgba(0, 123, 255, 0.3);
    padding: 10px;
    border-radius: 0.75rem;
    min-width: 50px;
    max-width: 50px;
    height: 50px;

    img{
      width: 100%;
    }
  }

  &__post {
    &:hover {
      background-color: rgba(0, 123, 255, 0.3);
    }
  }

  &__band {
    background-color: #007BFF;
    color: white;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    max-height: 65px;
    width: 100vw;

    @media only screen and (max-width: 768px) {
      img {
        display: none;
      }
    }
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1300px;
    margin: 0rem auto 8rem;

    &.-header {
      max-width: none;
      margin: 0;
    }

    &.-first {
      max-width: none;
      margin: 3rem auto;
    }

    &.-second {
      @media only screen and (max-width: 768px) {
        h3,
        h5 {
          text-align: left;
          max-width: none !important;
          padding: 0 !important;
        }
      }
    }

    &.-third {
      max-width: none;
      background-color: rgba(0, 123, 255, 0.10);
      padding: 4rem 0rem;
      margin-bottom: 5rem;
    }
  }

  &__content {
    max-width: 1300px;
    width: 100%;
  }

  &__image {
    width: 100vw;
    background-image: url('https://media.portail-assurance.ca/LandingPages/Radar/AdobeStock_274873810%201_p2mApTif6.png?updatedAt=1714670060600');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 700px;
    height: 30vh;
    background-position: center center;

    @media only screen and (min-width: 1920px) {
      height: 55vh;
      min-height: 800px;
    }

    @media only screen and (max-width: 1440px) {
      min-height: 500px;
    }

    @media only screen and (max-width: 850px) {
      min-height: 400px;
    }
  }

  &__icon {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  &__privilege {
    width: 100%;
    display: flex;
    flex-direction: column;

    div{
      min-height: 100px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      margin: 0;
    }

    hr{
      height: 1px;
      border: none;
      color: #007BFF;
      background: #007BFF;
      margin: 0rem 0rem 1rem;
      width: 100%;
    }

    p{
      min-height: 100px;
      max-width: 1000px;
      padding-left: 1rem;
      margin: 0;
    }

    &.-center{
      text-align: right;

      div{
        padding-left: 0px;
        padding-right: 2rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      p{
        padding-left: 0px;
        padding-right: 2rem;
        align-self: flex-end;
      }
    }
    @media only screen and (max-width: 768px) {
      text-align: left;

      div{
        height: 100%;
        padding: 0rem;
        justify-content: center !important;
        text-align: center;
      }

      p{
        padding: 0rem;
        align-self: flex-start;
        display: none;
      }

      hr{
        display: none;
      }

      &.-center{
        text-align: left;
      }
    }
  }

  &__faq {
    border-top: 1px solid black;
    width: 100%;

    padding: 2.5rem 5rem;
    height: 100%;
    transition: 0.3s all ease-in;

    div:first-child {
      cursor: pointer;
    }

    @media only screen and (max-width: 425px) {
      padding: 2.5rem 1.5rem;
    }

    &.-close {
      cursor: pointer;
      position: relative;

      &::before {
        content: "+";
        position: absolute;
        font-size: 80px;
        left: 1rem;
        top: -0.75rem;

        @media only screen and (max-width: 425px) {
          font-size: 40px;
          left: 0rem;
          top: 1.6rem;
        }
      }
    }

    &.-open {
      cursor: pointer;
      position: relative;

      &::before {
        content: "-";
        position: absolute;
        font-size: 80px;
        left: 1rem;
        top: -0.75rem;

        @media only screen and (max-width: 425px) {
          font-size: 40px;
          left: 0rem;
          top: 1.6rem;
        }
      }
    }
  }

  &__package-container {
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 825px) {
      flex-direction: column;
    }
  }

  &__package {
    max-width: 425px;
    width: 100%;

    &:nth-child(2) {
      margin: 0rem 1rem;

      @media only screen and (max-width: 825px) {
        margin: 2rem 0rem;
      }
    }
  }

  &__package-content {
    border: 1px solid #cecece;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
  }
}
