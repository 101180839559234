.main-logo{
    display: block;
    margin-top: 25px;
    max-height: 52px;
    min-width: 240px;
    margin-left: auto;
    margin-right: auto;
}

.main-logo-alt {
    margin-top: 25px;
    margin-left: 0;
    margin-bottom: 25px;
    margin-right: 0;
    min-width: 0;
    max-height: none;
    max-width: 100%;
    height: 54px;
    object-fit: contain;
}

.main-logo-alt:last-of-type {
    margin-bottom: 0;
    height: 60px;
}

// .cap-main-logo .main-logo-alt {
//     height: 50px;
// }

// .cap-main-logo .main-logo-alt:last-of-type {
//     height: 83px;
// }

// /* CC Logo */
// .cap-main-logo .main-logo-alt-cc:last-of-type {
//     height: 50px !important;
// }

/* JAD Logo */
// .cap-main-logo .main-logo-alt-jad:last-of-type {
//     height: 82px !important;
// }

.title {
    margin-top: 35px;
    text-align: center;
}

.subtitle {
    margin-top: 20px;
    text-align: center;
}

.registration-title {
    font-family: "Graphik-Light";
    font-size: 38px;
}
.registration-title.sm {
    font-family: "Graphik-Light";
    font-size: 24px;
}

.registration-title-alt {
    font-size: 30px;
}

.registration-subtitle {
    font-family: "Graphik-Regular";
    font-size: 16px;
}

.link-footer {
    color: #BEBFC0;
    font-family: Graphik-Medium;
    font-size: 10px;
    text-align: center;
}

.sign-page {
    min-height: 100vh;
    position: relative;
}

.sign-page-background {
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: auto;
    margin: 10px 0;
    opacity: 0.38;
}

.btn-submit {
    display: inline-block;
    border-radius: 36.5px;
    background-color: #ed1c24;
    padding: 12px 26px;
    min-width: 220px;
    max-width: 100%;
    font-family: "Graphik-Medium";
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5 !important;
    color: #FFF;
}

.btn-submit:hover, .btn-submit:focus {
    background-color: #f74950;
    color: #FFF;
    box-shadow: none;
}

.btn-submit:active, .btn-submit:active:focus {
    background-color: #d21920;
    color: #FFF;
    box-shadow: none;
}

.btn-submit-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-submit-dark:hover, .btn-submit-dark:focus, .btn-submit-dark:active, .btn-submit-dark:active:focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-submit-dark:focus, .btn-submit-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.link-signin {
    margin-top: 20px;
    text-align: center;
}

.link-black, .link-black:hover {
    color: var(--color-dark);
}

.span-icon-eye {
	color: #2b2b2b;
	height: 24px;
	position: absolute;
	top: 60%;
	right: 20px;
}

.span-icon-eye-sign-in {
	color: #2b2b2b;
	height: 24px;
	position: absolute;
	top: 22%;
	right: 20px;
}

.span-icon-eye:hover {
    cursor: pointer;
}

.btn-social-network {
    border-radius: 6px;
    background-color: #FFF;
    border: 0;
    color: #FFF;
    height: 52px;
    padding: 15px 25px;
    width: 100%;
    margin-top: 5px;
}

.btn-social-network > span {
    margin-right: 15px;
}

.form-check-inline:hover {
    cursor: pointer;
}

.icon-check {
    position: absolute;
    right: 20px;
}

.list-check-container .list-check:last-child {
    margin-bottom: 0;
}

.list-check {
    font-family: "Graphik-Medium";
    font-size: 15px;
}

.list-check .list-check-icon {
    font-size: 20px;
    margin-right: 10px;
    vertical-align: middle;
}

.card-payment-container {
    font-size: 15px;
    margin: auto;
}

/* Custom Radio */

.container-radio-ufc {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container-radio-ufc input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-ufc {
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: 1px solid #979797;
    border-radius: 50%;
}

.container-radio-ufc:hover input ~ .checkmark-ufc {
  background-color: #eee;
}

.container-radio-ufc input:checked ~ .checkmark-ufc {
  background-color: #fff;
}

.checkmark-ufc:after {
  content: "";
  position: absolute;
  display: none;
}

.container-radio-ufc input:checked ~ .checkmark-ufc:after {
  display: block;
}

.container-radio-ufc .checkmark-ufc:after {
 	top: 3px;
    left: 3px;
    width: 17px;
    height: 17px;
	border-radius: 50%;
	background: #000;
}

/* form error message 6 digit for permit number */
.error-message {
    display: none;
    position: absolute;
    left: 0;
    top: calc( 100% + 10px );
}

/* New Purchase flow */

.purchaseflow-title{
    font-family: Graphik-Semibold;
    font-size: 20px;
    color: #2b2b2b;
    margin-bottom: 20px;
    text-align: left;
    padding: initial;
}

.btn-grey  {
    color: grey !important;
    border-radius: 25px;
    font-size: 15px;
    padding: 3px 18px;
}

.btn-grey:hover{
    background-color: rgb(207, 207, 207);
}

.purchaseflow-sub-title{
    font-family: Graphik-Semibold;
    font-size: 19px;
    color: #2b2b2b;
    margin-bottom: 30px;
    text-align: left;
}

.box-monthly-amounts{
    color: #2b2b2b;
}

.container-sub-type {
    display: block;
    position: relative;
    padding-left: 23px;
    margin-bottom: 12px;
    font-family: Graphik-Semibold;
    cursor: pointer;
    font-size: 19px;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .checkmark-sub-type {
        position: absolute;
        top: 7px;
        left: -6px;
        width: 16px;
        height: 16px;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #979797;
        background-color: #fff;
        border-radius: 50%;
        &::after {
            content: "";
            position: absolute;
            display: none;
        }
        &:after {
            top: 3px;
            left: 3px;
            width: 8px;
            height: 8px;
            background-color: #2b2b2b;
            border-radius: 50%;
        }
    }
    &:hover input ~ .checkmark-sub-type {
        background-color: #eee;
    }
    input:checked ~ .checkmark-sub-type:after {
        display: block;
    }
}

.sub-details{
    font-family: Graphik-Regular;
    font-size: 16px;
}

.card-brand{
    font-family: Graphik-Regular;
    font-size: 14px;
    color: #aaa;
    padding-left:15px;
}

.hr-sub-type{
    width: 100%;
    height: 1px;
    background-color: #bebfc0;
    margin-top: 15px;
    margin-bottom: 15px;
    border: none !important;
}

.add-new-card{
    font-family: Graphik-Medium;
    font-size: 14px;
    color: #199dd8;
    text-transform: uppercase;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    &:hover, &:focus, &:active{
        text-decoration: underline;
    }
    &:focus{
        outline: none;
    }
}

.new-card-container{
    margin-bottom: 0;
    display: none;
}

/* End New Purchase flow */



/* Registration Responsive */
/* =================================================================================================================== */

@media (max-width: 575.98px) {
    .btn-submit {
        padding: 10px 15px;
        font-size: 14px;
    }

    .sign-page {
        overflow-x: hidden;
        padding-bottom: 250px;
    }

    .sign-page-background {
        width: 1000px;
        left: 50%;
        transform: translateX(-50%);
    }

    .registration-title {
        font-size: 28px;
    }

    .registration-title-alt {
        font-size: 25px;
    }

    .registration-subtitle {
        font-size: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .sign-page {
        overflow-x: hidden;
        padding-bottom: 250px;
    }

    .sign-page-background {
        width: 1000px;
        left: 50%;
        transform: translateX(-50%);
    }

    .registration-title {
        font-size: 28px;
    }

    .registration-subtitle {
        font-size: 14px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .main-logo-alt {
        margin-right: 25px;
        margin-bottom: 5px;
    }

    .main-logo-alt:last-of-type {
        margin-bottom: 0;
        margin-left: 25px;
    }

    .main-logo-separator {
        margin-top: 25px !important;
    }
}


/* END Registration Responsive */
/* =================================================================================================================== */


/* Modal new Payment */

.modal-purchase {
    width: 1091px !important;
    max-width: 100% !important;
}

.modal-header {
    border: none !important;
}

.modal-dialog {
    max-width: 100%;
    margin: 10px 25px !important;
}

.modal-title {
    font-size: 63px;
    color: #2b2b2b;
    font-family: 'Tinos', serif;
    font-weight: 700;
    line-height: 59px;
    margin-bottom: 25px;

    span {
        color: #ff0002;
    }

    br {
        line-height: 0;
    }
}

.modal-body {
    padding: 0 70px 1rem 70px !important;
}

.close-style {
    font-family: Graphik-Regular;
    font-size: 15px;
    color: #2b2b2b;
}

#msform {
    text-align: center;
    position: relative;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    position: relative
}

#msform fieldset:not(:first-of-type) {
    display: none
}

#msform .sub-step:not(:first-of-type) {
    display: none
}

select.list-dt {
    border: none;
    outline: 0;
    border-bottom: 1px solid #ccc;
    padding: 2px 5px 3px 5px;
    margin: 2px
}

select.list-dt:focus {
    border-bottom: 2px solid skyblue
}

.card {
    border: none;
    border-radius: 0.5rem;
    position: relative
}

.fs-title {
    font-size: 25px;
    color: #2C3E50;
    margin-bottom: 10px;
    font-weight: bold;
    text-align: left
}

#progressbar {
    margin-bottom: 40px;
    color: #d8d8d8;
    padding: 0;
    display: flex;
    li {
        list-style-type: none;
        font-size: 15px;
        font-family: Graphik-Regular;
        flex: 1;
        position: relative;
        text-align: left;
        text-transform: uppercase;

        .step-title {
            margin-left: 25px;
            width: 100%;
        }

        &:first-of-type {
            .step-title {
                margin-left: -10px;
            }
        }

        &:after {
            content: '';
            width: 106%;
            border-radius: 50px;
            height: 17px;
            background: #d8d8d8;
            position: absolute;
            left: -10px;
            top: 25px;
            z-index: -1;
        }
    }
    .active {
        color: #2b2b2b;
        &:after {
            background: #2b2b2b;
            z-index: 1;
        }
    }
}

#progressbar-mobile {
    margin-bottom: 60px;
    padding: 0;
    display: none;
    justify-content: center;
    list-style: none;
    li {
        height: 13px;
        width: 13px;
        border-radius: 50%;
        background-color: #d8d8d8;
        margin: 0 10px;
    }
    .active{
        background-color: #2b2b2b;
    }
}

.form-step-1{
    padding: initial;
    .step1-adv-list {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: initial;
        margin: initial;

        .adv-item {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 0;

            .img-icon {
                min-height: 50px;
                height: initial;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                img{
                    height: initial;
                    width: initial;
                }
            }

            p {
                font-family: Graphik-Medium;
                font-size: 16px;
                color: #2b2b2b;
                line-height: 18px;
                margin-bottom: 10px;
            }
        }
    }

    .sep {
        width: 100%;
        position: relative;
        text-align: center;
        margin-bottom: 40px;

        .sep-text {
            font-family: Graphik-Medium;
            font-size: 19px;
            color: #2b2b2b;
            background-color: #fff;
            padding: 0 20px;
            display: inline;
            position: relative;
            z-index: 16;
        }

        .sep-line {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #979797;
            border: none;
            margin: 0;
            bottom: 13px;
            left: 0;
            z-index: 0;
            display: block;
        }
    }

    .select-plans {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;
        .plan {
            flex: 1;
            max-width: 257px;
            width: initial;
            margin-bottom: 0;
            .borders{
                border: 1px solid #cecece;
                padding: 32px;
            }
            .borders-alt{
                border: 1px solid #cecece;
                border-top: none;
                padding: 15px 5px 15px 15px;
                text-align: left;
                ul{
                    padding-left: 15px;
                }
            }
            .plan-title {
                font-family: Graphik-Semibold;
                font-size: 18px;
                color: #8d8d8d;
                margin-bottom: 0;
                line-height: 1;
                .text-red{
                    color: #f20015;
                }
            }
            .plan-title-freq {
                font-family: Graphik-Regular;
                font-size: 16px;
                color: #8d8d8d;
                text-transform: uppercase;
            }

            .plan-price {
                font-family: Graphik-Regular;
                font-size: 44px;
                color: #2b2b2b;
                margin-bottom: 0;
                line-height: 1;
            }
            .plan-price-freq {
                font-family: Graphik-Regular;
                font-size: 22px;
                color: #2b2b2b;
            }
            .renewal{
                font-family: Graphik-Regular;
                font-size: 16px;
                color: #8d8d8d;
                margin-bottom: 0;
                margin-top: 10px;
            }
        }
        .most-pop{
            position: relative;
            flex: 1;
            max-width: 257px;
            width: initial;
            margin-bottom: 0;
            .plan{
                flex: initial !important;
                border: none !important;
                .borders{
                    border: none !important;
                    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.17);
                    padding: 32px;
                }
                .borders-alt{
                    border: none !important;
                    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.17);
                    padding: 15px 5px 15px 15px;
                    ul{
                        padding-left: 15px;
                    }
                }
            }
            .pop-high-light{
                position: absolute;
                top: -31px;
                text-align: center;
                font-family: Graphik-Semibold;
                color: #fff;
                background-color: #f20015;
                width: 100%;
                padding: 11px 0;
                text-transform: uppercase;
            }
        }
        #yearly{
            order: 1;
        }
        #monthly{
            order: 2;
        }
        #quarterly{
            order: 3;
        }
    }
    .note{
        text-align: center;
        font-family: Graphik-Regular;
        font-size: 16px;
        color: #2b2b2b;
        margin-bottom: 0;
    }
}

.plan-btn {
    font-family: Graphik-Semibold;
    color: #fff;
    background-color: #f20015;
    border: none;
    padding: 12px 24px;
    border-radius: 50px;
    cursor: pointer;
    &:hover, &:active, &:focus{
        background-color: #e0000f;
    }
}

.form-step-2{
    .sub-step{
        padding: initial;
        display: flex;
        flex-direction: column;
        .sub-step-tilte{
            font-family: Graphik-Semibold;
            font-size: 20px;
            color: #2b2b2b;
            text-align: left;
            margin-bottom: 35px;
        }
        .sub-step-sub-tilte{
            font-family: Graphik-SemiboldItalic;
            font-size: 19px;
            color: #2b2b2b;
            text-align: left;
            margin-bottom: 35px;
        }
        .sub-form{
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .sub-form-control{
                border-radius: 6px;
                border: solid 1px #afafaf;
                width: 100%;
                padding: 0 14px;
                height: 40px;
                margin-bottom: 10px;
                &::placeholder{
                    font-family: Graphik-Regular;
                    font-size: 15px;
                    color: #2b2b2b;
                }
                &:last-of-type{
                    margin-bottom: 25px !important;
                }
            }
            .plan-btn{
                width: 100%;
            }
        }
        .forgotten-pwd{
            text-align: right;
            font-size: 15px;
            font-family: Graphik-Regular;
            width: 100%;
            margin-bottom: 25px;
        }
    }
}

.w-40{
    width: 40%;
}

.form-step-3{
    padding: initial;
    #Pmethods{
        order: 1;
    }
    #Ptotal{
        order: 2;
    }
    #Pbtn{
        order: 3;
    }
    .payment-choices{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .input-group-promo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .promo-label{
            font-family: Graphik-Semibold;
            font-size: 16px;
            flex: 1;
            margin-bottom: 0;
        }
        .input-promo{
            border-radius: 6px !important;
            border: solid 1px #afafaf !important;
            height: 40px !important;
            padding-bottom: 0;
            margin: 0 20px !important;
            flex: 1;
        }
        .btn-promo-container{
            flex:1;
            .btn-promo{
                padding: 8px 15px !important;
                font-size: 16px !important;
            }
        }
    }
}

.form-step-4{
    padding: initial;
    .client-radio{
        .radio div{
            display: flex;
            align-items: center;
            height: 100%;
        }
        .form-group{
            display: flex !important;
        }
        label.form-check-inline{
            position: relative;
            padding-left: 35px;
            margin-right: 45px;
            margin-bottom: 0;
            cursor: pointer;
            font-family: Graphik-Regular;
            font-size: 15px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            input{
                position: absolute;
                opacity: 0;
                cursor: pointer;
                &:checked{
                    ~ .client-radio-checkmark{
                        &:after{
                            display: block;
                        }
                    }
                    ~ .magazine-radio-checkmark{
                        &:after{
                            display: block;
                        }
                    }
                }
            }
            .client-radio-checkmark {
                position: absolute;
                top: 2px;
                left: 0;
                width: 16px;
                height: 16px;
                border: 1px solid #979797;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
                border-radius: 50%;
                &:after{
                    top: 3px;
                    left: 3px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #2b2b2b;
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
            .magazine-radio-checkmark {
                position: absolute;
                top: 8px;
                left: 0;
                width: 20px;
                height: 20px;
                border: 1px solid #979797;
                box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
                border-radius: 50%;
                &:after{
                    top: 2px;
                    left: 2px;
                    width: 14px;
                    height: 14px;
                    border-radius: 50%;
                    background: #2b2b2b;
                    content: "";
                    position: absolute;
                    display: none;
                }
            }
            &:hover{
                input{
                    ~ .magazine-radio-checkmark{
                        background-color: #eee;
                    }
                }
            }
        }
    }

    .form-group_magazine{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        .form-label_magazine{
            text-align: left;
            font-family: Graphik-Medium;
            font-size: 15px;
        }
        .form-input_magazine{
            height: 38px;
            border: 1px solid #d5d5d5 !important;
            width: 100%;
            border-radius: 6px;
            padding: 5px 14px;
        }
    }
}

.modal-has-subscription {
    width: 520px !important;
    max-width: 100%;
}
.modal-has-subscription-dialog{
    margin-top: 75px !important;
}
.modal-has-subscription-body{
    padding: 0 !important;
}
.modal-has-subscription-info{
    font-family: Graphik-Semibold;
    font-size: 36px;
    color: #2b2b2b;
    padding: 40px 10px 20px 10px;
}
.modal-has-subscription-footer{
    justify-content: center !important;
    border-top: none !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}

.modal-add-product-confirm-dialog{
    margin-top: 75px !important;
    width: 575px !important;
    max-width: 100%;
    margin: auto;
}
.modal-add-product-confirm-body{
    h1{
        font-family: Graphik-Semibold;
        font-size: 20px;
        color: #2b2b2b;
        text-align: left;
        margin-bottom: 20px;
    }
    p{
        font-family: Graphik-Regular;
        font-size: 19px;
        color: #2b2b2b;
        text-align: left;
        margin-bottom: 0;
        span{
            font-family: Graphik-Semibold;
        }
    }
}
.modal-add-product-confirm-footer{
    display: flex;
    align-items: flex-start;
    border-top: none !important;
    padding-top: 0!important;
    padding-bottom: 10px;
    button:first-of-type{
        margin-right: 20px;
        margin-bottom: 0;
    }
    button{
        padding: 8px 20px;
    }
}


.flex-has-subscription-body{
    padding: 0 !important;
}

.flex-has-subscription-info{
    font-family: Graphik-Semibold;
    font-size: 36px;
    color: #2b2b2b;
    padding: 40px 10px 20px 10px;
}
.flex-has-subscription-footer{
    justify-content: center !important;
    border-top: none !important;
    padding: 0 !important;
    margin-bottom: 20px !important;
}

.max-width-xl{
    max-width: 1050px !important;
}


@media (max-width: 767px) {
    .modal-title{
        font-size: 32px;
        line-height: 32px;
    }
    #progressbar {
        display: none;
    }

    #progressbar-mobile {
        display: flex;
    }

    .purchaseflow-sub-title{
        font-size: 16px;
    }

    .form-step-1 {
        .sep {
            .sep-text {
                font-size: 16px;
            }
            .sep-line {
                display: none;
            }
        }

        .step1-adv-list {
            flex-direction: column;
            align-items: center;
            width: 290px;
            margin: auto;
            .adv-item {
                flex-direction: row;
                margin-bottom: 15px;
                .img-icon {
                    min-height: initial;
                    height: 21px;
                    min-width: 40px;
                    margin-bottom: 0;
                    img{
                        height: 100%;
                        width: 21px;
                    }
                }

                p {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 0;
                    text-align: left;
                }
            }
        }

        .select-plans {
            flex-direction: column;
            align-items: center;
            .plan {
                width: 100%;
                margin-bottom: 20px;
                max-width: 256px;
            }
            .most-pop{
                margin-bottom: 20px;
                width: 100%;
                max-width: 256px;
                .plan{
                    margin-bottom: 0;
                }
            }
            #yearly{
                order: 2;
            }
            #monthly{
                order: 1;
            }
            #quarterly{
                order: 3;
            }
        }
        .note{
            font-size: 15px;
        }
    }

    .purchaseflow-title{
        font-size: 16px;
        text-align: center;
        padding: 0 25px;
    }

    .form-step-3{
        #Pmethods{
            order: 2;
        }
        #Ptotal{
            order: 1;
        }
        #Pbtn{
            order: 3;
        }

        .input-group-promo {
            flex-direction: row !important;
            .promo-label{
                font-size: 14px;
                flex: initial;
                margin-bottom: 0;
                text-align: left;
            }
            .input-promo {
                height: 30px !important;
                padding-bottom: 0;
                margin: 0 10px !important;
                flex: initial;
                width: 107px;
            }
            .btn-promo-container{
                flex: initial;
                .btn-promo{
                    padding: 4px 8px !important;
                    font-size: 14px !important;
                }
            }
        }
        .stipe-tax-text{
            font-size: 12px;
        }
    }

    .form-step-2{
        .sub-step{
            padding: 0 20px;
            .sub-form{
                width: 80%;
            }
            .sub-step-sub-tilte{
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 992px) {
    .modal-body {
        padding: 0 30px 1rem 30px!important;
    }
    .form-step-1 {
        padding: 0 20px;
    }
    .form-step-2 {
        .sub-step {
            padding: 0 20px;
        }
    }
    .form-step-3{
        padding: 0 20px;
    }
    .form-step-4 {
        padding: 0 21px;
    }
}

@media (max-width: 1200px) {
    .form-step-1 {
        .select-plans {
            .plan {
                max-width: 230px;
            }
            .most-pop {
                max-width: 230px;
            }
        }
    }
}
/* End Modal new Payment Purchase */
