/* Article Big Picture */
/* =================================================================================================================== */

.main-image {
    position: relative;
}

.main-image>img {
    height: 500px;
    margin-bottom: 15px;
    object-fit: cover;
    width: 100%;
}

.main-image>p {
    display: none;
    background-color: #2b2b2b;
    bottom: 35px;
    color: #9d9d9d;
    font-size: 13px;
    padding: 15px 10px;
    position: absolute;
    right: 25px;
    text-align: justify;
    width: 320px;
}

.main-image>i {
    position: absolute;
    bottom: 30px;
    right: 15px;
    color: #b7b7b7;
}

.container-complete-article {
    margin: 0 50px;
    top: -60px;
    position: relative;
    background-color: #FFF;
    padding: 25px;
}

.floating-container {
    /*background-color: #1293FA;*/
    padding: 20px;
}

.title-related-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Graphik-Semibold;
    font-size: 21px;
    line-height: 28px;
    color: #FFF;
}

.line-item {
    border: 1px solid #FFF;
    margin: 15px 0;
}

.link-item {
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-size: 19px;
    line-height: 22px;
    color: #FFF;
}

.link-item a {
    color: #FFF;
    /*text-decoration: none;*/
}

.content-article blockquote {
    background-color: white;
    float: left;
    margin-right: 15px;
    padding: 44px 44px 44px 0;
    width: 290px;
}

.content-article blockquote>p {
    border-top: 8px solid #2b2b2b;
    font-family: Graphik-Semibold;
    font-size: 21px;
    padding-top: 15px;
}


/* END Article Big Picture */
/* =================================================================================================================== */





/* Article Detail */
/* =================================================================================================================== */

.container-subscription {
    padding-top: 25px;
}

.title-subscription {
    font-family: "Graphik-Semibold";
    font-size: 16px;
    text-transform: uppercase;
}

.title-subscription .fa {
    margin-right: 8px;
}

.leyend-box {
    font-family: Graphik-Semibold;
    font-size: 23px;
}

.header-subscription {
    border-top: 1px solid #000;
}

.title-header-subscription {
    font-family: Graphik-Regular;
    font-size: 34px;
}

.price-header-subscription {
    font-family: Graphik-Semibold;
    font-size: 21px;
    text-transform: uppercase;
}

.last-price {
    color: var(--color-background-ad);
    font-family: Graphik-Regular;
}

.btn-red {
    border-radius: 25px;
}

.wrapper-header-subscription {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.list-footer-subscription {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    padding-left: 0;
}

.item-footer-subscription {
    position: relative;
    min-height: 46px;
    padding-left: 68px;
    font-size: 20px;
    font-family: Graphik-Regular;
    line-height: 1.5;
    margin-bottom: 30px;
}

.item-footer-subscription .logo-footer-subscription {
    position: absolute;
    left: 0;
    top: 5px;
    width: 48px;
}

.subscription-login {
    font-family: Graphik-Regular;
    font-size: 16px;
}

.link-black,
.link-black:hover {
    color: var(--color-dark);
}

.main-content-article {
    overflow: hidden;
    height: 125px
}

// .main-content-article:after {
//     content: "";
//     position: absolute;
//     z-index: 1;
//     bottom: 0;
//     left: 0;
//     pointer-events: none;
//     background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 90%);
//     width: 100%;
//     height: 6em;
// }

.wrapper-articles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-articles * {
    max-width: 304px;
}

.container-articles-tags img {
    object-fit: cover;
}

.content-article blockquote {
    background-color: white;
    float: left;
    margin-right: 15px;
    padding: 44px 44px 44px 0;
    width: 290px;
}

.content-article blockquote>p {
    border-top: 8px solid #2b2b2b;
    font-family: Graphik-Semibold;
    font-size: 21px;
    padding-top: 15px;
}

.lightbox {
    position: fixed;
    top: 15px !important;
}

.lb-outerContainer {
    height: calc(100vh - 80px) !important;
    width: 80% !important;
    overflow-x: scroll;
}

.lb-container {
    min-height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lb-image {
    width: 100% !important;
    height: auto !important;
}

.btn-submit {
    display: inline-block;
    border-radius: 36.5px;
    background-color: #ed1c24;
    padding: 12px 26px;
    min-width: 220px;
    max-width: 100%;
    font-family: "Graphik-Medium";
    font-weight: 600;
    font-size: 18px;
    line-height: 1.5 !important;
    color: #FFF;
}

.btn-submit:hover,
.btn-submit:focus {
    background-color: #f74950;
    color: #FFF;
    box-shadow: none;
}

.btn-submit:active,
.btn-submit:active:focus {
    background-color: #d21920;
    color: #FFF;
    box-shadow: none;
}

.text-italic {
    font-style: italic;
}

@media (max-width: 1199.98px) {
    .title-header-subscription {
        font-size: 29px;
    }
}

@media (max-width: 575.98px) {
    .title-header-subscription {
        font-size: 26px;
    }
}

/* CC-Ads-Style */
// .CC-ads{
//     max-width: 736px;
//     padding: 0px 21px;
//     border-left: 12px solid #189180;
// }

// .CC-ads-container .CC-ads-title{
//     font-family: Graphik-Medium;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
//     margin-bottom: 5px;
// }

// .CC-ads-container .CC-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 46px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #189180;
//     margin-bottom: 20px;
//     display: inline-block;
// }

// .CC-ads-container .CC-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 18px;
//     color: #2b2b2b;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 0.95;
//     letter-spacing: normal;
// }

// .CC-ads-container .CC-ads-btn, .CC-ads-container .CC-ads-btn2{
//     border-radius: 50px;
//     color: white;
//     padding: 15px 35px;
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 17.1px;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     text-decoration: none;
// }

// .cc-ads-text-sm{
//     font-size: 14px;
// }

// .CC-ads-container .CC-ads-btn{
//     background-color: #ff713f;
//     margin: 15px 0 10px 0;
// }

// .CC-ads-container .CC-ads-btn2{
//     background-color: #189180;
//     margin-top: 15px;
// }

// .CC-ads-container .CC-ads-text-container{
//     background-color: #eaedef;
//     margin-left: -51px;
//     padding: 16px 16px 16px 51px;
//     position: relative;
//     max-width: 582px;
//     min-height: 84px;
//     display: flex;
//     align-items: center;
// }
// .CC-ads-container .CC-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -16px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 16.4px solid #8d8d8d;
//     border-left: 18px solid transparent;
// }
// .cc-manager-text-black{
//     color: #2b2b2b;
// }
// .CC-ads-container .CC-ads-text{
//     font-family: Graphik-Medium;
//     font-size: 22px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 26px;
//     letter-spacing: normal;
//     color: #2b625e;
//     margin-bottom: 0;
// }

// .CC-ads-container .CC-ads-price{
//     margin-top: 19px;
//     margin-bottom: 5px;
//     font-family: Graphik-Medium;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 20px;
//     letter-spacing: normal;
//     color: #2b2b2b;
// }

// .CC-ads-container .CC-ads-price-ext{
//     font-family: Graphik-Semibold;
//     font-size: 24px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 22px;
//     letter-spacing: normal;
//     color: #ff713f;
// }

// @media (max-width: 992px) {
//     .CC-ads-container .CC-ads-title{
//         font-size: 18px;
//     }

//     .CC-ads-container .CC-ads-subtitle{
//         font-size: 40px;
//     }

//     .CC-ads-container .CC-ads-link{
//         font-size: 18px;
//         line-height: 1;
//     }

//     .CC-ads-container .CC-ads-btn{
//         font-size: 18px;
//     }

//     .CC-ads-container .CC-ads-text{
//         font-size: 25px;
//     }

//     .CC-ads-container .CC-ads-list{
//         font-size: 18px;
//     }
// }

/* CAP-Ads-Style */
// .CAP-ads{
//     max-width: 736px;
//     padding: 12px 53px;
//     border-left: 8px solid #034e9d;
// }

// .CAP-ads-container .CAP-ads-title{
//     font-family: Graphik-Regular;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
// }

// .CAP-ads-container .CAP-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 48px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #034e9d;
//     margin-bottom: 20px;
//     display: inline-block;
// }

// .CAP-ads-container .CAP-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 18px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 0.95;
//     letter-spacing: normal;
// }

// .CAP-ads-container .CAP-ads-btn{
//     border-radius: 50px;
//     background-color: #013d7d;
//     color: white;
//     padding: 20px 35px;
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.13;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     margin: 15px 0;
// }

// .CAP-ads-container .CAP-ads-text-container{
//     background-color: #7bfbff;
//     margin-left: -81px;
//     padding: 16px 16px 16px 81px;
//     position: relative;
//     max-width: 582px;
//     min-height: 84px;
//     display: flex;
//     align-items: center;
// }
// .CAP-ads-container .CAP-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -13px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 13px solid #52d6da;
//     border-left: 20px solid transparent;
// }
// .CAP-ads-container .CAP-ads-text{
//     font-family: Graphik-Semibold;
//     font-size: 22px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     color: #034e9d;
//     margin-bottom: 0;
// }

// .CAP-ads-container .CAP-ads-list{
//     margin-top: 19px;
//     font-family: Graphik-Regular;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.4;
//     letter-spacing: normal;
//     color: #2b2b2b;
// }
// @media (max-width: 992px) {
//     .CAP-ads-container .CAP-ads-title{
//         font-size: 18px;
//     }

//     .CAP-ads-container .CAP-ads-subtitle{
//         font-size: 40px;
//     }

//     .CAP-ads-container .CAP-ads-link{
//         font-size: 18px;
//         line-height: 1;
//     }

//     .CAP-ads-container .CAP-ads-btn{
//         font-size: 18px;
//     }

//     .CAP-ads-container .CAP-ads-text{
//         font-size: 25px;
//     }

//     .CAP-ads-container .CAP-ads-list{
//         font-size: 18px;
//     }
// }

// /* CSC Ad Style */
// .CSC-ads{
//     max-width: 736px;
//     padding: 12px 53px;
//     border-left: 8px solid #383e64;
// }

// .CSC-ads-container .CSC-ads-title{
//     font-family: Graphik-Regular;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
// }

// .CSC-ads-container .CSC-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 48px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #383e64;
//     margin-bottom: 20px;
//     display: inline-block;
// }

// .CSC-ads-container .CSC-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 18px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 0.95;
//     letter-spacing: normal;
// }

// .CSC-ads-container .CSC-ads-btn, .CSC-ads-container .CSC-ads-btn-alt{
//     border-radius: 50px;
//     color: white;
//     padding: 20px 35px;
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.13;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     margin: 15px 0;
// }

// .CSC-ads-container .CSC-ads-btn{
//     background-color: #383e64;
// }

// .CSC-ads-container .CSC-ads-btn-alt{
//     background-color: #e1201d;
// }

// .CSC-ads-container .CSC-ads-text-container{
//     background-color: #E0E1F0;
//     margin-left: -81px;
//     padding: 16px 16px 16px 81px;
//     position: relative;
//     max-width: 582px;
//     min-height: 84px;
//     display: flex;
//     align-items: center;
// }
// .CSC-ads-container .CSC-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -13px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 13px solid #878A9F;
//     border-left: 20px solid transparent;
// }
// .CSC-ads-container .CSC-ads-text{
//     font-family: Graphik-Semibold;
//     font-size: 22px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     color: #383e64;
//     margin-bottom: 0;
// }

// .CSC-ads-container .CSC-ads-list{
//     margin-top: 19px;
//     font-family: Graphik-Regular;
//     font-size: 16px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.4;
//     letter-spacing: normal;
//     color: #2b2b2b;
// }

// .btn-modal{
//     display: block !important;
// }

// .btn-page{
//     display: none !important;
//     color: #fff !important;
// }

// @media (max-width: 767px) {
//     .btn-modal{
//         display: none !important;
//     }

//     .btn-page{
//         display: block !important;
//     }
// }

// @media (max-width: 992px) {
//     .CSC-ads-container .CSC-ads-title{
//         font-size: 18px;
//     }

//     .CSC-ads-container .CSC-ads-subtitle{
//         font-size: 40px;
//     }

//     .CSC-ads-container .CSC-ads-link{
//         font-size: 18px;
//         line-height: 1;
//     }

//     .CSC-ads-container .CSC-ads-btn{
//         font-size: 18px;
//     }

//     .CSC-ads-container .CSC-ads-text{
//         font-size: 25px;
//     }

//     .CSC-ads-container .CSC-ads-list{
//         font-size: 18px;
//     }
// }

/* END Article Detail */
/* =================================================================================================================== */




/* Article Category */
/* =================================================================================================================== */

.content-headlines {
    border-bottom: 2px solid var(--color-grey-light);
    margin: 30px 0;
    padding-bottom: 30px;
}

.main-article-item {
    height: 580px;
    max-height: 580px;
    position: relative;
}

.article-item {
    background-color: #FFF;
    height: 300px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
}

.main-article-item>p,
.article-item>p {
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-size: 18px;
    height: 250px;
    overflow: hidden;
}

.article-item>p>i {
    vertical-align: baseline;
}

.icon-leyend {
    bottom: -15px;
    font-size: 11px;
    position: absolute;
}

.article-item {
    margin-bottom: 0;

}

@media (max-width: 575.98px) {
    .icon-leyend {
        bottom: -18px;
    }

    .article-item {
        margin-bottom: 33px;
    }
}

.main-article-item>.icon-leyend {
    /*bottom: 15px;*/
    bottom: 0;
}

.icon-leyend>p {
    /*padding: 0;*/
    margin: 0;
    font-size: 11px;
    font-family: Graphik-Semibold;
    color: var(--color-grey-light);
}

.second-ad {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /*height: 100%;*/
    padding: 10px 0 20px 0;
}

.content-third-ad {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 0 20px 0;
}

.third-ad {
    background-color: var(--color-background-ad);
    height: 600px;
    line-height: 600px;
    text-align: center;
    width: 100%;
}

.btn-transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.article-item-popular {
    height: 150px;
    max-height: 150px;
}

.title-tags-section {
    font-family: Graphik-Semibold;
    font-size: 16px;
    margin: 15px 0;
}

.content-four-ad {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 0 20px 0;
}

.load_button {
    margin-top: 15px;
}

.load_button:hover {
    cursor: pointer;
}

.container-extra-articles {
    margin-top: 15px;
}

.grey-tag {
    color: var(--color-grey-light);
    font-family: Graphik-Semibold;
    font-size: 11px;
}

.main-article-item>.subtitle-article {
    display: block;
    background-color: #FFF;
    font-size: 32px;
    line-height: 38px;
    margin-top: 32px;
    margin-bottom: 19px;
}

.separation-line {
    border-top: 2px solid var(--color-ad);
    width: 100%;
}

#poool-widget {
    position:relative;
    z-index: 100005; // z-index greater than the ADS
}

/* END Article Category */
/* =================================================================================================================== */