#event-pages {
  min-height: 70vh;

  .no-amf {
    background-color: rgba(220, 10, 19, 0.1);
    color: red;
    padding: 1rem;

    &.-choice {
      background-color: rgba(10, 220, 19, 0.1);
      color: green;
      padding: 1rem;
    }

    &__button {
      background-color: red;
      color: white;
      padding: 0.25rem 1rem;
      border-radius: 2rem;
      display: flex;
      align-items: center;
      text-align: center;
      cursor: pointer;

      &.-choice {
        background-color: green;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.events {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: unset !important;
  }
}

/// BACKGROUND  ///

.bg-center-x {
  background-position-x: center;
}

bg-center-y {
  background-position-x: center;
}

.bg-right {
  background-position-x: right;
}

.bg-left {
  background-position-x: left;
}

.bg-top {
  background-position-y: top;
}

.bg-bottom {
  background-position-y: bottom;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.heightlg {
  min-height: 430px;
}

.heightxl {
  min-height: 630px;
}

.heightxxl {
  min-height: 830px;
}

//GENERALE///
.event-link {
  color: #76cee6;
}

.event-link:hover {
  color: #62bdd6;
}

.event img {
  object-fit: contain;
}

.vertical-line {
  width: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
}

.line-lg {
  height: 70px;
}

.line-md {
  height: 50px;
}

.line-sm {
  height: 35px;
}

.line-light {
  border-left: 1px solid #ffffff;
}

.line-soft {
  border-left: 1px solid #bebfc0;
}

.line-dark {
  border-left: 1px solid #343a52;
}

/// SPONSORS ///
.sponsors-event {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  // flex-wrap: wrap;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  &__block {
    @media screen and (max-width: 800px) {
      p {
        text-align: center;
      }
    }

    &.-partner {
      border-left: 1px solid #979797;
      padding: 0rem 1rem;

      @media screen and (max-width: 800px) {
        border: none;
        border-top: 1px solid #979797;
        padding: 1rem 0rem;
      }
    }
  }

  &__logos {
    display: inline-flex;
    justify-content: center;
    flex-wrap: wrap;

    @media screen and (max-width: 800px) {
      justify-content: center;
      align-items: center;
    }

    &.-single {
      display: flex;
      align-items: center;
      margin: 1rem;

      img {
        max-width: 160px;
        max-height: 55px;
      }
    }
  }
}

/// ELEMENTS ///
.hero-container .text-dark {
  color: #34353a;
}

.hero-calendar {
  width: 30px;
  height: auto;
  margin-right: 20px;
}

.header-tags {
  display: inline-flex;
  align-items: center;
  margin: 0 10px;

  h3 {
    font-style: italic;
    font-weight: 600;
    max-width: 130px;
  }

  img {
    height: 30px;
    width: auto;
    margin: 20px;
  }
}

.hero-credit {
  padding: 5px 20px;
  letter-spacing: 0.12rem;
  text-transform: uppercase;
  display: inline;
  text-align: center;
  font-size: 0.7rem;
  opacity: 1;
}

//// PRICING CARD ///
.tag-pro {
  color: white;
  text-transform: uppercase;
  background-color: #fe0505;
  padding: 2px 3px;
  margin-bottom: 0px;
  line-height: 17px;
}

.tag-executive {
  color: white;
  text-transform: uppercase;
  background-color: #f8c14a;
  padding: 2px 3px;
  margin-bottom: 0px;
  line-height: 17px;
}

.tag-pro-light {
  color: white;
  text-transform: uppercase;
  background-color: #df4444;
  padding: 2px 3px;
  margin-bottom: 0px;
  line-height: 17px;
}

.pricing-card {
  width: 100%;
  max-width: 350px;
  border-top: solid 5px #424242;
  border-left: solid 1px #cecece;
  border-bottom: solid 1px #cecece;
  border-right: solid 1px #cecece;
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &.-bigger {
    max-width: 400px;
  }
}

.upper-pricing-wrapper.non-pro {
  min-height: 257px;
}

.upper-pricing-wrapper {
}

.pricing-items-wrapper {
  background-color: #f3f3f3;
  border-top: solid 1px #cecece;
  padding: 25px;
  height: 100%;

  ul {
    margin-block-start: 0;
    padding-inline-start: 10px;
  }
}

.price-en::before {
  content: "$";
}

.price-fr::after {
  content: "$";
}

.tariff-info {
  height: 105px;

  i {
    margin-right: 1rem;
    font-size: 25px;
  }

  @media (max-width: 768px) {
    height: 100%;
    text-align: center;

    i {
      margin-right: 0;
      margin-bottom: 0.5rem;
      font-size: 40px;
    }
  }
}

.upper-pricing {
  color: #fff;
  background-color: #424242;
  text-transform: uppercase;
  position: absolute;
  margin-top: -31px;
  padding: 5px 20px;
  align-self: center;
  justify-items: center;
  width: 101%;
}

//// PROMO CARD ////
.promo-card {
  &__button {
    color: white;

    &:hover {
      color: white;
      text-decoration: none;
      opacity: 0.8;
    }
  }
}

/// HERO SECTION ///

.hero-container {
  display: flex;
  align-items: stretch;
  background-repeat: no-repeat;
}

/// NAV TABS ///
// TODO: Make use of the SCSS BIM
.event-tabs {
  position: sticky;
  top: 0;
  z-index: 3;

  &.-mobile {
    position: relative;
    height: 100px;
  }

  &__container {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  &__button {
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      text-decoration: none;
      color: white;
      opacity: 0.9;
    }
  }

  &__link {
    list-style: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Graphik-Regular;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    color: white;
    padding: 1.5rem 0.75rem;
    min-height: 100%;

    &:hover {
      background-color: rgb(76, 76, 76) !important;
      transition: all 0.3s ease-in-out;
      color: white;
    }

    &.-active {
      background-color: white !important;
      color: black;
    }
  }

  &__sidebar {
    display: none;

    @media only screen and (max-width: 1024px) {
      display: block;
      position: fixed;
      overflow: hidden;
      top: 0;
      left: 0;
      height: 100%;
      width: 275px;
      z-index: 3;
      margin-left: 0;
      transition: 0.25s all ease-in;

      &.-close {
        margin-left: -275px;
        transition: 0.25s all ease-in;
      }
    }
  }

  &__lines {
    div {
      width: 35px;
      height: 5px;
      background-color: white;
      margin: 6px 0;
    }
  }

  &__navigation {
    display: block;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    &.-mobile {
      display: none;

      @media only screen and (max-width: 1024px) {
        display: block;
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 35px;
    z-index: 4;
  }
}

.table.note thead th,
td {
  border: none !important;
}

.gp-price-row {
  border-bottom: 1px solid #979797;
}

.jad2022.gp-price-body
  tr:nth-last-child(-n + 3)
  td:nth-last-child(-n + 1)
  span {
  display: none;
}

.jad2022.gp-price-body
  tr:nth-last-child(-n + 3)
  td:nth-last-child(-n + 1):after {
  content: "x";
}

.gp-price-row td {
  padding: 15px 30px 15px 10px;
}

// .header-enter-active,
// .header-leave-active {
//     transition: all 0.3s ease-in-out;
//     height: 430px;
// }

// .header-enter-from,
// .header-leave-to {
//   height: 135px;
// }

.summury ul {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 18px;
}

.book-promo tr {
  line-height: 55px;
}

.book-promo tr:nth-child(2n) {
  background-color: #e5e9ed;
}

.header-book {
  width: 744px;
}

.book-info label {
  width: 150px;
  font-size: 15px;
  font-family: "Graphik-Medium" !important;
}

/// MODAL ////
.event-modal {
  top: 50%;
  left: 50% !important;
  transform: translate(-50%, -50%);
  padding-right: 0rem;
  width: 100%;
  max-width: 1000px;
  height: auto !important;

  .modal-dialog,
  &__dialog {
    -webkit-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    top: 50%;
    margin: 0 auto;
  }
}

.event.hide-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.event.hide-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.event {
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease-in;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    overflow-y: scroll;
  }

  .modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
    overflow: hidden;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-container.full-page {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .purchase-container {
    max-width: 700px;
  }

  .purchase-sections {
    opacity: 0;
  }

  .full-page .purchase-sections {
    opacity: 1;
    transition: all 0.5s;
  }

  .purchase-header a {
    width: 40%;
  }

  .close-menu {
    position: absolute;
    margin-top: -70px !important;
    right: 0px;
  }

  .modal-menu {
    position: absolute;
    max-width: 716px;
    padding: 30px 40px;
    border: 1px solid #d2d2d2;
    border-top: 10px solid #2b2b2b;
    top: 270px;
    background-color: white;
    margin: 0 auto;
    box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 15px -1px rgba(0, 0, 0, 0.4);
  }

  // @-webkit-keyframes modalOpen {
  //     0% {
  //         width: 60%;
  //         height: 60%;
  //     }
  //     40% {
  //         width: 58%;
  //         height: 58%;
  //     }
  //     100% {
  //         width: 100%;
  //         height: 100%;
  //     }
  // }

  .modal-default-button {
    float: right;
  }

  .purchase-sections.focus {
    max-width: 700px;
  }

  .purchase-wrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
  }

  .purchase-wrapper.move-top {
    max-height: 100vh;
    overflow-y: hidden;
    transition: all 0.3s;
  }

  .purchase-confirm {
    opacity: 0;
    transform: translateY(40px);
  }

  .purchase-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
  }

  .purchase-main section,
  .purchase-main footer {
    max-width: 500px;
    width: 100% !important;
  }

  .purchase-confirm section {
    max-width: 100%;
  }

  .purchase-main,
  .purchase-confirm {
    min-width: 100%;
    max-width: 100%;
  }

  .move-top.purchase-main {
    opacity: 0;
    transition: all 0.3s;
  }

  .move-top.purchase-confirm {
    opacity: 1;
    transition: all 0.3s;
    transform: translateY(0px);

    p {
      margin-block-end: 0px;
      margin-block-start: 0px;
      width: 700px;
    }
  }

  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

  // .purchase-enter-from modal-container, .purchase-leave-to modal-container {
  //     opacity: 0;
  //     animation: modalOpen 4s ease-in;
  // }

  // .purchase-enter-active modal-container, .purchase-leave-active modal-container {
  //     animation: modalOpen 4s ease-in;
  // }
  .radio {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
  }

  .radio-input {
    display: none;
  }

  .radio-radio {
    position: relative;
    width: 1.25em;
    height: 1.25em;
    border: 2px solid #bebfc0;
    border-radius: 50%;
    margin-right: 15px;
    box-sizing: border-box;
    padding: 2px;
  }

  .radio-radio::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    background: #6d6a6a;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 0.15s;
  }

  .radio-input:checked + .radio-radio::after {
    transform: scale(1);
  }

  .menu .radio-radio {
    margin-right: 0px;
  }

  .menu-title::before {
    position: absolute;
    margin-top: 5px;
    margin-left: -12px;
    content: "";
    width: 5px;
    height: 5px;
    aspect-ratio: inherit;
    border-radius: 50%;
    background-color: #38414a;
  }

  .generic-list {
    margin-block-end: 1em;
    padding-inline-start: 15px;
  }

  .user-info .required:after {
    content: "*";
    position: relative;
    margin-left: 1px;
    margin-bottom: 2px;
    font-size: 14px;
  }

  .user-info {
    ::placeholder {
      color: #2b2b2b;
    }

    :-ms-input-placeholder {
      color: #2b2b2b;
    }

    ::-ms-input-placeholder {
      color: #2b2b2b;
    }
  }

  /// CHeckout //
  .table th,
  td {
    border: none;
    padding: 12px 0px !important;
  }

  .table > tr:first-child {
    height: 50px;
    line-height: 50px;
  }

  .table.taxes {
    border-bottom: 1px double #bebfc0;
  }

  .table.total {
    border-top: 1px double #bebfc0;
    padding: 5px 0px;
    height: 50px;
    line-height: 50px;
  }

  .code-promo {
    width: 210px;
    height: 40px;
    border-radius: 6px;
  }
}

/// PARTNERS LOOG
.logo-banner {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
}

.logo-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 80px;
}

.logo-company.principal {
  border-right: 1px solid #979797;
  margin-right: 2em;
}

.logo-company.principal img {
  margin-top: 10px;
}

.logo-principal,
.logo-partner {
  display: inline-flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.logo-container {
  display: flex;
  align-items: center;
  max-width: 170px;
  min-width: 156px;
  height: 70px;
  margin-right: 0.2em;
  justify-content: center;
  overflow: hidden;
}

.logo-principal .logo-container {
  justify-content: start;
}

.logo-container img {
  max-width: 100%;
  min-width: 140px;
  object-fit: contain;
}

.event.speaker-profil-list {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}

.speaker-profil-list {
  img {
    width: 110px;
    height: auto;
    margin: 5px;
    opacity: 1;
    transition: all 0.25s ease-in-out;
  }
}

//TODO: CLEAN THIS SECTION
/* SPEAKER INFO (SPEAKER PAGE) */
.speakers-info {
  margin-bottom: 50px;
}

.speakers-info-info {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.speakers-info-info .speakers-info-img {
  flex-grow: 0;
  flex-shrink: 0;
  height: 70px;
  width: 70px;
  background-size: cover;
  background-position: center center;
  margin-right: 20px;
}

.speakers-info-info .speakers-info-desc {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 16px;
}

.event.speakers-info-info .speakers-info-desc {
  min-height: 26px !important;
}

@media (min-width: 576px) {
  /* SPEAKER INFO (SPEAKER PAGE) */
  .speakers-info-info {
    align-items: flex-end;
  }

  .speakers-info-info .speakers-info-img {
    width: 110px;
    height: 110px;
  }
}

@media (min-width: 768px) {
  /* SPEAKER INFO (SPEAKER PAGE) */
  .speakers-info-info .speakers-info-img {
    width: 160px;
    height: 160px;
  }
}

@media (min-width: 992px) {
  /* SPEAKER INFO (SPEAKER PAGE) */
  .speakers-info {
    padding-right: 20px;
    margin-bottom: 0;
  }

  .speakers-info-info .speakers-info-img {
    width: 120px;
    height: 120px;
  }
}

@media (min-width: 1200px) {
  /* SPEAKER INFO (SPEAKER PAGE) */
  .speakers-info {
    padding-left: 5px;
    padding-right: 95px;
  }

  .speakers-info-info .speakers-info-img {
    width: 195px;
    height: 195px;
  }
}

@media (min-width: 1360px) {
  /* SPEAKER INFO (SPEAKER PAGE) */
  .speakers-info {
    padding-left: 0;
  }
}

.hiddenImg {
  opacity: 0;
}

.displayImg {
  opacity: 1;
  width: 520px;
  padding-left: 40px;
  transition: all 500ms ease;
}

.events.mp-speaker {
  margin: 10px 10px 10px 0;
  min-width: 365px;
  max-width: 365px;
}

.event.mp-speaker-bio {
  display: flex;
  flex-direction: column;
  max-width: 215px;
  min-width: 215px;
  margin-left: 10px;
  height: 140px;
  justify-content: flex-end;
}

.events.speaker-link {
  opacity: 1;
  position: relative;
  overflow: hidden;
}

.events.speaker-link > p.img-alt {
  position: absolute;
  opacity: 0;
  width: 90%;
  right: 0;
  left: 3px;
  bottom: 3px;
  margin: 3px 3px 3px 3px;
  padding: 5px;
  font-size: 11px;
  line-height: 14px;
  color: white;
  background-color: rgba(66, 66, 66, 0.5);
  transition: all 300ms ease;
  transition-delay: 300ms;
  transform: translateY(100%);
}

.events.speaker-link:hover > p.img-alt {
  opacity: 1;
  transition-delay: 100ms;
  transform: translateY(0);
}

/// PROGRAM

.text-hightlight {
  color: #ffaf00;
  font-size: 1.1rem;
}

.text-hightlight::before,
.text-hightlight::after {
  content: "***";
  margin: 0 5px;
  letter-spacing: 2px;
  font-variant-position: sub;
}

.program-grid {
  padding-left: 10%;
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(6, 1fr);
  padding-block: 2rem;
  width: min(370px, 370px);
  margin-inline: auto;
}

.program-grid .position-absolute {
  left: -110px;
}

// NEW PROGRAM

.countdown_live_1 {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
}

.program-general {
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2rem 5rem;

    @media screen and (max-width: 800px) {
      margin: 2rem 2rem;
    }
  }

  &__activities {
    border-top: 1px solid grey;
    margin-top: 5rem;
  }

  &__activity {
    margin-top: 1rem;
    padding: 1rem 1rem;
    border-bottom: 1px solid grey;
  }

  &__hat {
    display: flex;
    justify-content: center;
  }

  &__virtual {
    margin: 3rem 12rem;
    padding: 0rem 2rem;

    @media screen and (max-width: 800px) {
      margin: 3rem 0rem;
      padding: 0rem;
    }
  }

  &__header {
    padding: 0rem 2rem;

    @media screen and (max-width: 800px) {
      padding: 0rem;
    }
  }

  &__overview {
    margin: 1.5rem 0rem 3rem;
    padding: 0rem 2rem;

    @media screen and (max-width: 800px) {
      padding: 0rem;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  &__button {
    padding: 1rem 3rem;
    border-radius: 2rem;
    text-align: center;
    text-decoration: none;
  }
}

.replay-general {
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2rem 5rem;

    @media screen and (max-width: 800px) {
      margin: 2rem 2rem;
    }
  }

  &__hat {
    display: flex;

    &.-center {
      justify-content: center;
    }
  }

  &__activities {
    overflow: hidden;
    margin-top: 1rem;

    &.-single-replay {
      display: flex;

      @media screen and (max-width: 800px) {
        flex-direction: column;
      }
    }
  }

  &__column {
    &:hover {
      a {
        text-decoration: none;
      }
    }

    &.-first {
      margin-right: 1rem;
      width: 50%;
    }

    &.-second {
      margin-left: 1rem;
      width: 50%;
    }

    @media screen and (max-width: 800px) {
      display: none;
    }

    &.-mobile {
      display: none;

      @media screen and (max-width: 800px) {
        display: block;
      }
    }
  }

  &__activity {
    padding: 1rem 2rem 2rem;
    border: 0.5px solid grey;
    border-top: 6px solid grey;
    color: black;

    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
      border-top-color: rgba(0, 0, 0, 0.7);

      .replay-general__speaker {
        opacity: 35%;
      }

      .replay-general__hat {
        h4 {
          opacity: 35%;
        }
      }

      .replay-general__cta-card {
        display: block;
      }

      &.-upcoming {
        background-color: rgba(0, 0, 0, 0.2);
        border-top-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &__replay {
    margin-top: 1rem;

    &:hover {
      text-decoration: none;
    }
  }

  &__header {
    padding: 0rem 2rem;

    @media screen and (max-width: 800px) {
      padding: 0rem;
    }
  }

  &__overview {
    margin: 3rem 0rem;
    padding: 0rem 2rem;

    @media screen and (max-width: 800px) {
      padding: 0rem;
    }
  }

  &__cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  &__cta-card {
    display: none;
    margin-top: 0rem;
    margin-right: 1rem;
  }

  &__button {
    padding: 1rem 2rem;
    border-radius: 2rem;
    text-align: center;
    margin: auto;

    &.-replay-now {
      display: flex;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      padding: 1.25rem;
      border-radius: 3rem;
    }

    &:hover {
      text-decoration: none;
    }
  }

  &__badge {
    padding: 0.75rem;
    text-align: center;
    margin: -1.5rem auto 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    border-radius: 3rem;
    max-width: 250px;
    color: white;
    font-size: 20px;
  }
}

.section-activities-multi {
  display: flex;
  overflow: hidden;
  margin: 0rem 1rem;

  &__time {
    display: flex;
    margin-top: 1rem;

    @media only screen and (max-width: 800px) {
      display: none;
    }

    &.-mobile {
      display: none;

      @media only screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__clock {
    margin-right: 0.75rem;
  }

  &__content {
    display: flex;
    width: 100%;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }

  &__line {
    display: none;

    @media screen and (max-width: 800px) {
      width: 100%;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      border-top: 20px solid #d8d8d8;
      display: block;
    }
  }

  &__block {
    flex-basis: 100%;
  }
}

.block-activity-multi {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;

  @media screen and (max-width: 800px) {
    border-top: 1px solid black;
    margin: 1rem 0rem;
  }

  &__content > div {
    margin-top: 0.5rem;
  }

  &__line {
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border-top: 20px solid #d8d8d8;
    display: block;

    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  &__title {
    div {
      display: none;
    }

    @media only screen and (max-width: 800px) {
      div {
        display: block;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.section-activities-one {
  display: flex;
  overflow: hidden;
  margin: 0rem 1rem;

  &__time {
    display: flex;
    margin-right: 1rem;

    @media only screen and (max-width: 800px) {
      display: none;
      margin-right: 0rem;
    }

    &.-mobile {
      display: none;

      @media only screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
      }
    }
  }

  &__clock {
    margin-right: 0.75rem;

    @media only screen and (max-width: 800px) {
      margin-right: 0rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__block {
    &.-line {
      border-top: 2px solid grey;
    }
  }

  &__line {
    width: 100%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    border-top: 20px solid #d8d8d8;
  }
}

.block-activity-one {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;

  &__content > div {
    margin-top: 0.5rem;
  }

  &__content {
    g {
      fill: #777777;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__button {
    display: none;

    @media only screen and (max-width: 800px) {
      display: block;
    }
  }

  &__info {
    display: block;

    &.-open {
      @media only screen and (max-width: 800px) {
        display: block;
      }
    }

    &.-close {
      @media only screen and (max-width: 800px) {
        display: none;
      }
    }
  }
}

// PREPLAY

.replay iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

/* JAD Quizz Modals */

.quizz-block-container {
  position: absolute;
  width: 40%;
  height: 100%;
  top: 0;
  right: 2%;
}

.events {
  .quizz_modal_container {
    position: absolute !important;
    top: 0;
    right: 0;
    min-width: 300px;
    max-width: 380px;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;
    z-index: 9999999999999999;

    .quizz_modal {
      background-color: #fff;
      margin-right: initial;
      max-width: 428px;
      border-radius: 4px;
      box-shadow: 4px 4px 22px 0 rgba(0, 0, 0, 0.26);
    }

    .quizz_modal_dialog {
      margin: 0;
    }

    .quizz_modal_header {
      background-color: #34353a;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      .quizz_modal_title {
        padding: 18px;
        color: #fff;
        font-family: Graphik-Semibold;
        font-size: 17px;
        text-transform: uppercase;
        text-align: center;
      }

      .result_quizz {
        font-size: 38px;
      }
    }

    .quizz_modal_body {
      padding: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .quizz_question {
        font-family: Graphik-Regular;
        font-size: 16px;
        color: #34353a;
        margin-bottom: 25px;
      }
    }

    .quizz_answers {
      display: flex;
      justify-content: center;

      .container_quizz_answer {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
        margin-right: 0 !important;
        margin: 5px 0;

        &:last-child {
          margin-right: 0;
        }
      }

      .checkmark_quizz {
        top: 3px;
      }
    }

    .quizz_answers_alt {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .container_quizz_answer {
        font-size: 16px;
        margin-bottom: 10px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .checkmark_quizz {
        top: 0;
      }
    }

    .quizz_answers,
    .quizz_answers_alt {
      .container_quizz_answer {
        display: block;
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-family: Graphik-Regular;
        color: #34353a;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      .container_quizz_answer input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark_quizz {
        position: absolute;
        left: 0;
        height: 26px;
        width: 26px;
        box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
        border: solid 1px #979797;
        border-radius: 50%;
      }

      .container_quizz_answer:hover input ~ .checkmark_quizz {
        background-color: #eee;
      }

      .checkmark_quizz:after {
        content: "";
        position: absolute;
        display: none;
      }

      .container_quizz_answer input:checked ~ .checkmark_quizz:after {
        display: block;
      }

      .container_quizz_answer .checkmark_quizz:after {
        top: 3px;
        left: 3px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background: #2b2b2b;
      }
    }

    .quizz_modal_footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;

      .quizz_modal_close {
        font-family: Graphik-Semibold;
        font-size: 16px;
        text-align: center;
        color: #34353a;
        background: none;
        border: none;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        &:active,
        &:focus {
          cursor: pointer;
          text-decoration: underline;
          border: none;
          outline: none;
        }
      }
    }
  }
}

/// SHOWCASE

.showcase-wrapper {
  width: 250px;

  &.-csc {
    margin-right: 1rem;

    @media screen and (max-width: 500px) {
      margin-right: 0rem;
    }
  }
}

.showcase-frame {
  margin-bottom: 65px !important;
  position: relative;
  border: 1px solid #dadada;
  width: 252px;
  height: 185px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    object-fit: contain;
    max-height: 165px;
    max-width: 190px;

    &:hover {
      opacity: 0.85;
    }
  }

  p {
    padding: 0 15px;
  }
}

.showcase-virtual-showcase {
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
}

.showcase-partners {
  display: flex;
  flex-wrap: wrap;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }
}

.showcase-kiosk {
  display: inline-flex;
  justify-items: flex-start;
  position: absolute;
  width: 100%;
  top: -35px;

  h6 {
    width: 35px;
    height: 35px;
    margin-right: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2b2b2b;
    color: white;
    top: -10px;
  }
}

/// CC
.b-cc2022 {
  background-color: #00af99 !important;
  color: white;
}

/// JAD

.b-jad2022 {
  background-color: #ffae00 !important;
  color: white;
}

.carrousel-box {
  width: 35%;
  min-width: 130px;
}

.carrousel-box.middle {
  width: 30%;
  padding: 0px 4%;
  min-height: 150px;
  background-color: #f9af02;
  font-size: 18px;

  img {
    width: 35px;
    height: auto;
    opacity: 0.8;
  }
}

#event-carousel .carousel-item.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.event-quote {
  max-height: 85%;
  min-height: 15%;
}

.carrousel-box.img img {
  width: 100%;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  object-fit: fill;
  overflow: hidden;
}

.faq-q {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.faq-nav-item {
  display: flex;
  align-items: center;
  color: #181c37;
  font-size: 19px;
  font-family: Graphik-Medium;
  line-height: 24px;
  margin: 9px 0;
}

svg > g {
  fill: #181c37;
}

.faq-nav-item:hover,
.faq-nav-item:focus,
.faq-nav-item:active,
.faq-nav-item:active:focus {
  color: #ffae00;
  text-decoration: underline;
}

.event .faq-q .faq-icon {
  height: 37px;
  width: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Tinos", serif;
  font-weight: 700;
  color: #fff;
  font-size: 28px;
  position: absolute;
  top: 6px;
  left: 0;
}

.event.jad2022 .faq-q .faq-icon {
  background-color: #ffae00 !important;
}

.event.cc2022 .faq-q .faq-icon {
  background-color: #00af99 !important;
}

.event.jad2022 .faq-nav-item:hover,
.event.jad2022 .faq-nav-item:focus,
.event.jad2022 .faq-nav-item:active,
.event.jad2022 .faq-nav-item:active:focus {
  color: #ffae00 !important;
  text-decoration: underline;
}

.event.cc2022 .faq-nav-item:hover,
.event.cc2022 .faq-nav-item:focus,
.event.cc2022 .faq-nav-item:active,
.event.cc2022 .faq-nav-item:active:focus {
  color: #00af99 !important;
  text-decoration: underline;
}

.faq-q .faq-q-text {
  font-family: Graphik-Semibold;
  color: #181c37;
  padding-left: 54px;
}

.event.jad2022 svg > g {
  fill: #ffae00;
}

.event.cc2022 svg > g {
  fill: #00af99;
}

.faq-r {
  color: #181c37;
  padding-left: 54px;
}

.purchaseButton {
  position: relative;
  cursor: pointer;
}

.logo-sponsors img {
  height: 45px;
  width: auto;
}

.purchaseButton--loading .purchaseButton__text {
  visibility: hidden;
  opacity: 0;
}

.purchaseButton--loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

/* Other breakpoints */
@media (min-width: 1200px) {
  .banner-heroInfo p {
    white-space: nowrap;
  }
}

/* Other breakpoints */
@media (max-width: 1199px) {
  .events .quizz_modal_container {
    height: 90%;
  }

  .events .hero-container p.text-nowrap {
    white-space: wrap !important;
  }

  .carrousel-box.middle {
    font-size: 16px;
  }

  .program-grid {
    grid-template-columns: repeat(4, 1fr);
    padding-left: 12%;
    gap: 0;
  }

  .program-grid .jad2022:nth-of-type(4) {
    grid-row: 6 !important;
  }
}

@media (max-width: 991px) {
  .program-grid {
    grid-template-columns: repeat(3, 1fr);
    padding-left: 15%;

    .position-absolute {
      left: -105px;
    }
  }

  .main-container {
    max-width: 100%;
  }

  .carrousel-box.middle {
    font-size: 14px;
    padding: 0px 2%;
  }

  .header-book {
    display: none;
  }

  .logo-partner .logo-container {
    margin: 20px;
  }

  .logo-company.principal {
    border: none;
    margin-bottom: 40px;
  }

  .logo-partner {
    justify-content: center;
  }

  .line-around-md:before,
  .line-around-md:after {
    content: "";
    display: none;
  }
}

@media (max-width: 768px) {
  .program.events h2 {
    font-size: 1rem;
  }

  .program.events .header h2 {
    font-size: 1.1rem;
    text-align: center;
  }

  .program-grid {
    grid-template-columns: repeat(2, 1fr);
    padding-left: 18%;

    .position-absolute {
      left: -90px;

      p {
        font-size: 1rem !important;
      }
    }

    p {
      max-width: 90%;
      padding: 0 3% 0 0;
      justify-content: start;
    }
  }

  .carrousel-box.middle {
    font-size: 18px;
    padding: 0px 5%;
    width: 100%;
    height: 300px;
  }

  .carrousel-box {
    width: 100%;
  }

  .hero-container.hero-home {
    background-size: 100% !important;
    min-height: 700px !important;
    padding: 20px;
  }

  .hero-container.hero-page {
    height: 380px !important;
    padding: 20px;
  }

  .header-info {
    flex-direction: column !important;

    div {
      margin: 20px 0px;
    }
  }

  .upper-pricing {
    position: relative;
  }

  .pricing-card {
    margin-bottom: 30px;
  }

  .register-section section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1,
    h2,
    h3 {
      text-align: center;
    }

    img,
    .btn-round {
      margin: 20px 0px;
    }
  }

  .register-section .purchase-main section {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }

  .purchase-logo {
    align-items: center;
    margin-bottom: 30px;
  }

  .purchase-main section,
  .purchase-main section > div {
    width: 100% !important;
  }

  .purchase-main section > div,
  .purchase-main > footer,
  .purchase-main section > h3,
  .purchase-main section > p {
    padding: 0 5%;
  }

  .register-section section div {
    justify-content: center;
    align-items: center;
    flex-direction: column !important;

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 20px;
    }
  }

  .cap-speaker-link.gallery {
    height: auto !important;
    width: auto !important;
  }

  .modal-container .vertical-line {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .program-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 425px) {
  .pricing-card {
    width: 280px;
  }
}

/*** EXPIRY TEMPLATES ***/
.post-event {
  .modal {
    width: 75%;

    input {
      width: 500px;
    }

    @media only screen and (max-width: 768px) {
      input {
        width: 400px;
      }
    }

    @media only screen and (max-width: 425px) {
      input {
        width: 175px;
      }
    }
  }

  &__section {
    display: flex;
    width: 100%;

    &.-first {
      .post-event__image {
        width: 25%;
      }

      .post-event__content {
        width: 50%;
      }

      .post-event__logo {
        padding: 2rem 0rem;
        width: 25%;

        img {
          width: 75%;

          max-width: 250px;
        }
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;

        .post-event__image,
        .post-event__content,
        .post-event__logo {
          width: 100%;
        }
      }
    }

    &.-second {
      .post-event__image {
        width: 50%;
      }

      .post-event__content {
        width: 50%;
      }

      @media only screen and (max-width: 768px) {
        .post-event__image,
        .post-event__content {
          width: 100%;
        }
      }
    }

    &.-third {
      .post-event__image {
        width: 25%;
      }

      .post-event__content {
        width: 75%;
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: column-reverse;

        .post-event__image,
        .post-event__content {
          width: 100%;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__button {
    padding: 1rem 3rem;
    background-color: black;
    color: white;
    border-radius: 5rem;
    cursor: pointer;
    white-space: nowrap;
    border: black;
    outline: inherit;

    &.-modal {
      background-color: red;
      border: red;
    }

    &:hover {
      text-decoration: none;
      color: white;
    }

    &.-disabled {
      cursor: none;
      background-color: grey;
      border: grey;
      pointer-events: none;
    }

    &:disabled {
      cursor: none;
      background-color: grey;
      border: grey;
      pointer-events: none;
    }
  }
}

.event-button {
  margin: 1rem 0.16rem;
  padding: 0.75rem 2.5rem 0.82rem;
  border-radius: 3rem;
  cursor: pointer;
  color: white;
  min-width: 150px;
  text-decoration: none;
  white-space: nowrap;
  font-family: Graphik-Medium;
  user-select: none;
  background-color: #fe0505;
  border: none;
  text-align: center;

  span,
  a {
    text-decoration: none;
    color: white;
  }

  &.-primary {
    background-color: #f8c14a;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.5) !important;
    text-decoration: none;
    color: white;
  }

  &.-disabled {
    background: lightgray;
    pointer-events: none;
  }
}

/// POOOL STYLES ////

#poool-widget {
  position: relative;
}
