
.logo-event img {
        height: 52.7px;
        width: auto;
        margin: 0px 15px;
}


.dark-vertical-line {
    height: 70px;
    width: 1px;
    border-left: 1px solid rgb(141, 141, 141);
    margin: 0px 20px;
    
}

.promo-code-section {
    height: 60px;
    display: flex;
    align-items: center;
}

.signup-form.promo-code input {
    margin: 0 5px !important;
    border-radius: 10px;
    max-width: 100px;
}

.text-credit-card {
    font-family: Graphik-Regular!important;
    font-style: italic;
    font-size: 13px;
    margin-bottom: 0px;
    margin-top: 8px;
    font-weight: 600;
}

.purchase-table tr{
   height: 30px;
}

.purchase-table thead ,.purchase-table tfoot {
    height: 50px;
}

.purchase-table tfoot {
    border-top: 1px solid #dee2e6;
}

.purchase-table td:nth-child(2), .purchase-table th:nth-child(2){
    text-align: right;
}

.purchaseButton:hover {
    background-color: rgb(244, 54, 54);
}

.purchaseButton:focus {
    background-color: rgb(234, 16, 16);
}

.totalprice--loading::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid transparent;
    border-top-color: #000000;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}


#purchase-confirmation {
    display: none;  
}

.confirmation {     
    a {
        max-width: 250px;
        color: white;
        text-decoration: none;
    }
}

// .cap.confirmation.purchase p{
//     max-width: 664px;
//     font-family: Graphik-Semibold;
//     line-height: 24px;
//     color: #30363d;
//     padding: 0px 37px;
// }

.filling-bar {
    width: 10%;
    height: 100%;
}

#onetime-purchase {
    .image-stripe {
        height: 46px;
    }

    .purchaseflow-title {
        font-size: 18px;
        font-family: Graphik-SemiBold
    }
}

.select {
    background-color: #fff !important;
    border-radius: 6px !important;
    border: 2px solid #ddd !important;
}


@media (min-width: 767.98px) {

    .plans-section {
        grid-area: plans;
    }
}

@media screen and (max-width: 576px) {
    
    .payment-section {
        grid-area: payment;
        width: 100%;
    }

    .plans-section {
        grid-area: plans;
    }

    .plans-section:first-child {
        width: 100%;
    }

    .logo-event img {
        height: 32.7px;
        width: auto;
        margin: 0 2%;
    }
    .dark-vertical-line {
        height: 55px;
        width: 1px;
        border-left: 1px solid rgb(141, 141, 141);
        margin: 0px 5px;
        
    }

    #onetime-purchase {    
        .purchaseflow-title {
            font-size: 16px;
        }
    }

}