.other-cap2024 {
  &__section {
    h2 {
      font-size: 55px;
    }

    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      padding: 0rem 1rem;
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column-reverse;
    }
  }

  &__persons {
    display: inline-block;

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: row;
    }
  }

  &__block {
    width: 100%;
    display: flex;
    justify-content: space-between;

    border-top: 1px solid black;
    padding: 2rem 0 5rem;

    @media only screen and (max-width: 1200px) {
      padding: 2rem 1rem 5rem;
    }

    @media only screen and (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    .nav-link {
      color: black;
      width: 100%;
      height: 100%;
      font-size: 18px;

      &.active {
        font-family: Graphik-SemiBold;
        background-color: #fff;
        border: none;
        border-bottom: 5px solid #007bff;
        color: black;

        &:hover {
          opacity: 1;
          border-bottom: 5px solid #007bff;
        }
      }

      &:hover {
        border: none;
        opacity: 0.7;
      }
    }
  }

  &__content {
    display: flex;
    width: 100%;
    max-width: 900px;

    @media only screen and (max-width: 425px) {
      flex-direction: column;
    }
  }

  &__calendly {
    max-width: 400px;
    margin-left: 2rem;

    @media only screen and (max-width: 1000px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-top: 2rem;
    }
  }
}

.other-csc2024 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 25px;

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 12px;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);

    &:first-child {
      border-bottom: 2px solid #000000;
    }
  }

  td:nth-child(2) {
    text-align: right;
  }

  th:nth-child(2) {
    text-align: right;
  }

  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
}

.other-jad2025 {
  &__section {
    min-height: 525px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    height: fit-content;

    &--second {
      min-height: 269px;
    }

    &__strong {
      font-size: 28px;
      font-weight: 700;
      font-family: Graphik-Semibold;
      color: white;
    }

    &--discount {
      background: #114e62;
      height: 340px;
    }

    &--cta {
      background: white;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: fit-content;
      width: 100%;
      padding: 3.5rem;

      @media (max-width: 1000px) {
        padding: 1rem;
      }
    }

    @media (max-width: 1000px) {
      height: fit-content;
    }

    &__text-container {
      padding-left: 2rem;
      margin: auto;
      width: 580px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #171717;
      font-family: "Graphik-Regular";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      &--second {
        padding: 2rem;
        width: fit-content;

        @media (max-width: 1000px) {
          padding: 0;
        }
      }

      &--cta {
        width: 100%;
      }

      &__buttons {
        display: flex;
        gap: 1rem;

        @media (max-width: 764px) {
          flex-direction: column;
        }

        div,
        a {
          width: 380px !important;
          padding: 15px 0 !important;

          @media (max-width: 764px) {
            width: 100% !important;
          }
        }
      }

      @media (max-width: 1000px) {
        padding: 2rem;
      }

      &__title {
        color: #114e62;
        font-family: Graphik-Semibold;
        font-size: 28px;
        font-weight: 700;
        line-height: 36px;
      }

      &__subtitle {
        color: #171717;
        font-family: Graphik-Semibold;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;

        &--second {
          margin: 0;
          width: 600px;

          @media (max-width: 1000px) {
            width: fit-content;
          }
        }
      }

      &__light {
        color: #707070;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      }
    }

    &__image-container {
      @media (max-width: 1000px) {
        display: none;
      }

      img {
        width: 480px;
        height: 525px;
      }

      &--second {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 2rem;
        width: 240px;

        @media (max-width: 1000px) {
          display: none;
        }
        img {
          width: 114px;
          height: 113.984px;
        }
      }
    }

    &--collabs {
      background: white;
      display: flex;
      flex-direction: column;
      min-height: fit-content;
      width: 100%;
      padding: 3.5rem;
      padding-bottom: 0;

      @media (max-width: 1000px) {
        padding: 1rem;
      }

      p {
        padding-left: 2rem;
        color: #171717;
        font-family: "Graphik-Regular";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: 0;
      }
    }

    &__collabs-images {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-left: 2rem;
      transform: translateX(-12px);

      @media (max-width: 575px) {
        gap: 20px;
      }

      img {
        transform: scale(0.8);
      }
    }
  }
}
