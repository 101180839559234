:root {
    --border-r: 15px;
}

.comparator-title {
    font-family: Tinos;
    font-size: 52px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: center;
    margin-top: 3%;
}

.comparator-p {
    font-family: Graphik-Semibold;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
}

.comparator-highlight {
    color: #0b94ff;
}

.comparator-sub-title {
    font-family: Graphik-Semibold;
    font-size: 20px;
    color: white;
    text-align: center;
    vertical-align: middle;
}

.comparator-mid-title {
    font-family: Graphik-Medium;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: center;
    color: #0b94ff;
    margin-bottom: 2%;
    margin-top: 2%;
}

.shape1 {
    background: #005ca5;
    margin: 0 auto 0;
    width: 469px;
    max-width: 469px;
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shape2 {
    background: #0389f2;
    margin: 0 auto 0;
    width: 568px;
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shape3 {
    background: #046ec1;
    margin: 0 auto 0;
    width: 626px;
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shape4 {
    background: #0b94ff;
    margin: 0 auto 0;
    width: 692px;
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.shape5 {
    background: #005292;
    margin: 0 auto 0;
    width: 769px;
    height: 52px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rectangle {
    background-color: #ed1c24;
    margin: 0 auto 0;
    padding: 0;
    width: 122px;
    height: 71px;
    border-top-left-radius: var(--border-r);
    border-bottom-left-radius: var(--border-r);
    border-bottom-right-radius: var(--border-r);
}

.comparator-product-title {
    font-family: Graphik-Semibold;
    font-size: 32px;
}

.comp-li {
    font-family: Graphik-Regular;
    font-size: 17px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.53;
    letter-spacing: normal;
    color: #2b2b2b;
}

.economis {
    font-family: Graphik-Semibold;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.economis2 {
    font-family: Graphik-Semibold;
    font-size: 32px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.81;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.vertical {
    display: table-cell;
    vertical-align: middle;
}

.pro-color {
    color: #ed1c24;
}

.Dj-PRO-Connectez {
    width: 422px;
    max-width: 100%;
    height: 40px;
    font-family: Graphik-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    color: #2b2b2b;
}


.comparator-legend {
    border-bottom: 4px solid #000;
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 0;
    line-height: 1.17;
}

.laptop-img {
    max-width: 100% !important;
    height: auto;
    display: block;
}

.new-img {
    max-width: 100% !important;
    height: auto;
    display: block;
}

.text-fr {
    max-width: 100% !important;
    height: auto;
    display: block;
}

.comparator-text-list {
    max-width: 726px;
    width: 100%;
    height: auto;
}

.comparator-btn-black {
    font-size: 16px;
    font-family: Graphik-Regular;
    border-radius: 23px;
    background-color: #252525;
    height: 46px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.comparator-btn-blue {
    font-size: 16px;
    font-family: Graphik-Regular;
    border-radius: 23px;
    background-color: #0b94ff;
    height: 46px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.comparator-btn-red {
    font-size: 16px;
    font-family: Graphik-Regular;
    border-radius: 23px;
    background-color: #e1201d;
    height: 46px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.plan-subtitle {
    font-family: Graphik-Medium;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: center;
    color: #2b2b2b;
}

.comparator-discount {
    color: #ed1c24;
    font-family: Graphik-Semibold;
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.69;
    letter-spacing: normal;
    text-align: center;
}

.comparator-button-desktop {
    display: none;
}

/* FINDER UNLOCK */
.finder-sub {
    font-family: Graphik-Medium;
    font-size: 19px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #2b2b2b;
}

.finder-sub-2 {
    font-family: Graphik-Regular;
    font-size: 15px;
    font-stretch: normal;
    letter-spacing: normal;
    color: #2b2b2b;
    font-weight: 500;
    font-style: normal;
    line-height: 1.37;
}

.finder-individual {
    font-family: Tinos;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #2b2b2b;
    max-width: 239px;
}

.ul-no-bullets {
    list-style-type: none;
    padding: 0;
}

.pdf-icon {
    padding-right: 5px;
}

.du-vivant-title {
    font-family: Graphik-Semibold;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #2b2b2b;
    text-transform: uppercase;
}

.du-vivant-item {
    font-family: Graphik-Regular;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #2b2b2b;
}

/* Modal styles*/
.space-f {
    margin-top: 2%;
    margin-bottom: 2%;
}

.modal-title-f {
    font-family: Graphik-Medium;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
}

.modal-text {
    font-family: Graphik-Regular;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    color: #2b2b2b;
}

.modal-background {
    background-color: #2b2b2b;
}

.modal {
    width: 620px;
    margin: auto;
    left: auto !important;
}

.modal.pyament-card {
    width: 750px;
    margin: auto;
}

.btn.btn-red.btn-round:hover {
    color: white;
    background-color: #e71920;
    box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 1px 1px 5px 3px rgba(0, 0, 0, 0.12);
  }
  
  #PROAccessModal .modal-body {
    padding: 30px 70px !important;
  }
  
  #PROAccessModal {
    left: 50% !important;
    transform: translateX(-50%);
  }

@media (max-width: 575.98px) {
    .comparator-title {
        font-size: 32px;
    }

    .comparator-img-container {
        display: none;
    }

    .modal.cancel-subscription {
        left: 0% !important;
      }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .comparator-title {
        font-size: 42px;
    }

    .comparator-img-container {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .comparator-img {
        width: 200px;
    }

    .comparator-hidden-tablet {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .modal.cancel-subscription {
      left: 0% !important;
    }
  
    #ModalCancelSubscription {
      width: 550px !important;
    }
  
    #PROAccessModal {
      left: 8% !important;
    }
  }

@media (min-width: 992px) {
    .comparator-hidden-tablet {
        display: none;
    }

    .comparator-button-mobile {
        display: none;
    }

    .comparator-button-desktop {
        display: block;
    }
}
