.eja-button {
  font-size: 18px !important;
  margin: 0.5rem;
  padding: 15px 25px;
  border-radius: 2rem;
  min-width: 200px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  display: inline-block;
  user-select: none;
  background-color: #2f2f2f;
  font-family: Graphik-SemiBold;
  border: none;
  text-align: center;
  transition: all 0.3s ease-in;

  &:hover {
    opacity: 0.7;
    text-decoration: none;
    color: white;
  }

  &.-panel {
    font-size: 16px;
    border-radius: 4px;
    background-color: #2b2b2b;
    padding: 7.5px 25px;
  }

  &.-small {
    padding: 7.5px 25px;
    min-width: 165px;
  }

  &.-white {
    background-color: white;
    border: 1px solid black;
    color: black;
  }

  &.-radar{
    background-color: transparent;
    color: #007bff;
    font-family: Graphik-Medium;
    &:hover{
      opacity: 1;
      background-color: 
      rgba(178, 215, 255, 0.3);
    }
  }

  &.-radar-secondary{
    background-color: #007bff;
  }

  &.-primary {
    background-color: #f20015;
  }

  &.-secondary {
    background-color: #f8c14a;
  }

  &.-corpo-products {
    background-color: #c91515;
  }

  &.-orange {
    background-color: #ef0c23;
  }

  &.-darkgrey {
    background-color: #343a40;
  }

  &.-grey {
    background-color: #545454;
  }

  &.-prfeed{
    background-color: #FF5A11;
  }

  &.-lightgrey {
    background-color: #c2c2c2;
    color: black;

    &:hover {
      color: black;
    }
  }

  &.-disabled {
    background: #878787;
    color: white;
    pointer-events: none;
  }
}

.eja-link {
  text-decoration: underline;
  font-family: Graphik-Medium;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #007bff;
  transition: all 0.3s ease-in;

  &.-primary {
    color: #f20015;
  }

  &.-black {
    color: black;
  }

  &.-prfeed{
    color: #FF5A11;
  }

  &.-aih {
    text-decoration: none;
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }

  &.-radar {
    text-decoration: none;
    color: black;
    &:hover {
      color: #007bff;
      text-decoration: underline;
    }
  }

  &.-radar-secondary {
    color: #007bff;
  }


  &:hover {
    opacity: 0.7;
  }

  &.-logo{
    text-decoration: none;

    &:hover {
      opacity: 1;
    }
  }
}

.eja-input {
  &__text {
    font-size: 18px !important;
    padding: 0.25rem 1rem;
    margin: 0.75rem 0.75rem 0.25rem 0;
    border-radius: 7px;
    border: 1px solid black;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;

    &::placeholder{
      font-style: italic;
    }

    &:focus {
      border: 1.5px solid #555;
    }

    &.-error {
      border: 1px solid #ea212e;
      background: #ffdbdb;
    }
  }

  &__radio {
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    min-width: 15px;
    min-height: 15px;
    border-radius: 50%;

    /* The background will be the radio dot's color. */
    background: transparent;

    /* The border will be the spacing between the dot and the outer circle */
    border: 3px solid white;

    /* And by creating a box-shadow with no offset and no blur, we have an outer circle */
    box-shadow: 0 0 0 1px black;

    &.-large {
      border: 5px solid white !important;
      min-width: 25px;
      min-height: 25px;
    }

    &:checked {
      box-shadow: 0 0 0 1px black;
      background-color: black;
      border-width: 0.2rem;
    }

    &.-primary {
      // accent-color: #f20015;
      &:checked {
        box-shadow: 0 0 0 1px #f20015;
        background-color: #f20015;
        border-width: 0.2rem;
      }
    }

    &.-secondary {
      // accent-color: #f8c14a;
      &:checked {
        box-shadow: 0 0 0 1px #f8c14a;
        background-color: #f8c14a;
        border-width: 0.2rem;
      }
    }
  }

  &__select {
    &.-uneditable {
      border: none;
      border-width: 0;
      box-shadow: none;
      pointer-events: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background-color: white !important;

      &::-ms-expand {
        display: none;
      }
    }
  }

  &__checkbox {
    /* The container */
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      top: 5px;
      right: 20px;
      // height: 0;
      // width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: -2px;
      left: 0;
      height: 20px;
      width: 20px;
      border: 1px solid black;
    }

    /* On mouse-over, add a background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a color background */
    input:checked ~ .checkmark {
      background-color: transparent;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 6px;
      top: 1px;
      width: 7px;
      height: 14px;
      border: solid black;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &.-uneditable {
    border: none;
    border-width: 0;
    box-shadow: none;
    pointer-events: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: white !important;

    &::-ms-expand {
      display: none;
    }
  }
}

.eja-modal {
  left: 50% !important;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 850px;

  .modal-header {
    border: none !important;
  }

  .modal-body {
    padding: 0 30px 30px 30px !important;
  }

  &.-top {
    top: 5%;
    transform: translate(-50%, -40%);
    max-width: 550px;

    .modal-dialog {
      -webkit-transform: translate(0) !important;
      -o-transform: translate(0) !important;
      transform: translate(0) !important;
      top: 35%;
      margin: 0 auto;
    }

    .modal-body {
      padding: 0px 10px !important;
    }
  }

  .modal-content {
    border-radius: 1.5rem;
  }

  .modal-dialog {
    -webkit-transform: translate(0, -50%) !important;
    -o-transform: translate(0, -50%) !important;
    transform: translate(0, -50%) !important;
    top: 50%;
    margin: 0 auto;
  }

  &__fields {
    display: flex;
    align-items: center;

    @media screen and (max-width: 425px) {
      flex-direction: column;
      justify-content: center;
    }
  }
}
