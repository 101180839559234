//SUBSCRIBE
#signup #id_job_title_other{
      margin-left: 67px;
    padding-left: 15px;
}
#subscribe, #signup{
  font-size: 14px;
  #content {
    padding:0;
  }
  main{
    padding-bottom: 40px;
  }

  #login_form{
    label{
      padding-left: 0 !important;
    }
  }

  #subscribe-header{
    background-color: $darkgrey;
    padding: 10px 0;
    h2{
      margin: 0;
      font-size: 16px;
      color: #fff;
      padding-top: 34px;
      font-family: $fancyfont;
      text-transform: none;
    }
    .navbar-nav{
      li{
        float: none;
        display: inline-block;
      }
    }
  }
  #subscribe-intro{
    .lead{
      color: $maincolor;
      font-size: 35px;
      font-weight: 900;
      text-align: right;
      padding-top: 30px;
      border-top: 1px solid #000;
    }
  }
  form{
    label.label-lg{
      text-transform: uppercase;
    }
    label{
        .form-control{
          font-size: 14px;
        }
        .maincolor{
          font-style: italic;
        }
      .extra-label{
          text-transform: initial;
          font-weight: 400;
        }
      input{
        border: 0;
        border-bottom: 2px solid $lightgrey;
        border-radius: 0;
        background: transparent;
        box-shadow: none;
        padding: 12px 0;
        height: auto;
        color: $darkgrey;
        line-height: 1.4;
      }
    }
  }
  #subscribe-steps{
    .steps-header{
      border: 2px solid $darkgrey;
      .steps-count{
        background-color: $darkgrey;
        margin: 0;
        color: #fff;
        font-size: 24px;
        font-family: "Montserrat", sans-serif;
        text-align: center;
        padding: 10px;
      }
      h2{
        padding: 10px 10px 10px 45px;
        margin: 0;
      }
    }
    .steps-content{
      padding: 30px 0;
      hr{
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .total-row{
        margin-bottom: 15px;
        display: block;
        text-align: right;
      }
    }
    .forms-errors {
      p{
        margin: 7px 0 0 !important;
      }
    }
    #step2{
      #subscribe-choices{
        margin-bottom: 25px;
      }
      #id_job_title_other{
        margin-left: 67px;
        padding-left: 15px;
      }
    }
    #step4{
      .btn{
        margin: 0;
        padding: 14px;
        min-width: 250px;
      }
    }
  }
}

#subscribe {

  legend {
    text-align: center;
    font-family: 'Roboto Slab';
    color: $grey;
    padding-top: 20px;
    margin-bottom: 10px;
    border: 0;
  }

  hr {
    margin: 20px 0;
  }
  #subscribe-choices {
    margin-left: 80px;
  }

  .color-bloc {
    padding-left: 0;
    margin-left: 80px;
  }

  .lightgrey-bloc {
    background: none;
  }

}

.wizard > .content > .body {
  position: relative;
  width: 100%;
  padding: 40px 0;
}

.step-intro{
  text-align: center;
  font-size: 21px;
  color: #70777d;
}

.wizard > .steps .disabled a, .wizard > .steps .disabled a:hover, .wizard > .steps .disabled a:active {
  color: $darkgrey;
  border-radius: 0;
  background-color: $white;
  font-size: 16px;
  font-family: "Monserrat", sans-serif;
  text-align: center;
  padding: 10px;
  position: relative;
  border-top: solid 2px $darkgrey;
  border-bottom: solid 2px $darkgrey;
  border-left: 0;

  &:after, &:before {
    content: "";
    width: 20px;
    height: 43px;
    display: block;
    position: absolute;
    right: -2px;
    border-right: solid 2px $darkgrey;
    z-index: 1;
  }

  &:before{
    transform: rotate(-20deg);
    top: -1px;
    transform-origin: top right;
  }

  &:after{
    transform: rotate(20deg);
    bottom: -1px;
    transform-origin: bottom right;
  }

}

.wizard > .steps .done a, .wizard > .steps .done a:hover, .wizard > .steps .done a:active {
  color: $darkgrey;
  border: solid 2px $darkgrey;
  border-radius: 0;
  background-color: $white;
  font-size: 16px;
  font-family: "Monserrat", sans-serif;
  text-align: center;
  padding: 10px;
  position: relative;

  &:after, &:before {
    content: "";
    width: 20px;
    height: 43px;
    display: block;
    position: absolute;
    right: -2px;
    border-right: solid 2px $darkgrey;
    z-index: 1;
    background-color: $white;
  }

  &:before{
    transform: rotate(-20deg);
    top: -1px;
    transform-origin: top right;
  }

  &:after{
    transform: rotate(20deg);
    bottom: -1px;
    transform-origin: bottom right;
  }
}

.wizard>.steps .current a, .wizard>.steps .current a:hover, .wizard>.steps .current a:active {
  color: $white;
  border: solid 2px $darkgrey;
  background-color: $darkgrey!important;
  border-radius: 0px;
  font-size: 16px;
  font-family: "Monserrat", sans-serif;
  text-align: center;
  padding: 10px;
  position: relative;

  &:after, &:before {
    content: "";
    background-color: $darkgrey!important;
    width: 20px;
    height: 43px;
    display: block;
    position: absolute;
    right: -3px;
    z-index: 1;
  }
  &:before{
    transform: rotate(-20deg);
    top: -1px;
    transform-origin: top right;
  }
  &:after{
    transform: rotate(20deg);
    bottom: -1px;
    transform-origin: bottom right;
  }
}

.wizard>.steps>ul>li:first-child a{
    border-left: solid 2px $black;
    
}

.wizard>.steps>ul>li:last-child a{
    border-right: solid 2px $black;
    &:before, &:after {
      display: none;
    }
}


.tab-title{
  font-size: 24px;
}




.wizard > .steps .number {
  display: none;
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
    padding: 10px;
  transition: none !important;
}

.wizard > .content {
  background: none;
  min-height: 100px;
  margin: 0;
}

.wizard > .content > .body input {
  border: 0;
  border-bottom: 2px solid #dddfe1;
  border-radius: 0;
  background: transparent;
  padding: 7px 0 !important;
  height: auto;
  color: #202020;
  line-height: 1.4;
}


#subscribe form label input, #signup form label input {
  display: inline-block;
  margin-right: 10px;
}





.wizard > .content > .body ul {
  list-style: none!important;
}

.wizard > .actions a {
  min-width: 320px;
  text-align: center;
  border-radius: 0;
  font-family: "Monserrat", sans-serif;
  font-size: 20px;
}

.wizard > .actions > ul {
  width: 100%;
}


.wizard > .actions > ul > li {
  margin: 0;

  a{
    border-radius: 0 !important;
    &:focus, &:active{
      color: #000 !important;
    }
  }

  &:first-child{

    a {
        background: $grey;
        color: $white;
        border: solid 2px $grey;

      &:hover {
        background: transparent;
        color: $grey;
        border: solid 2px $grey;
      }
    }
  }

  &:nth-child(2) {
    float: right;

    a {
      background: $black;
      color: white;
      border: solid 2px $black;
      transition: all .5s;

      &:hover {
            background: transparent;
            color: $black;
            border: solid 2px $black;
      }
    }
  }

  &:last-child {
    float: right;

    a {
      background: $black;
    color: white;
    border: solid 2px $black;
    transition: all .5s;

      &:hover {
            background: transparent;
            color: $black;
            border: solid 2px $black;
      }
    }
  }
}

#recap{
  .fa{
    margin-right: 5px;
  }
}

.wizard > .steps a, .wizard > .steps a:hover, .wizard > .steps a:active {
  margin: 0;
}

#sub-sub{
  font-size: 20px;
}

.wizard > .steps > ul > li {
  width: 25%;
  margin: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.wizard > .actions .disabled a, .wizard > .actions .disabled a:hover, .wizard > .actions .disabled a:active {
    background: transparent;
    color: #2184be;
    border: solid 2px #2184be;
    display: none;
}


.wizard > .content > .body input.error {
  //background: none;
  border: 0;
  border-bottom: solid 2px #ff4020;

}

.wizard > .content > .body label.error {
  color: $ja-red;
}

#checkbox_error_message{
  color: $ja-red;
}

#profile_confirmation_error_message{
  color: $ja-red;
}

#profile_form {
 h4 {
   font-size: 22px;
   color: $darkgrey;
   margin-bottom: 20px;
 }

}

  .input-text-check {
    width: 100%;
    text-align: center;
    background-color: #dddfe1;
    padding: 20px;
  }

  .confirm-text {
    position: relative;
    font-size: 21px;
    color: $grey;
    label{
      padding: 0;
      margin: 0 !important;
      font-weight: 400;
    }
    input:before{
      font-size: 13px;
    }
  }

  #profile_confirmation {
    position: relative;
  }

#id_business_activities {
  margin-bottom: 25px;
}

#new-content {
  .form-group select{
    float: right;
    max-width: 312px;
  }

  .form-group .form-control {
    font-size: 14px;
  }
}