.pro-title {
    font-family: Graphik-Semibold;
    font-size: 28px;
}

.pro-title-resp {
    font-family: Graphik-Semibold;
    font-size: 20px;
}

.pro {
    color: #ed1c24;
}

.black-line {
    border-bottom: 4px solid #2b2b2b;
}

.hr-black {
    border-top: 1px solid #000;
}

.pro-subtitle {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 52px;
    line-height: 54px;
}

.pro-subtitle-resp {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.05;
}

.little-subtitle {
    font-family: Graphik-Semibold;
    font-size: 18px;
    margin-top: 10px;
}

.pro-featured-title {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 20px;
}

.pro-featured-text {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 1;
}

.check-icon-niveau {
    height: 45px;
    width: 45px;
}

.title-item {
    font-family: Graphik-Bold !important;
    font-size: 20px;
}

.title-item-alt, .title-item-sm {
    font-family: Graphik-Regular;
    font-size: 16px;
}

.title-item-lg {
    font-family: Graphik-Semibold;
    font-size: 20px;
}

.subtitle-item-niveau {
    font-family: Graphik-Semibold;
    font-size: 18px;
}

.info-pro {
    font-family: Graphik-Regular;
    font-size: 16px;
    margin-top: 25px;
}

.category-section {
    padding-bottom: 46px;
}

.title-category {
    font-family: Graphik-Semibold;
    font-size: 34px;
    line-height: 1.2;
    margin-bottom: 8px;
}

.subtitle-category {
    font-family: Graphik-Semibold;
    font-size: 21px;
}

.info-category {
    margin-bottom: 20px;
}

.pro-text-md {
    font-size: 17px;
}

.pro-img {
    width: 380px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.hyphen-list {
    list-style-type: none;
    padding-left: 0;
}

.hyphen-list .hyphen-list-item {
    position: relative;
    padding-left: 15px;
}

.hyphen-list .hyphen-list-item:before {
    content: "-";
    position: absolute;
    left: 0;
}

.form-bold-label label {
    font-weight: bold;
}

.container-images {
    display: none;
}

.item-sm, .item-md, .item-xs {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.item-xs {
    max-width: 525px;
}

.item-sm {
    max-width: 650px;
}

.item-md {
    max-width: 1000px;
}

.mt-50-resp {
    margin-top: 25px;
}

.mb-30-resp {
    margin-bottom: 20px;
}

.mb-50-resp {
    margin-bottom: 25px;
}

.mb-90-resp {
    margin-bottom: 60px;
}

@media (max-width: 575.98px) {
    .container-info {
        padding-left: 15px;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .container-images {
        display: block;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .col-lg-6:first-child .category-section {
        padding-left: 30px;
    }

    .category-section {
        height: 340px;
    }

    .category-section-en {
        height: 360px;
    }

    .pro-title-resp {
        font-size: 28px;
    }

    .pro-subtitle-resp {
        font-size: 52px;
    }

    .title-item-alt {
        font-size: 20px;
    }

    .title-item-sm {
        font-size: 18px;
    }

    .title-item-lg {
        font-size: 26px;
    }

    .pro-featured-title {
        font-size: 24px;
    }

    .pro-featured-text {
        font-size: 40px;
    }

    .mt-50-resp {
        margin-top: 50px;
    }

    .mb-30-resp {
        margin-bottom: 30px;
    }

    .mb-50-resp {
        margin-bottom: 50px;
    }

    .mb-90-resp {
        margin-bottom: 90px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .category-section {
        height: 280px;
    }

    .category-section-en {
        height: 320px;
    }

    .container-info {
        padding-left: 45px;
    }
}

/* HD Laptop */
@media (min-width: 1360px) {

}
