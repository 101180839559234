.cover-list-container {
    overflow: hidden;
}

.cover-list {
    margin: -20px;
}

.cover-list .cover-item {
    display: inline-block;
    padding: 20px;
}

.cover-magazine-img {
    width: 211px;
    max-width: 100%;
    margin-bottom: 20px;
}

.cover-magazine-title {
    font-size: 24px;
}

h3.cover-magazine-title {
    font-family: Graphik-Semibold;
}

/* Promotion Magazine */
/* =================================================================================================================== */

.pro-title {
    font-family: Graphik-Semibold;
    font-size: 28px;
    line-height: 32px;
}

.pro {
    color: #ed1c24;
}

.black-line {
    border-bottom: 4px solid #2b2b2b;
}

.main-title {
    line-height: 60px;
    display: flex;
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 52px;
    justify-content: center;
    margin-top: 30px;
}

.main-title > div {
    text-align: center;
    width: 70%;
}

.subtitle-magazine-mag {
    display: flex;
    font-family: Graphik-Medium;
    font-size: 18px;
    justify-content: center;
    margin: 30px 0;
}

.subtitle-magazine-mag > div {
    text-align: center;
    width: 70%;
}

.wrapper-middle-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.firs-middle-text {
    font-family: Graphik-Medium;
    font-size: 16px;
    margin: 40px 0;
    text-align: center;
    width: 50%;
}

.second-middle-text {
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin: 40px 0;
    text-align: center;
    width: 50%;
}

.title-category {
    font-family: Graphik-Semibold;
    font-size: 36px;
}

.check-icon {
    height: 16px;
    width: 16px;
}

.item-text {
    font-family: Graphik-Semibold;
    font-size: 16px;
}



/* END Promotion Magazines */
/* =================================================================================================================== */



/* Promotion Responsive */
/* =================================================================================================================== */

@media (max-width: 991.98px) {
    div.tittle-w {
        font-size: 11px;
    }
}

@media (max-width: 767.98px) {
    .block-product {
      padding-bottom: 0;
    }

    .main-title > div {
        width: 100%;
    }

    .subtitle-magazine > div {
        width: 100%;
    }

    div.elem-absolute {
        position: relative;
    }
    .main-title {
        font-size: 35px;
        line-height: 35px;
    }

    div.wrapper-relative {
        height: initial;
    }

    /* IMAGES */
    div.img-rot-pos0 {
        left: 0;
        text-align: center;
    }

    div.img-rot-pos0 > img {
        margin-bottom: 15px;
    }

    div.img-rot-pos1 {
        left: 0;
        text-align: center;
    }

    div.img-rot-pos1 > img {
        margin-bottom: 15px;
    }

    div.img-rot-pos2 {
        left: 0;
        text-align: center;
    }

    div.img-rot-pos2 > img {
        margin-bottom: 15px;
    }

    div.img-rot-pos3 {
        left: 0;
        text-align: center;
    }

    div.img-rot-pos3 > img {
        margin-bottom: 15px;
    }

    div.img-rot-pos4 {
        left: 0;
        text-align: center;
    }

    div.img-rot-pos4 > img {
        margin-bottom: 15px;
    }

    /* CANVAS */
    div.tittle-w {
        font-size: 16px;
    }

    div.tittle-rot-pos0 {
        left: 0;
        transform: rotate(0deg);
        margin-bottom: 15px;
    }

    div.tittle-rot-pos1 {
        left: 0;
        transform: rotate(0deg);
        margin-bottom: 15px;
    }

    div.tittle-rot-pos2 {
        left: 0;
        transform: rotate(0deg);
        margin-bottom: 15px;
    }

    div.tittle-rot-pos3 {
        left: 0 !important;
        transform: rotate(0deg);
        width: 100%;
        margin-bottom: 15px;
    }

    div.tittle-rot-pos4 {
        left: 0;
        transform: rotate(0deg);
        margin-bottom: 15px;
    }

    .firs-middle-text {
        width: 100%;
        margin: 10px 0;
    }

    .second-middle-text {
        width: 100%;
        margin: 10px 0;
    }

    .package-section {
        height: 100% !important;
    }

    .block-product {
        margin-top: 45px;
    }
}

/* END Promotion Responsive */
/* =================================================================================================================== */



/* Banner Style Responsive */
/* =================================================================================================================== */

.block-product {
  padding-bottom: 66px;
}

/* Wrapper Block properties */
div.wrapper-relative {
  position: relative;
  width: 100%;
  height: 600px;
  font-weight: bold;
  color: #ffffff;
}

/* Define the block text size, color and aling*/
div.tittle-w {
  text-align: center;
  background-color: #2b2b2b;
  color:#ffffff;
  font-weight: bold;

  width: auto;
  padding:1em;
  padding-left: 3em;
  padding-right: 3em;
}

/* Define the block text has an absolte position*/
div.elem-absolute {
  position: absolute;
}

/* Rotation and position of the block text */
div.tittle-rot-pos0 {
  -ms-transform: rotate(4deg); /* IE 9 */
  -webkit-transform: rotate(4deg); /* Safari 3-8 */
  transform: rotate(4deg);

    top: 58%;
    left:38%;
}

div.tittle-rot-pos1 {
  -ms-transform: rotate(1deg); /* IE 9 */
  -webkit-transform: rotate(1deg); /* Safari 3-8 */
  transform: rotate(1deg);

    top: 65%;
    left:22%;
}

div.tittle-rot-pos2 {
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);

  top: 73%;
  left:27%;
}

div.tittle-rot-pos3 {
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);

  top: 80%;
  left:30%;
}

div.tittle-rot-pos4 {
  -ms-transform: rotate(358deg); /* IE 9 */
  -webkit-transform: rotate(358deg); /* Safari 3-8 */
  transform: rotate(358deg);

  top: 89%;
  left:28%;
}


/* Color style of the prime text */
.cero-txt{
  color: #ffa913;
}

.frst-txt{
  color: #0b94ff;
}

.scnd-txt{
  color: #c8007f;
}

.thrd-txt{
  color: #ff613a;
}

.frth-txt{
  color: #960db2;
}

/* Image postion and rotation */
div.img-rot-pos0{
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);
  max-width: 100%;
  top: 1%;
  left:20%;
}

div.img-rot-pos1{
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);
  max-width: 100%;
  top: 10%;
  left:30%;
}

div.img-rot-pos2{
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);
  max-width: 100%;
  top: 1%;
  left:60%;
}

div.img-rot-pos3{
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);
  max-width: 100%;
  top: 10%;
  left:50%;
}

div.img-rot-pos4{
  -ms-transform: rotate(0deg); /* IE 9 */
  -webkit-transform: rotate(0deg); /* Safari 3-8 */
  transform: rotate(0deg);
  max-width: 100%;
  top: 20%;
  left:40%;
}

/* END Banner Style Responsive */
/* =================================================================================================================== */
