:root {
    --color-dark: #2B2B2B;
    --color-grey: #d1d1d1;
    --color-white: #FFFFFF;
    --color-red: #ed1c24;
    --color-success: #91b569;
}

.margin-15 {
    margin-top: 15px;
}

.breadcrumb {
    background-color: #FFF;
    margin-top: 20px;
    padding: 0;
    font-size: 14px;
    font-family: Graphik-Semibold;
}

.breadcrumb-item>a {
    color: #8D8D8D;
    font-family: Graphik-Regular;
}

.breadcrumb .active {
    color: #8D8D8D;
    font-family: Graphik-Medium;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '>';
}

.subtitle-item {
    border-bottom: 3px solid black;
}

.subtitle-item {
    font-family: Graphik-Semibold;
    font-size: 20px;
    color: #2b2b2b;
}

.subtitle-item-alt {
    font-family: Graphik-Medium;
    font-size: 15px;
    color: #2b2b2b;
    border-bottom: 3px solid black;
    text-transform: uppercase;
}

.btn-black {
    background-color: var(--color-dark);
    color: var(--color-white);
    border-radius: 25px;
    font-size: 15px;
    padding: 3px 18px;
    padding-top: 5px !important;
}

.btn-grey {
    background-color: #b3b3b3;
    border-radius: 25px;
    font-size: 15px;
    padding: 3px 18px;
    color: white !important;
}

.btn-grey:hover {
    background-color: rgb(207, 207, 207);
}

.btn-red.btn-card {
    border-radius: 25px;
    font-size: 15px !important;
    padding: 3px 18px;
}

.btn-transparent {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.second-section {
    margin-top: 40px;
    margin-bottom: 40px;
}

.third-section {
    margin-bottom: 40px;
}

.gsemibold-15 {
    font-family: Graphik-Semibold;
    font-size: 15px;
}

.gregular-15 {
    font-family: Graphik-Regular;
    font-size: 15px;
}

.gmedium-15 {
    font-family: Graphik-Medium;
    font-size: 15px;
}

.fa-eye {
    cursor: pointer;
}

.errorlist {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
}

.errorlist>li {
    color: var(--color-red);
    padding: 0;
}

.text-error {
    color: var(--color-red);
}

.text-success {
    color: var(--color-success);
}

#magazine-rd .text-link-sky:hover {
    color: #2f99e8;
}

.package-name {
    color: var(--color-red);
    text-transform: uppercase;
    font-family: Graphik-Semibold;
    font-size: 23px;
}

.list-detail-forfait>li {
    display: block;
    height: 35px;
    line-height: 35px;
    padding-left: 0;
}






/* MY TRANSACTION */
// .subtitle-transaction {
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     margin-top: 15px;
// }

// .grey-note {
//     color: var(--color-grey-light);
//     font-family: Graphik-MediumItalic;
//     font-size: 12px;
//     margin-top: 12px;
// }

// #div_id_language>div {
//     margin-top: 10px;
// }

/* STRIPE STYLING */
// .StripeElement {
//     width: 100%;
//     padding: 10px 12px;
//     background-color: #fff;
//     border: 1px solid #bebfc0;
//     border-radius: 6px;
// }

// .StripeElement--webkit-autofill {
//     background-color: #f1f1f1;
//     border: 1px solid #f1f1f1;
// }

// .StripeElement--invalid {
//     border: 1px solid #ed1c24;
// }

// .StripeElement--focus {
//     border-color: #aaa !important;
//     box-shadow: 0 0 0 0.2rem rgba(100, 100, 100, 0.15) !important;
// }

/* Profile Contest Style */
// .profile-contest-container {
//     display: flex;
//     width: 100%;
//     margin: 25px 0 50px 0;
//     justify-content: center;
//     height: 111px;

// }

// .profile-contest-left {
//     background: #2b2b2b;
//     display: flex;
//     align-items: center;
//     border-top-left-radius: 70px;
//     border-bottom-left-radius: 70px;
//     padding: 26px 26px 26px 39px;
//     position: relative;
// }

// .pr-custom {
//     padding-right: 71px;
// }

// .profile-contest-left:after {
//     position: absolute;
//     content: '';
//     width: 0;
//     height: 0;
//     border-left: 25px solid transparent;
//     border-right: 25px solid transparent;
//     border-top: 20px solid #2b2b2b;
//     bottom: -20px;
//     left: 75px;
//     display: block;
// }

// .profile-contest-right {
//     background: #eee;
//     display: flex;
//     align-items: center;
//     border-top-right-radius: 70px;
//     border-bottom-right-radius: 70px;
//     padding: 23px;
// }

// .profile-contest-right-alt {
//     background: #eee;
//     display: flex;
//     align-items: center;
//     border-top-right-radius: 70px;
//     border-bottom-right-radius: 70px;
//     padding: 23px;
// }

// .profile-contest-right p {
//     font-size: 16px;
// }

// .contest-profile-rules-link {
//     color: #2b2b2b;
//     font-family: Graphik-Medium;
//     font-size: 13px;
//     text-decoration: underline;
// }

// .contest-profile-rules-link:hover {
//     color: #2b2b2b;
// }

// .btn-contest-profile {
//     background-color: #f20015;
//     color: #fff;
//     font-size: 16px;
//     font-family: Graphik-Medium;
//     text-transform: initial;
//     border-radius: 50px;
//     padding: 10px 25px;
// }

// .btn-contest-profile:hover,
// .btn-contest-profile:active,
// .btn-contest-profile:focus {
//     background-color: #c40013;
//     color: #fff;
//     text-decoration: none;
// }

// .btn-contest-profile-alt {
//     background-color: #f20015;
//     color: #fff;
//     font-size: 15px;
//     font-family: Graphik-Medium;
//     text-transform: initial;
//     border-radius: 50px;
//     padding: 3px 18px;
//     border: none;
//     cursor: pointer;
// }

// .btn-contest-profile-alt:hover,
// .btn-contest-profile-alt:active,
// .btn-contest-profile-alt:focus {
//     background-color: #c40013;
//     color: #fff;
//     text-decoration: none;
// }

// .contest-profile-text-red {
//     color: #f20015;
// }

// Payement Methome Modal
#submit_payment {
    display: block;
}

.btn-validation-wrapper {
    min-width: 100px;
}



@media (max-width: 1002px) {
    .profile-contest-container {
        height: initial;
        flex-direction: column;
    }

    .profile-contest-left {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        border-bottom-left-radius: initial;
        padding: 15px;
        justify-content: space-around;
    }

    .profile-contest-left:after {
        display: none;
    }

    .profile-contest-right,
    .profile-contest-right-alt {
        border-bottom-left-radius: 30px;
        border-top-right-radius: initial;
        border-bottom-right-radius: 30px;
        position: relative;
        padding: 15px;
        flex-direction: column;
    }

    .contest-profile-text {
        margin-bottom: 10px;
    }

    .profile-contest-right:after,
    .profile-contest-right-alt:after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-top: 20px solid #eee;
        bottom: -20px;
        left: 75px;
        display: block;
    }

    .profile-contest-right p {
        font-size: 14px;
    }

    .btn-contest-profile {
        padding: 5px 15px;
        font-size: 14px;
    }

    .text-success-contest {
        border-left: none !important;
        padding-top: 15px !important;
    }
}

@media (max-width: 1220px) {
    .profile-contest-left h1 {
        font-size: 18px;
    }

    .pr-custom {
        padding-right: 20px;
    }

    .profile-contest-left img {
        width: 65px;
    }

    .profile-contest-left {
        padding: 15px;
    }

    .profile-contest-right p,
    .profile-contest-right-alt p {
        font-size: 12px !important;
    }

    .contest-profile-rules-link {
        font-size: 12px;
    }

    .btn-contest-profile {
        padding: 5px 15px;
        font-size: 14px;
    }

    .text-success-contest {
        padding-top: 0;
    }
}

.text-success-contest {
    color: #29c981;
    font-family: Graphik-Semibold;
    font-size: 17px;
    padding-left: 10px;
    border-left: 1px solid #979797;
    padding-top: 0;
}

/*New Profile Structure*/
.border-custom {
    border-right: 2px solid #9f9f9f;
    border-bottom: none;
}

.client-brief {
    font-family: Graphik-Medium;
    font-size: 15px;
    color: #2b2b2b;
    margin-bottom: 40px;

    p {
        margin-bottom: 0;
        text-transform: none;
    }
}

.profile-sep-line {
    width: 100%;
    background-color: #2b2b2b;
    height: 3px;
    border: none !important;
    margin: 25px 0 35px 0 !important;
}

.profile-nav {
    display: flex;
    flex-direction: column;

    .profile-nav-item {
        position: relative;
        font-family: Graphik-Regular;
        font-size: 17px;
        color: #2b2b2b;
        text-decoration: none;
        margin-bottom: 24px;
        padding-left: 12px;

        .nav-indicator {
            position: absolute;
            top: 6px;
            left: 0;
            width: 0;
            height: 0;
            border-top: 6px solid transparent;
            border-left: 6px solid #2b2b2b;
            border-bottom: 6px solid transparent;
            display: none;
        }

        &:hover,
        &:active,
        &:focus {
            font-family: Graphik-Semibold;

            .nav-indicator {
                display: block;
            }
        }
    }

    .active {
        font-family: Graphik-Semibold;

        .nav-indicator {
            display: block;
        }
    }
}

.adv-list {
    display: flex;
    flex-direction: column;

    .adv-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .img-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 50px;
        }

        p {
            font-family: Graphik-Medium;
            font-size: 16px;
            color: #2b2b2b;
            line-height: 18px;
            margin-bottom: 0;
        }
    }
}

.text-normal {
    text-transform: none !important;
}

.btn-profile {
    font-family: Graphik-Medium;
    font-size: 10px;
    margin-left: 15px;
    padding: 4px 15px !important;
    text-transform: none !important;
}

.btn-profile:hover {
    text-decoration: none !important;
    background-color: rgb(255, 66, 66);
    color: var(--white);
    transition: all .3s ease-in-out;
}

.profile-payment-method {
    font-family: Graphik-Semibold;
    font-size: 15px;
    color: #2b2b2b;

    span {
        font-family: Graphik-Regular;
        color: #8d8d8d;
    }
}

.new-card-profile-container {
    margin-bottom: 0;
    width: 100%;
}


.card-brand img {
    width: 40px;
    padding-bottom: 5px;
    height: auto;
}

.profile-link {
    font-family: Graphik-Semibold;
    font-size: 15px;
    color: #0091ff;
    background-color: #fff;
    border: none;

    &:active,
    &:focus,
    &:hover {
        outline: none;
    }

    &:hover {
        color: #0071c9;
        text-decoration: underline;
    }
}

.pl-custom {
    padding-left: 35px !important;
}

.top-0 {
    .magazine-radio-checkmark {
        top: 0 !important;
    }
}

.client-radio {
    .radio div {
        display: flex;
        align-items: center;
        height: 65%;
    }

    .form-group {
        display: flex !important;
    }

    label.form-check-inline {
        position: relative;
        padding-left: 25px;
        margin-right: 45px;
        margin-bottom: 0;
        cursor: pointer;
        font-family: Graphik-Regular;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked {
                ~.client-radio-checkmark {
                    &:after {
                        display: block;
                    }
                }

                ~.magazine-radio-checkmark {
                    &:after {
                        display: block;
                    }
                }
            }
        }

        .client-radio-checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid #979797;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            border-radius: 50%;

            &:after {
                top: 3px;
                left: 3px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #2b2b2b;
                content: "";
                position: absolute;
                display: none;
            }
        }

        .magazine-radio-checkmark {
            position: absolute;
            top: 8px;
            left: 0;
            width: 20px;
            height: 20px;
            border: 1px solid #979797;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
            border-radius: 50%;

            &:after {
                top: 2px;
                left: 2px;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: #2b2b2b;
                content: "";
                position: absolute;
                display: none;
            }
        }

        &:hover {
            input {
                ~.magazine-radio-checkmark {
                    background-color: #eee;
                }
            }
        }
    }
}

.is-hidden {
    display: none;
}

#alert-message {
    margin-top: 1rem;
}

.margin-input {
    label.form-check-inline {
        margin-right: 15px !important;
    }
}

.client-checkbox {
    label {
        margin-bottom: 15px;
    }

    .checkbox {
        div {
            display: flex;
            flex-direction: column;
        }
    }

    label.form-check-inline {
        position: relative;
        padding-left: 35px;
        cursor: pointer;
        font-family: Graphik-Regular;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked {
                ~.client-checkbox-checkmark {
                    background-color: #2b2b2b;

                    &:after {
                        display: block;
                    }
                }
            }
        }

        .client-checkbox-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: solid 2px #2b2b2b;

            &:after {
                left: 5px;
                top: 1px;
                width: 6px;
                height: 12px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                position: absolute;
                display: none;
            }
        }

        &:hover {
            input {
                ~.client-radio-checkmark {
                    background-color: #eee;
                }
            }
        }
    }
}


.corpo-logo {
    width: 175px;
    height: auto;
}

#magazin-adress {

    // Bootstrap Control form 
    .col-form-label:after {
        content: "*";
    }

    #div_id_postal-province .col-form-label:after,
    #div_id_postal-address2 .col-form-label:after {
        content: "";
    }
}

#permission-number {
    display: none;
    transition: all 0.3s ease-in-out;
}

#amf-validation {
    position: absolute;
    display: block;
    font-size: 0.8rem;
    top: 30px;
    text-align: right;
    color: red;
    display: none;
}

#ModalCancelSubscription {
    width: 800px !important;

    .modal-content {
        overflow: hidden;
    }

    .modal-body.d-flex {
        width: 200%;
    }

    .modal-body {
        width: 100%;
        padding: 0 !important;

        .cancel-container {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

        }

        .modal-footer button {
            font-size: 15px !important;
            margin-left: 15px;
            margin-bottom: 0px;
            padding: 3px 18px;
            height: 30px;
            min-width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .cancel-item {
            padding-top: 3px;
            font-size: 1rem;
            font-weight: 300 !important;
            font-family: Graphik-Regular;
        }
    }
}

#magazin-adress .col-form-label {
    font-family: Graphik-Semibold;
}

.client-checkbox-alt {
    .form-check {
        padding: 0;
    }

    .form-text {
        padding-left: 35px;
        margin: 0;
        color: #2b2b2b !important;
    }

    label.form-check-label {
        position: relative;
        padding-left: 35px;
        padding-bottom: 10px;
        cursor: pointer;
        font-family: Graphik-Semibold;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked {
                ~.client-checkbox-checkmark {
                    background-color: #2b2b2b;

                    &:after {
                        display: block;
                    }
                }
            }
        }

        .client-checkbox-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            border: solid 2px #2b2b2b;

            &:after {
                left: 5px;
                top: 1px;
                width: 6px;
                height: 12px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
                position: absolute;
                display: none;
            }
        }

        &:hover {
            input {
                ~.client-radio-checkmark {
                    background-color: #eee;
                }
            }
        }
    }
}

.showAdress {
    display: none;
}

.bulletin-note {
    font-family: Graphik-RegularItalic;
    font-size: 13px;
    color: #2b2b2b;
    text-align: right;

    .note-link {
        color: #2b2b2b;
        text-decoration: underline;
    }
}

@media (max-width: 992px) {
    .border-custom {
        border-right: none;
        border-bottom: 2px solid #9f9f9f;
    }

    .btn-profile {
        margin-left: 0;
    }


    #amf-validation {
        top: 50px;
    }

}

/*** MY EVENTS - MES EVENEMENTS ***/

// #events-profil-app {
//     margin-left: 1rem;

//     .content {
//         border: 1px solid black;
//         padding: 1rem 1rem 1rem 0.5rem;
//         margin-top: 2rem;
//         width: 100%;
//     }

//     .content-container {
//         border-bottom: 1px solid black;
//         padding: 2rem 0rem;
//         width: 100%;
//     }

//     .content-logo {
//         width: 15rem;
//     }

//     .content-button {
//         cursor: pointer;
//         border-radius: 2.5rem;
//         background-color: black;
//         color: white;
//         padding: 0.5rem 2rem 0.5rem;
//         display: inline-flex;
//         flex-direction: column;
//         justify-content: center;
//         min-width: 225px;
//         max-width: 350px;
//         max-height: 50px;
//         text-align: center;
//         user-select: none;
//     }

//     .content-button:hover {
//         color: white;
//         text-decoration: none;
//     }

//     .disabled {
//         pointer-events: none;
//         background-color: #878787;
//     }

//     label {
//         margin: 1rem 1rem 0.25rem 0rem;
//     }

//     input {
//         min-width: 240px;
//         width: 100%;
//     }

//     ::placeholder {
//         color: red;
//         opacity: 1;
//         /* Firefox */
//     }


//     :-ms-input-placeholder {
//         /* Internet Explorer 10-11 */
//         color: red;
//     }

//     ::-ms-input-placeholder {
//         /* Microsoft Edge */
//         color: red;
//     }

//     .allergies {

//         &::placeholder {
//             color: black;
//             opacity: 1;
//         }

//         &:-ms-input-placeholder {
//             /* Internet Explorer 10-11 */
//             color: black;
//         }

//         &::-ms-input-placeholder {
//             /* Microsoft Edge */
//             color: black;
//         }

//     }

//     select {
//         min-width: 200px;
//     }

//     select:invalid {
//         color: red;
//     }

//     .uneditable {
//         border: none;
//         border-width: 0;
//         box-shadow: none;
//         pointer-events: none;
//         -moz-appearance: none;
//         -webkit-appearance: none;
//         background-color: white !important;
//     }

//     h2 {
//         font-size: 30px;
//         text-decoration: underline;
//     }

//     .uneditable::-ms-expand {
//         display: none;
//     }
// }

/*** MY CREDITS - MES CREDITS ***/
#credits-profil-app {
    .no-amf {
        background-color: rgba(220, 10, 19, 0.1);
        color: red;
        padding: 1rem;

        &__button {
            background-color: red;
            color: white;
            padding: 0.25rem 1rem;
            border-radius: 2rem;
            display: flex;
            align-items: center;
            text-align: center;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .credit-event {
        border-bottom: 4px solid black;

        &__title {
            padding: 1rem 0rem;
            font-size: 37px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;

        }

        &__activities {
            padding: 2rem 0rem;
            border-top: 1px solid grey;
        }

        &__activity {
            display: flex;
            justify-content: space-between;
            padding: 1.5rem 1rem 1.5rem 0rem;
            color: black;

            &:hover {
                background-color: #F0F0F0;
                text-decoration: none;
            }

            @media (max-width: 768px) {
                flex-direction: column;
            }

        }

        &__arrow {
            // border: solid black;
            // border-width: 0 6px 6px 0;
            // display: inline-block;
            // padding: 15px;

            // &.-close {
            //     transform: rotate(-135deg);
            //     -webkit-transform: rotate(-135deg);
            //     margin-top: 1rem;
            // }

            // &.-open {
            //     transform: rotate(45deg);
            //     -webkit-transform: rotate(45deg);
            //     margin-bottom: 1rem;
            // }

            font-size: 75px;
            margin-bottom: 1rem;
        }

        &__button {
            min-width: 230px;
            display: flex;
            align-items: flex-end;
            justify-content: end;
            flex-direction: column;

            @media (max-width: 768px) {
                margin-top: 1rem;
            }

            div:first-child {
                background-color: black;
                white-space: nowrap;
                color: white;
                padding: 0.25rem 1rem;
                border-radius: 2rem;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

/*End New Profile Structure*/