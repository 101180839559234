// #login{
//   height: 100%;
//   #content{
//     height: 100%;
//     padding-top: 0;
//     #login-content{
//       height: 100%;
//       .container-fluid{
//         height: 100%;
//         .row{
//           height: 100%;
//         }
// 		.col{
// 			height: 100%;
// 		}
//       }
//     }
//   }
//   img{
//     width: 250px;
//     margin-bottom: 30px;
//   }
//   .color-bloc{
//     max-width: 450px;
//     margin: auto;
//     text-align: center;
//   }
//   .btn-default{
//     border: 1px solid $lightgrey;
//   }
//   form{

//     .form-group {
//       position: relative;
//       input{
//         background-color: #fff;
//       }
//     }
//     label{
//       position: absolute;
//       left: -120px;
//       font-size: 14px;
//       top: 10px;
//       width: 110px;
//       text-align: right;
//     }
//   }
// }

.modal-footer{

  .btn-default {
    border: solid 2px $lightgrey;
  }

  .btn{
    white-space: initial;
    width: 48%;
  }
}
