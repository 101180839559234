:root {
    --color-person: #0B94FF;
    --color-damage: #FFA913;
    --color-economy: #662ABC;
    --color-entrepreneur: #FF5A11;
    --color-health: #33D688;
    --color-society: #C8007F;
    --color-dark: #2B2B2B;
    --color-dark-light: #30363D;
    --color-grey-light: #9D9D9D;
    --color-red: #ED1C24;
    --color-white: #FFF;
    --color-ad: #D5D5D5;
    --color-tag: #8D8D8D;
    --color-background-ad: #F2F2F2;
}

body {
    margin: 0;
    padding: 0;
}

#content-dark {
    background-color: var(--color-dark);
    display: none;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 100;
    opacity: 0.41;
}

/* LINKS BOX */
.container-links {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 35px;
    text-align: right;
    font-family: Graphik-Medium;
    padding: 25px 0;
}

.container-links>a {
    color: var(--color-dark);
    text-decoration: none;
    font-size: 14px;
}

.container-links>a:nth-child(1) {
    margin-right: 15px;
}

/* ALERT HEADER BOOK-ANDRE-CYR */
.alert-header {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    top:0;
    color: #BA2727;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    height: 53px;
    background: #FAB0B0;
    font-family: "Graphik-Regular";

    p {
        margin:0;
    }

    &__container {
        position: relative;
        width: 100%;

        &__hide-button {
            all:unset;
            position: absolute;
            right: 20px;
            top:50%;
            transform: translateY(-50%);
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
                cursor: pointer;
                opacity: 0.8;
            }
        }
    }
}

/* SUBMENU */
.submenu {
    display: none;
    position: absolute;
    background-color: #F6F6F6;
    top: 35px;
    width: 170px;
    padding: 0;
    z-index: 1000;
}

.submenu>li {
    display: block;
    padding: 0;
    text-align: left;
    border-bottom: 1px solid #d5d5d5;
}

.submenu>li:hover {
    background-color: var(--color-dark);
}

.submenu>li:hover>a {
    color: white;
    text-decoration: none;
    display: inline-block;
}

.submenu>li>a {
    color: black;
    display: inline-block;
    width: 100%;
    padding: 10px 0 10px 15px;
}

/* LOGO */
.container-logo {
    display: flex;
    align-items: center;
}

.logo {
    height: 40px;
}

/* HAMBURGER ICON */
.box-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.box-bar:hover {
    cursor: pointer;
}

.box-bar>span {
    font-size: 10px;
    font-family: Graphik-Medium;
}

.box-bar>i {
    font-size: 2em;
}

/* NAVBAR */
.navmenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-dark);
}

.navmenu>ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 100%;
}


.navmenu>ul>li {
    margin: 0 10px;
    display: inline;
    padding: 0 10px;
    text-decoration: none;
    height: 47px;
    line-height: 47px;
}

.navmenu>ul>li>a {
    color: #FFF;
    font-family: Graphik-Medium;
    font-size: 14px;
}

.search>i {
    color: #FFF;
    margin-right: 12px;
}

.search>i:hover {
    cursor: pointer;
}

.input-search {
    border: 0;
    background-color: var(--color-dark) !important;
    font-family: "Graphik-Regular";
    height: 24px !important;
    padding: 0 5px;
    width: 190px;
    color: var(--color-white);
    outline: none !important;
}

.input-search::placeholder {
    color: var(--color-white);
}

.input-search::-webkit-input-placeholder {
    color: var(--color-white);
}

.input-search::-moz-placeholder {
    color: var(--color-white);
    opacity: 1;
}

.input-search:-moz-placeholder {
    color: var(--color-white);
    opacity: 1;
}

.input-search::-ms-input-placeholder {
    color: var(--color-white);
}

/* LI HOVER */
.item-person:hover {
    background-color: var(--color-person);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

.item-damage:hover {
    background-color: var(--color-damage);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

.item-economy:hover {
    background-color: var(--color-economy);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

.item-entrepreneur:hover {
    background-color: var(--color-entrepreneur);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

.item-health:hover {
    background-color: var(--color-health);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

.item-society:hover {
    background-color: var(--color-society);
    height: 90px;
    line-height: 115px;
    position: relative;
    top: -13px;
}

/* LI HIGHLIGHTED */
.item-person-highlighted {
    background-color: var(--color-person);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

.item-damage-highlighted {
    background-color: var(--color-damage);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

.item-economy-highlighted {
    background-color: var(--color-economy);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

.item-entrepreneur-highlighted {
    background-color: var(--color-entrepreneur);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

.item-health-highlighted {
    background-color: var(--color-health);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

.item-society-highlighted {
    background-color: var(--color-society);
    height: 90px !important;
    line-height: 115px !important;
    position: relative;
    top: -13px;
}

/* LI ACTIVE */
.item-person-active {
    background-color: var(--color-person);
    height: 60px;
    line-height: 60px;
}

.item-damage-active {
    background-color: var(--color-damage);
    height: 60px;
    line-height: 60px;
}

.item-economy-active {
    background-color: var(--color-economy);
    height: 60px;
    line-height: 60px;
}

.item-entrepreneur-active {
    background-color: var(--color-entrepreneur);
    height: 60px;
    line-height: 60px;
}

.item-health-active {
    background-color: var(--color-health);
    height: 60px;
    line-height: 60px;
}

.item-society-active {
    background-color: var(--color-society);
    height: 60px;
    line-height: 60px;
}

/* A HOVER */
.item-person>a:hover {
    color: #FFF;
}

.item-damage>a:hover {
    color: #FFF;
}

.item-economy>a:hover {
    color: #FFF;
}

.item-entrepreneur>a:hover {
    color: #FFF;
}

.item-health>a:hover {
    color: #FFF;
}

.item-society>a:hover {
    color: #FFF;
}

/* A STYLE */
.item-person>a {
    border-bottom: 2px solid var(--color-person);
    text-decoration: none;
}

.item-damage>a {
    border-bottom: 2px solid var(--color-damage);
    text-decoration: none;
}

.item-economy>a {
    border-bottom: 2px solid var(--color-economy);
    text-decoration: none;
}

.item-entrepreneur>a {
    border-bottom: 2px solid var(--color-entrepreneur);
    text-decoration: none;
}

.item-health>a {
    border-bottom: 2px solid var(--color-health);
    text-decoration: none;
}

.item-society>a {
    border-bottom: 2px solid var(--color-society);
    text-decoration: none;
}

/* SIDEBAR */
.sidebar {
    display: none;
    width: 300px;
    position: fixed;
    top: 0;
    z-index: 300;
    left: 0;
    background-color: #FFF;
    /*height: 102%;*/
    /*min-height: 1000px;*/
    /*overflow: scroll;*/
    overflow-y: auto;
    height: 100%;
    padding-bottom: 50px;
}

#icon-class {
    cursor: pointer;
}

.sidebar>ul {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.sidebar>ul>li {
    padding: 2px 13px;
    // line-height: 30px;
}

.first-block-items>li:not(:first-child),
.second-block-items>li:not(:first-child),
.third-block-items>li:not(:first-child),
.fourth-block-items>li:not(:first-child),
.fifth-block-items>li:not(:first-child) {
    border-left: 16px solid #FFF;
}

.sidebar>ul>li>a {
    display: inline-block;
    color: #2b2b2b;
    font-family: Graphik-Regular;
    font-size: 15px;
    line-height: 1.2;
}

.sidebar>ul>li:first-child {
    font-family: Graphik-Medium;
}

.sidebar hr {
    border-top: 1px solid #000;
    margin-left: 30px;
    margin-right: 15px;
}

.text-red {
    color: var(--color-red);
}

.btn-round-md {
    height: 40px;
    border-radius: 20px;
}

.btn-light-white {
    color: #fff;
    border: 1px solid white !important;
    background-color: transparent;
    padding: 10px 35px !important;
    text-decoration: none !important;
    border-color: white !important;
}


.btn-red {
    background-color: #ed1c24;
    color: #FFF;
    font-family: Graphik-Medium;
    margin-bottom: 30px;
}

.btn-red-cancel-popup {
    background-color: #ed1c24;
    color: #FFF;
    font-family: Graphik-Medium;
    border-radius: 25px;
    font-size: 15px;
    padding: 3px 18px;
}

.btn-text-light {
    text-transform: none !important;
    font-size: 15px;
    font-family: Graphik-Regular;
    padding: 5px 20px;
}

.btn-42 {
    height: 42px;
}

.btn-black {
    background-color: var(--color-dark);
    color: #FFF;
    font-family: Graphik-Medium;
}

.link-person {
    border-left: 16px solid var(--color-person);
}

.link-damage {
    border-left: 16px solid var(--color-damage);
}

.link-economy {
    border-left: 16px solid var(--color-economy);
}

.link-entrepreneur {
    border-left: 16px solid var(--color-entrepreneur);
}

.link-health {
    border-left: 16px solid var(--color-health);
}

.link-society {
    border-left: 16px solid var(--color-society);
}

/* LINK HOVER */
.link-person:hover {
    background-color: var(--color-person);
}

.link-damage:hover {
    background-color: var(--color-damage);
}

.link-economy:hover {
    background-color: var(--color-economy);
}

.link-entrepreneur:hover {
    background-color: var(--color-entrepreneur);
}

.link-health:hover {
    background-color: var(--color-health);
}

.link-society:hover {
    background-color: var(--color-society);
}

/* LINK HOVER */
.link-person:hover a {
    color: #FFF;
    text-decoration: none;
}

.link-damage:hover a {
    color: #FFF;
    text-decoration: none;
}

.link-economy:hover a {
    color: #FFF;
    text-decoration: none;
}

.link-entrepreneur:hover a {
    color: #FFF;
    text-decoration: none;
}

.link-health:hover a {
    color: #FFF;
    text-decoration: none;
}

.link-society:hover a {
    color: #FFF;
    text-decoration: none;
}

/* MODAL */
.custom-modal {
  display: none;
  position: fixed;
  z-index: 20000;
  background-color: #fff;
  top: 50%;
  left: 50%;
  width: 620px;
  margin-top: -175px;
  margin-left: -310px;
  border-top: 19px solid black;
}

.custom-modal > i {
  position: absolute;
  right: -35px;
  color: #fff;
  top: -50px;
}

.custom-modal-200 {
  height: 200px;
}

.custom-modal-230 {
  height: 230px;
}

.custom-modal-300 {
  height: 350px;
}

.custom-modal > .modal-header {
  border: 0;
  padding: 10px;
}

.custom-modal > .modal-header-border {
  border-bottom: 1px solid #979797;
}

.custom-modal > .custom-modal-footer {
  text-align: center;
}

.custom-modal > .modal-header > span {
  margin: 0 auto;
  font-family: Graphik-Medium;
  font-size: 32px;
}

.close-modal {
  cursor: pointer;
}


/* FOOTER PAGE */
.footer-home {
    background-color: var(--color-dark-light);
    bottom: 0;
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 15px;
}

.footer-container {
    width: 1230px;
    color: #FFF;
    margin-top: 40px;
    margin-bottom: 40px;
}

.footer-home p {
    font-family: Graphik-Regular;
    font-size: 15px;
}

.list-footer {
    list-style: none;
    font-family: Graphik-Regular;
    font-size: 15px;
}

.list-footer>li {
    list-style-type: none;
}

.list-footer>li>a {
    color: #FFF;
    text-decoration: none;
}

/* DETAIL ARTICLE */
.article-title {
    color: var(--color-dark);
    font-family: Graphik-Semibold;
    font-size: 40px;
    line-height: 1.2;
}

.source-article {
    font-family: Graphik-Regular;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    color: #8D8D8D;
}

.source-article-link:hover,
.source-article-link:focus {
    text-decoration: none;
    color: var(--color-dark);
}


#subscription-section {
    padding: 30px;
    min-height: 166px;
    color: #2b2b2b;
    background-position: center;
    background-size: cover;
    margin: 30px 0;

    .subs-section-header {
        align-items: center;

        img {
            height: 17px;
            margin-right: 10px;
        }

        p {
            margin: 0;
            padding-top: 2px;
            font-family: Graphik-Medium;
            font-size: 14px;
            letter-spacing: 1px;
        }
    }

    h1 {
        margin-top: 13px;
        font-family: Graphik-Semibold;
        font-size: 32px;
    }

    .form-group {
        margin-right: 22px;
        margin-bottom: 20px;
    }

    input {
        border-radius: 10px;
        font-size: 15px;
        height: 42px;
        margin-right: 30px;
    }


    .subs-section-footer {
        font-size: 15px;
        font-family: Graphik-MediumItalic;
        margin-bottom: 0px;
    }

    .subs-section-footer.text-style-normal {
        font-family: Graphik-Medium !important;
    }

}




.tags-container-alt {
    line-height: 1.2;
}

.third-ads {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 0 20px 0;
}

.custom-i {
    background-color: #FFF;
    border-radius: 25px;
    color: var(--color-dark-light);
    display: inline-block;
    padding: 5px;
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
}

.custom-i:hover {
    color: var(--color-dark-light);
}

.content-article,
.content-article>p {
    font-family: 'Tinos', serif;
    font-weight: 400;
    font-size: 20px;
    margin-top: 30px;
    overflow-wrap: break-word;
    /*text-align: justify;*/
}

.content-article strong {
    font-family: 'Tinos', serif;
    font-weight: 700;
}

.content-article h5 {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 28px;
    margin-top: 40px;
}

.buttons-share {
    /*width: 50px;*/
    position: absolute;
    left: -50px;
}

.buttons-share>a {
    margin-bottom: 12px;
}

.share-button,
.open-mail {
    border-radius: 25px;
    border: 1px solid var(--color-dark);
    color: var(--color-dark);
    display: block;
    padding: 5px;
    width: 40px;
    height: 40px;
    line-height: 30px;
    text-align: center;
    margin-right: 10px;
}

.share-button:hover,
.open-mail:hover {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.tags {
    margin: 10px 0;
}

.tags-alt {
    margin: 5px 15px;
}

.tags>a,
.tags-mobile>a {
    border-radius: 4px;
    border: 1px solid var(--color-grey-light);
    color: var(--color-dark);
    font-family: Graphik-Medium;
    font-size: 14px;
    padding: 2px 10px;
}

.tags>a:hover,
.tags-mobile>a:hover {
    text-decoration: none;
    color: var(--color-white);
    background-color: var(--color-dark);
    border: 1px solid var(--color-dark);
}

.image-legend>p {
    color: var(--color-grey-light);
    font-family: Graphik-Regular;
    font-size: 13px;
    margin-top: 15px;
    text-align: justify;
}

.leyend-title {
    border-bottom: 3px solid #0b94ff;
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-black {
    /*border-bottom: 4px solid #000;*/
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
    line-height: 1.17;
}

.leyend-title-black>span {
    border-bottom: 4px solid #000;
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
    display: block;
    width: 100%;
}

.leyend-title-person,
.leyend-title-personnes {
    border-bottom: 4px solid var(--color-person);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-damage,
.leyend-title-dommages {
    border-bottom: 4px solid var(--color-damage);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-economy,
.leyend-title-économie {
    border-bottom: 4px solid var(--color-economy);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-entrepreneur,
.leyend-title-entrepreneuriat {
    border-bottom: 4px solid var(--color-entrepreneur);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-health,
.leyend-title-santé {
    border-bottom: 4px solid var(--color-health);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.leyend-title-society,
.leyend-title-société {
    border-bottom: 4px solid var(--color-society);
    font-family: Graphik-Semibold;
    font-size: 24px;
    margin-top: 30px;
}

.most-popular-new {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    padding: 20px 0;
    width: 100%;
}

.most-popular-new>a {
    color: var(--color-dark);
    font-family: Graphik-Semibold;
    font-size: 14px;
}

.most-popular-new>a>img {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.container-articles-tags {
    /*display: inline-block;*/
    /*margin: 5px 15px;*/
    /*width: 100%;*/
    display: grid;
    grid-template-columns: 1fr 1fr 2px 1fr 2px 1fr 2px 1fr;
    column-gap: 5px;
    margin: 0 15px 15px;
}

.container-articles-tags>div {
    display: inline-block;
}

.container-articles-tags>div>a {
    color: var(--color-dark);
    font-family: Graphik-Semibold
}

.container-articles-tags>div>a:hover {
    color: var(--color-person);
    text-decoration: none;
}

.container-field-search {
    margin-top: 45px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 6px;
}

.container-field-search>button {
    background-color: var(--color-dark);
    border-radius: 25px;
    color: var(--color-white);
    font-family: Graphik-Semibold;
    font-size: 13px;
    height: 30px;
    line-height: 15px;
    padding: 0 15px;
}

.field-search {
    border: 0;
    font-family: Graphik-Semibold;
    font-size: 20px;
    width: 88%;
}

.container-result {
    margin: 10px 0
}

.container-result>p {
    font-family: Graphik-Semibold;
    font-size: 21px;
}

.tags-color {
    margin-top: 15px;
}

.tags-color>a {
    display: inline-block;
    border-radius: 4px;
    color: var(--color-dark);
    font-family: Graphik-Medium;
    font-size: 14px;
    padding: 10px 10px;
    margin: 5px;
    line-height: 1.2;
}

.tag-all {
    border: 2px solid var(--color-dark) !important;
    text-decoration: none;
}

.tag-all-active {
    background-color: var(--color-dark) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-all:hover {
    background-color: var(--color-dark) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-person,
.tag-personnes {
    border: 1px solid var(--color-person) !important;
}

.tag-person-active {
    background-color: var(--color-person) !important;
    border: 2px solid var(--color-person) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-person:hover,
.tag-personnes:hover {
    background-color: var(--color-person) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-damage,
.tag-dommages {
    border: 1px solid var(--color-damage) !important;
}

.tag-damage-active {
    background-color: var(--color-damage) !important;
    border: 2px solid var(--color-damage) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-damage:hover,
.tag-dommages:hover {
    background-color: var(--color-damage) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-economy,
.tag-économie {
    border: 1px solid var(--color-economy) !important;
}

.tag-economy-active {
    background-color: var(--color-economy) !important;
    border: 2px solid var(--color-economy) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-economy:hover,
.tag-économie:hover {
    background-color: var(--color-economy) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-entrepreneur,
.tag-entrepreneuriat {
    border: 1px solid var(--color-entrepreneur) !important;
}

.tag-entrepreneur-active {
    background-color: var(--color-entrepreneur) !important;
    border: 2px solid var(--color-entrepreneur) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-entrepreneur:hover,
.tag-entrepreneuriat:hover {
    background-color: var(--color-entrepreneur) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-health,
.tag-santé {
    border: 1px solid var(--color-health) !important;
}

.tag-health-active {
    background-color: var(--color-health) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-health:hover,
.tag-santé:hover {
    background-color: var(--color-health) !important;
    border: 2px solid var(--color-health) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-society,
.tag-société {
    border: 1px solid var(--color-society) !important;
}

.tag-society-active {
    background-color: var(--color-society) !important;
    border: 2px solid var(--color-society) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tag-society:hover,
.tag-société:hover {
    background-color: var(--color-society) !important;
    color: var(--color-white) !important;
    text-decoration: none !important;
}

.tags-detail-article,
.tags-detail-article>a {
    font-family: Graphik-Semibold;
    font-size: 11px;
    color: #8D8D8D;
}

.tags-detail-article>a:hover {
    color: var(--color-dark);
    text-decoration: none;
}

.title-article {
    font-family: Graphik-Semibold;
    font-size: 20px;
    color: var(--color-dark);
}

.subtitle-article {
    font-family: Graphik-Semibold;
    font-size: 21px;
    color: var(--color-dark);
    /*margin-top: 18px;*/
    line-height: 1.14;
}

.subtitle-article-14 {
    color: var(--color-dark);
    font-family: Graphik-Semibold;
    font-size: 14px;
    line-height: 1.21;
    margin-top: 14px;
}

.subtitle-article:hover,
.subtitle-article-14:hover {
    color: var(--color-dark);
    text-decoration: none;
}

.title-article:hover {
    text-decoration: none;
    color: var(--color-dark);
}

.title-person:hover,
.subtitle-person:hover {
    text-decoration: none;
    color: var(--color-person);
}

.title-damage:hover,
.subtitle-damage:hover {
    text-decoration: none;
    color: var(--color-damage);
}

.title-economy:hover,
.subtitle-economy:hover {
    text-decoration: none;
    color: var(--color-economy);
}

.title-entrepreneur:hover,
.subtitle-entrepreneur:hover {
    text-decoration: none;
    color: var(--color-entrepreneur);
}

.title-health:hover,
.subtitle-health:hover {
    text-decoration: none;
    color: var(--color-health);
}

.title-society:hover,
.subtitle-society:hover {
    text-decoration: none;
    color: var(--color-society);
}

/* PAGINATION */
.pagination {
    margin: 25px 0;
}

.pagination>span>a {
    background-color: #E7E7E7;
    color: var(--color-dark);
    padding: 2px 7px;
}

.pagination>span>a {
    text-decoration: none;
    font-size: 13px;
}

.pagination>span>.active {
    background-color: var(--color-dark);
    color: var(--color-white);
}

.gsemibold-15 {
    font-family: Graphik-Semibold;
    font-size: 15px;
}

.content-first-ad {
    background-color: var(--color-background-ad);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px 0 20px 0;
    max-height: 300px;
}

.image-list-articles {
    padding: 20px 0 20px 20px;
    min-height: 100%;
    max-height: 190px;
    object-fit: cover;
}

.position-initial {
    position: initial !important;
    bottom: initial !important;
  }