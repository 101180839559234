body {
    overflow-x: hidden;
}

.container-headlines {
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 15px;
    margin: 20px 0;
}

.second-ad {
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0 20px 0;
}



.add-ful-width {
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    background-color: #FFF;
}

.container-headlines .subtitle-article:first-child {
    font-size: 28px;
}

.title-section {
    color: var(--color-dark);
    font-family: Graphik-Semibold;
    font-size: 18px;
    line-height: 18px;
}

.title-section:hover {
    text-decoration: none;
    color: var(--color-dark);
}

.chip-number {
    background-color: red;
    color: #FFF;
    border-radius: 14px;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    font-family: Graphik-Semibold;
    font-size: 17px;
}

.container-img-title {
    display: flex;
    align-items: center;
    padding: 0;
}

.container-img-title > a > img {
    width: 60px;
    height: 60px;
    margin: 5px;
}

.center-chip {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*padding: 0;*/
}

.grey-line {
    border-top: 1px solid var(--color-grey-light);
    margin: 5px 0;
}

.separation-line {
    border-top: 2px solid var(--color-ad);
    width: 100%;
}

.container-thumbnail-headlines {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 50px;
    margin-top: 20px;
}

.dinamically-ad {
    grid-column-start: 1;
    grid-column-end: 3;
}

.content-profesional {
    /*background-color: #f2f2f2;*/
    grid-column-start: 5;
    grid-column-end: 7;
    border-left: 2px solid var(--color-grey-light);
    padding: 0 15px
}

.content-third-ad {
    background-color: #FFF;
    grid-column-start: 3;
    grid-column-end: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-leyend {
    /*bottom: 15px;*/
    font-size: 11px;
    margin-top: auto;
    /*position: absolute;*/
}

.icon-leyend > p {
    margin: 0;
    font-size: 11px;
    font-family: Graphik-Semibold;
    color: var(--color-grey-light);
}

.grey-tag {
    color: var(--color-grey-light);
    font-family: Graphik-Medium;
    font-size: 10px;
}

.load_button {
    margin-top: 15px;
    cursor: pointer;
}

.container-section-articles {
    display: inline-grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 10px;
    margin: 25px 0;
}

.container-section-articles > .article-item {
    height: 256px;
}

.container-section-articles > .article-item > .subtitle-article {
    font-size: 16px;
    line-height: 22px;
}

.container-section-articles > .article-item > p {
    font-family: 'Tinos', serif;
    font-weight: 400;
}


.most-read-articles {
    grid-column-start: 5;
    grid-column-end: 7;
}

.title-section-person {
    display: block;
    font-family: Graphik-Semibold;
    font-size: 16px;
    text-transform: uppercase;
}

.most-read-articles-homepage {
    padding: 15px 0;
    border-bottom: 1px solid lightgrey;
}

.most-read-articles-homepage > .subtitle-article {
    font-family: Graphik-Semibold;
    font-size: 14px;
    line-height: 1.21;
    margin-top: 14px;
}

.container-ad {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: lightgrey
}

.wrapper-magazine-section {
    display: flex;
    justify-content: center;
    width: initial !important;
    text-align: left !important;
}

.container-magazine {
    width: 40%;
    background-color: var(--color-dark);
    color: #FFF;
    display: flex;
    justify-content: flex-end;
    padding: 40px 0;
}

.container-data-magazine {
    width: 60%;
    color: #FFF;
    display: flex;
    justify-content: flex-start;
    padding: 40px 40px;
}

.container-img-magazine {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wrapper-magazine-section .magazine-image {
    max-width: 100%;
    object-fit: contain;
}

.white-button {
    background-color: #FFF;
    border-radius: 25px;
    color: #000;
    font-family: Graphik-Bold;
}

.title-img-magazine {
    font-family: Graphik-Medium;
    font-size: 40px;
    line-height: 38px;
}

.subtitle-img-magazine {
    font-family: Graphik-Medium;
    font-size: 18px;
}

.subtitle-info-magazine {
    font-family: Graphik-Medium;
    font-size: 28px;
    line-height: 32px;
    margin-top: 25px;
}

.wrapper-articles-magazine {
    font-family: Graphik-Medium;
    font-size: 16px;
    margin-top: 25px;
}

.tag-link:hover {
    text-decoration: none;
}

/* CC-Ads-Style */
// .CC-ads{
//     max-width: 360px;
//     border-left: 8px solid #189180;
// }

// .CC-ads-container .CC-ads-title{
//     font-family: Graphik-Medium;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
//     margin-bottom: 5px;
// }

// .CC-ads-container .CC-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 24px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #189180;
//     margin-bottom: 14px;
//     display: inline-block;
// }

// .CC-ads-container .CC-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1;
//     letter-spacing: normal;
// }

// .cc-text-xs{
//     font-size: 11px;
// }

// .cc-text-xs-alt{
//     font-size: 10px;
// }

// .CC-ads-container .CC-ads-btn, .CC-ads-container .CC-ads-btn2{
//     border-radius: 50px;
//     color: white;
//     padding: 6px 20px;
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 17.1px;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     text-decoration: none;
// }

// .cc-ads-text-sm{
//     font-size: 11px;
//     margin-bottom: 10px;
// }

// .CC-ads-container .CC-ads-btn{
//     background-color: #ff713f;
//     margin: 10px 0 5px 0;
// }

// .CC-ads-container .CC-ads-btn2{
//     background-color: #189180;
//     margin-top: 10px;
// }

// .CC-ads-container .CC-ads-text-container{
//     background-color: #eaedef;
//     margin-left: -34px;
//     padding: 8px 8px 8px 19px;
//     position: relative;
//     max-width: 346px;
//     min-height: 64px;
//     display: flex;
//     align-items: center;
// }
// .CC-ads-container .CC-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -8px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 8px solid #8d8d8d;
//     border-left: 11px solid transparent;
// }
// .CC-ads-container .CC-ads-text{
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     color: #2b625e;
//     margin-bottom: 0;
// }

// .CC-ads-container .CC-ads-price{
//     margin-top: 13px;
//     margin-bottom: 2px;
//     font-family: Graphik-Medium;
//     font-size: 11px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 20px;
//     letter-spacing: normal;
//     color: #2b2b2b;
// }

// .CC-ads-container .CC-ads-price-ext{
//     font-family: Graphik-Semibold;
//     font-size: 18px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 20px;
//     letter-spacing: normal;
//     color: #ff713f;
// }

/* CAP-Ads-Style */
// .CAP-ads{
//     max-width: 346px;
//     padding: 12px;
//     border-left: 8px solid #034e9d;
// }

// .CAP-ads-container .CAP-ads-title{
//     font-family: Graphik-Regular;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
// }

// .CAP-ads-container .CAP-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 29px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #034e9d;
//     margin-bottom: 20px;
//     display: inline-block;
// }

// .CAP-ads-container .CAP-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1;
//     letter-spacing: normal;
// }

// .CAP-ads-container .CAP-extra-text{
//     font-size: 12px;
// }

// .CAP-ads-container .CAP-ads-link a{
//     text-decoration: underline;
//     color: #034e9d !important;
// }

// .CAP-ads-container .CAP-ads-link-ext{
//     color: #2b2b2b;
// }

// .CAP-ads-container .CAP-ads-btn{
//     border-radius: 50px;
//     background-color: #013d7d;
//     color: white;
//     padding: 10px;
//     font-family: Graphik-Semibold;
//     font-size: 10px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.13;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     margin: 15px 0;
// }

// .CAP-ads-container .CAP-ads-text-container{
//     background-color: #7bfbff;
//     margin-left: -31px;
//     padding: 8px 8px 8px 19px;
//     position: relative;
//     max-width: 346px;
//     min-height: 64px;
//     display: flex;
//     align-items: center;
// }
// .CAP-ads-container .CAP-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -6px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 6px solid #52d6da;
//     border-left: 11px solid transparent;
// }
// .CAP-ads-container .CAP-ads-text{
//     font-family: Graphik-Semibold;
//     font-size: 15px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     color: #034e9d;
//     margin-bottom: 0;
// }

// .CAP-ads-container .CAP-ads-list{
//     margin-top: 19px;
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.4;
//     letter-spacing: normal;
//     color: #2b2b2b;
// }

/* CSC-Ads-Style */
// .CSC-ads{
//     max-width: 346px;
//     padding: 0 12px;
//     border-left: 8px solid #383e64;
// }

// .CSC-ads-container .CSC-ads-title{
//     font-family: Graphik-Regular;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: normal;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     text-transform: uppercase;
//     margin-bottom: 5px;
// }

// .CSC-ads-container .CSC-ads-subtitle{
//     font-family: 'Tinos', serif;
//     font-weight: 700;
//     font-size: 29px;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #383e64;
//     margin-bottom: 10px;
//     display: inline-block;
// }

// .CSC-ads-container .CSC-ads-link {
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1;
//     letter-spacing: normal;
//     color: #e1201d;
// }

// .CSC-ads-container .CSC-extra-text{
//     font-family: Graphik-RegularItalic;
//     font-size: 12px;
//     margin-bottom: 10px;
// }

// .CSC-ads-container .CSC-ads-link-ext{
//     color: #2b2b2b;
// }

// .CSC-ads-container .CSC-ads-btn, .CSC-ads-container .CSC-ads-btn-alt{
//     border-radius: 50px;
//     color: white;
//     padding: 10px;
//     font-family: Graphik-Semibold;
//     font-size: 10px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.13;
//     letter-spacing: normal;
//     text-align: center;
//     text-transform: uppercase;
//     display: inline-block;
//     margin: 10px 0;
// }

// .CSC-ads-container .CSC-ads-btn{
//     background-color: #383e64;
//     margin-bottom: 3px;
// }

// .CSC-ads-container .CSC-ads-btn-alt{
//     background-color: #e1201d;
// }

// .CSC-ads-container .CSC-ads-text-container{
//     background-color: #E0E1F0;
//     margin-left: -31px;
//     padding: 8px 8px 8px 19px;
//     position: relative;
//     max-width: 346px;
//     min-height: 56px;
//     display: flex;
//     align-items: center;
// }
// .CSC-ads-container .CSC-ads-text-container:after{
//     content: '';
//     position: absolute;
//     top: -6px;
//     left: 0;
//     width: 0;
//     height: 0;
//     border-bottom: 6px solid #878A9F;
//     border-left: 11px solid transparent;
// }
// .CSC-ads-container .CSC-ads-text{
//     font-family: Graphik-Semibold;
//     font-size: 14px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.38;
//     letter-spacing: normal;
//     color: #383e64;
//     margin-bottom: 0;
// }

// .CSC-ads-container .CSC-ads-list{
//     margin-top: 10px;
//     font-family: Graphik-Semibold;
//     font-size: 12px;
//     font-stretch: normal;
//     font-style: normal;
//     line-height: 1.4;
//     letter-spacing: normal;
//     color: #2b2b2b;
//     list-style: disc;
// }

// .CSC-ads-container .CSC-ads-list ul{
//     margin-bottom: 10px;
// }

// .border-none{
//     border: none !important;
// }

/* CC Promo on Articles page */
// .event-promo-v1-container, .event-promo-v2-container{
//     height: initial;
//     width: 100%;
//     position: fixed;
//     bottom: -100%;
//     transition: all ease 1800ms;
//     z-index: 100000000000000000;
// }

// .bg-cc-promo-v1{
//     background-image: linear-gradient(5deg, #20d19e -27%, #23a182 18%, #0b7f6f 67%);
// }

// .bg-cc-promo-v2{
//     background-color: #2b2b2b;
// }

// .cc-promo-v1, .cc-promo-v2{
//     position: relative;
// }

// .cc-promo-left{
//     margin-right: 21px;
// }

// .cc-promo-left .orange-box, .cc-promo-left .green-box{
//     width: 150px;
//     margin-top: -9.7px;
//     margin-bottom: 20px;
//     display: flex;
// }

// .cc-promo-left .orange-box{
//     background-color: #ff713f;
//     justify-content: center;
// }

// .cc-promo-left .green-box{
//     background-color: #189180;
//     align-items: center;
//     flex-direction: column;
// }

// .cc-promo-left .green-box .cc-promo-logo{
//     padding-bottom: 20px;
//     padding-top: 0;
// }

// .text-logo-green{
//     padding-top: 15px;
//     font-size: 15px;
//     margin-bottom: 8px;
// }

// .cc-promo-left .orange-box .cc-promo-logo{
//     padding-top: 29px;
//     padding-bottom: 21px;
// }

// .cc-promo-left .orange-box .cc-promo-logo img, .cc-promo-left .green-box .cc-promo-logo img{
//     height: 32px;
//     width: auto;
// }

// .cc-promo-left .cc-promo-price-container{
//     width: 150px;
//     color: white;
//     font-family: Graphik-Bold;
//     padding-left: 20px;
// }

// .cc-promo-left .cc-promo-price-container .price{
//     font-size: 26px;
//     margin-bottom: 0;
//     line-height: 14.5px;
// }

// .price-green{
//     color: #66ddb9 !important;
// }

// .cc-promo-left .cc-promo-price-container .price-text-sm{
//     font-size: 14.5px;
// }

// .cc-promo-middle{
//     display: none;
//     margin-right: 28px;
// }

// .cc-promo-middle .cc-promo-illustration{
//     height: 183px;
//     width: auto;
//     margin-top: -17.7px;
// }

// .cc-promo-right{
//     margin-top: 19px;
//     padding-left: 20px;
//     padding-right: 20px;
// }

// .cc-promo-right .cc-promo-title, .cc-promo-right .cc-promo-title-alt{
//     color: white;
//     font-family: Graphik-Semibold;
// }

// .cc-promo-right .cc-promo-title{
//     font-size: 25px;
//     text-align: center;
// }

// .cc-promo-right .cc-promo-title-alt{
//     font-size: 22px;
//     margin-bottom: 15px;
//     text-align: center;
// }


// .promo-content{
//     color: white;
//     display: flex;
//     margin-bottom: 20px;
// }

// .promo-content-alt{
//     flex-direction: column-reverse;
//     align-items: center;
// }

// .promo-content-alt .promo-list-right, .promo-content-alt .promo-list-left{
//     margin-bottom: 20px;
// }

// .promo-content-alt .promo-list-left{
//     width: 75%;
// }

// .promo-content ul {
//     font-size: 12px;
// }

// .promo-content .promo-list-left ul{
//     padding-left: 18px;
//     margin-bottom: 9px;
// }

// .promo-content .promo-list-right ul li:first-child{
//     margin-bottom: 0px;
// }

// .btn-sizing-custom, .btn-sizing-custom-alt{
//     text-align: center;
// }

// .btn-sizing-custom-alt{
//     padding: 4px 8px !important;
//     font-size: 13px !important;
// }

// .btn-sizing-custom{
//     padding: 4px 10px !important;
//     font-size: 12px !important;
//     line-height: 17px;
// }

// .cc-promo-v2-text{
//     padding-left: 0;
//     text-align: center;
// }

// .form-close{
//     position: absolute;
//     top: 10px;
//     right: 10px;
// }

// .btn-close{
//     background: none;
//     border: none;
//     padding: 0;
//     margin: 0;
//     cursor: pointer;
// }

// .btn-close:focus, .btn-close:active{
//     outline: none !important;
// }

// .close-x{
//     height: 16px;
//     width: 16px;
// }

// .justify-content-custom{
//     justify-content: center;
// }

// .jad-promo-flex-custom{
//     flex-direction: column;
// }
// .cc-promo-resp-top{
//     display: flex;
//     justify-content: center;
// }

// .visible-lg-custom{
//     display: none;
// }
/* JAD Promo on Articles page */

// .bg-jad-promo-v1{
//    background-image: linear-gradient(336deg, #ffaf00 55%, #ffcf00 75%);
// }

// .bg-jad-promo-v2{
//     background-color: #2b2b2b;
// }

// .jad-promo-v1, .jad-promo-v2{
//     position: relative;
// }

// .jad-promo-left{
//     margin-right: 21px;
// }

// .jad-promo-resp-top{
//     display: flex;
//     justify-content: center;
// }
// .jad-promo-logo{
//     height: 65px;
//     margin-top: 15px;
// }

// .jad-promo-middle .jad-promo-illustration{
//     width: auto;
//     height: 90px;
// }

// .jad-promo-middle .jad-promo-illustration-v2{
//     width: auto;
//     height: 90px;
//     margin-left: -95px;
// }

// .jad-promo-middle{
//     display: none;
//     margin-right: 28px;
// }

// .jad-box-price{
//     background-color: #2b2b2b;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: -6px 23px 18px 23px;
//     padding: 12px 0;
// }

// .jad-box-price-text{
//     font-size: 20px;
//     text-align: center;
//     margin-bottom: 0;
// }

// .jad-promo-right .jad-promo-title{
//     font-size: 24px;
//     text-align: left;
//     color: #34353a;
//     font-family: Graphik-Semibold;
// }

// .jad-content-text{
//     font-size: 15px;
//     color: #34353a;
//     font-family: Graphik-Medium;

// }

// .jad-promo-right{
//     margin-top: 19px;
//     margin-bottom: initial;
// }

// .box-yellow{
//     background-color: #ffae00;
//     padding: 25px 30px;
//     margin-top: -12px;
//     margin-bottom: 15px;
// }

// .jad-promo-logo-v2{
//     height: 65px;
// }

// .jad-box-price-v2{
//     margin-top: 26px;
//     margin-left: 12px;
// }

// .jad-box-price-v2-lg{
//     font-size: 24px;
//     line-height: 22px;
// }

// .jad-box-price-v2-sm{
//     font-size: 17.5px;
// }

// .jad-btn-cutom-text-color{
//     color: #34353a !important;
// }

// /* JAD-Ads-Style */
// .JAD-ads{
//     max-width: 638px;
//     padding: 12px 0;
// }

// .JAD-ads-container{
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 100%;
// }

// .jad-promo-sm-text{
//     font-size: 15px;
//     text-align: center;
// }

// .jad-promo-md-text{
//     font-size: 16px;
//     text-align: center;
// }

// .jad-date{
//     margin-bottom: 5px;
// }

// .jad-promo-subtitle{
//     text-transform: uppercase;
//     margin-bottom: 10px;
// }

// .jad-promo-footer{
//     padding: 25px;
//     background-color: #34353a;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
// }

// .jad-promo-footer-text{
//     font-size: 22px;
//     color: white;
//     text-align: center;
//     margin-bottom: 10px;
// }

// .jad-promo-title-fix{
//     font-size: 44px;
//     margin-bottom: 20px;
// }

// .jad-promo-price{
//     font-size: 24px;
//     text-align: center;
//     margin-bottom: 15px;
// }

/* Home Responsive */
/* =================================================================================================================== */

@media (max-width: 575.98px) {
    .container-headlines {
        grid-template-columns: 1fr;
    }

    .container-headlines .img-responsive {
        margin-bottom: 30px;
    }

    .container-thumbnail-headlines {
        display: initial;
    }
    .container-section-articles{
        display: initial;
    }

    .content-profesional {
        grid-column-start: 1;
        grid-column-end: 7;
        border-left: 0;
        padding: 0;
    }

    .img-article-item {
        height: 200px;
    }

    .icon-leyend {
        margin-top: 0;
    }

    .wrapper-title-section-person {
        grid-column-start: 1;
        grid-column-end: 7;
    }

    .most-read-articles {
        grid-column-start: 1;
        grid-column-end: 7;
    }

    /*  PROMO MAGAZINE SECTION  */
    .container-fluid{
        margin-right: 0;
        margin-left: 0;
        padding: 0;
    }

    .container-ad {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .wrapper-magazine-section {
        flex-direction: column;
    }

    .container-magazine {
        text-align: center;
        width: 100%;
    }

    .container-data-magazine {
        width: 100%;
        padding: 10px;
    }

    .container-img-magazine {
        height: 200px;
        margin-top: 25px;
    }

    .wrapper-articles-magazine > div {
        margin: 10px 0;
    }

    .container-magazine > div > .row {
        margin: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .img-article-item {
        height: 107px;
    }

    .container-headlines {
        grid-template-columns: 1fr 1fr;
    }

    .container-headlines .second-ad {
        grid-column: 1/3;
        margin-top: 30px;
    }

    .container-thumbnail-headlines, .container-extra-articles {
        grid-template-columns: repeat(2, 1fr);
    }

    .container-thumbnail-headlines .article-item {
        height: 276px;
    }

    .content-profesional, .content-third-ad {
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .ad-mobile {
        grid-column: span 2;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-section-articles {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .container-section-articles .wrapper-title-section-person {
        grid-column: 1/4;
        margin-bottom: 15px;
    }

    .container-section-articles .most-read-articles {
        grid-column-start: initial;
        grid-column-end: initial;
        grid-column: 1/4;
        margin-top: 15px;
    }

    .wrapper-magazine-section {
        flex-wrap: wrap;
    }

    .wrapper-magazine-section .container-magazine,
    .wrapper-magazine-section .container-data-magazine {
        width: 100%;
        justify-content: center;
    }

    /* TESTIG PURPOSE */
    /*body {*/
    /*    background-color: #FFF;*/
    /*}*/
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .img-article-item {
        height: 107px;
    }

    .container-headlines {
        grid-template-columns: 1fr 1fr;
    }

    .container-headlines .second-ad {
        grid-column: 1/3 !important;
        margin-top: 30px;
    }

    .container-thumbnail-headlines, .container-extra-articles {
        grid-template-columns: repeat(2, 1fr);
    }

    .container-thumbnail-headlines .article-item {
        height: 276px;
    }

    .content-profesional, .content-third-ad, .second-ad {
        grid-column: span 1 !important;
        overflow: hidden;
    }

    .container-section-articles {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .container-section-articles .wrapper-title-section-person {
        grid-column: 1/4;
        margin-bottom: 15px;
    }

    .container-section-articles .most-read-articles {
        grid-column-start: initial;
        grid-column-end: initial;
        grid-column: 1/4;
        margin-top: 15px;
    }

    .wrapper-magazine-section {
        flex-wrap: wrap;
    }


    .wrapper-magazine-section .container-magazine,
    .wrapper-magazine-section .container-data-magazine {
        width: 100%;
        justify-content: center;
    }

    /* TESTIG PURPOSE */
    /*body {*/
    /*    background-color: #FFF;*/
    /*}*/
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .img-article-item {
        height: 107px;
    }

    .wrapper-magazine-section .container-magazine {
        width: 45%;
    }

    .wrapper-magazine-section .container-data-magazine {
        width: 55%;
    }
}

@media (min-width: 1200px) {
    .img-article-item {
        height: 107px;
    }

    .container-headlines img {
        max-height: 300px;
        object-fit: cover;
    }

    .container-thumbnail-headlines .article-item {
        height: 276px;
        margin-bottom: 25px;
    }
}

@media (min-width: 1205px) {
    /* CC Promo Responsive */
    // .cc-promo-v1-container, .cc-promo-v2-container{
    //     height: 197.3px !important;
    // }

    // .cc-promo-left .orange-box, .cc-promo-left .green-box{
    //     width: 199px;
    //     margin-top: -11.7px;
    // }

    // .cc-promo-left .green-box .cc-promo-logo{
    //     padding-bottom: 31px;
    // }

    .text-logo-green{
        padding-top: 19px;
        font-size: 18px;
    }

    // .cc-promo-left .orange-box .cc-promo-logo{
    //     padding-top: 39px;
    //     padding-bottom: 31px;
    // }

    // .cc-promo-left .orange-box .cc-promo-logo img, .cc-promo-left .green-box .cc-promo-logo img{
    //     height: 42px;
    // }

    // .cc-promo-left .cc-promo-price-container{
    //     width: 199px;
    //     padding-left: 25px;
    // }

    // .cc-promo-left .cc-promo-price-container .price{
    //     font-size: 36px;
    //     line-height: 17.5px;
    // }

    // .cc-promo-left .cc-promo-price-container .price-text-sm{
    //     font-size: 17.5px;
    // }

    // .cc-promo-middle .cc-promo-illustration{
    //     height: 229px;
    //     margin-top: -31.7px;
    // }

    // .cc-promo-right{
    //     margin-top: 23.3px;
    // }

    // .cc-promo-right .cc-promo-title{
    //     font-size: 35px;
    // }

    // .cc-promo-right .cc-promo-title-alt{
    //     font-size: 29px;
    // }

    .promo-content ul {
        font-size: 16px;
    }

    .promo-content .promo-list-left ul{
        padding-left: 18px;
        margin-bottom: 12px;
    }

    .promo-content .promo-list-right ul li:first-child{
        margin-bottom: 12px;
    }

    .btn-sizing-custom{
        font-size: 16px !important;
        padding: 11px 22px !important;
    }

    .btn-sizing-custom-alt{
        font-size: 12px !important;
        padding: 8px 10px !important;
    }

    .form-close{
        top: 15px;
        right: 15px;
    }

    .close-x{
        height: 16px !important;
        width: 16px !important;
    }

    .justify-content-custom{
        justify-content: initial;
    }

    /* JAD Promo reponsive */
    // .jad-promo-middle .jad-promo-illustration{
    //     height: 129px;
    // }
    // .jad-promo-right{
    //     margin-top: auto;
    //     margin-bottom: auto;
    // }
    // .jad-promo-flex-custom{
    //     flex-direction: row;
    // }
    // .jad-promo-middle .jad-promo-illustration-v2{
    //     height: 154px !important;
    // }
}

@media (min-width: 900px) {
    .flex-custom-alt{
        flex-direction: row;
    }
    // .cc-promo-v1-container, .cc-promo-v2-container {
    //     height: 151px;
    // }
    // .cc-promo-right{
    //     margin-top: 19px;
    //     padding-left: 0 !important;
    //     padding-right: 0 !important;
    // }

    .close-x{
        height: 10px;
        width: 10px;
    }

    .promo-content-alt{
        flex-direction: row;
    }
    .promo-content-alt .promo-list-right, .promo-content-alt .promo-list-left{
        margin-bottom: 0;
        width: initial;
    }

    // .cc-promo-v2-text{
    //     padding-left: 20px;
    //     text-align: initial;
    // }

    // .cc-promo-right .cc-promo-title-alt{
    //     text-align: left;
    // }
    .visible-lg-custom{
        display: block;
    }

    // .cc-promo-right .cc-promo-title{
    //     text-align: initial;
    // }
}

// @media (min-width: 767px) {
//     /* JAD Promo Responsive */
//     .jad-promo-middle .jad-promo-illustration{
//         height: 120px;
//     }
//     .jad-promo-logo{
//         height: 91px;
//         margin-top: initial;
//     }
//     .jad-box-price-text{
//         font-size: 24px;
//     }
//     .jad-box-price {
//         margin: -6px 43px 21px 41px;
//     }
//     .jad-promo-middle .jad-promo-illustration-v2{
//         height: 130px;
//     }
// }

@media (min-width: 575px) {
    // .cc-promo-middle{
    //     display: block;
    // }

    // .cc-promo-right {
    //     margin-top: 19px;
    //     padding-left: 50px;
    //     padding-right: 50px;
    // }

    .promo-content-alt .promo-list-left{
        width: 60%;
    }

    /* JAD Promo Responsive */
    // .jad-promo-middle{
    //     display: block;
    // }
}

/* END Home Responsive */
/* =================================================================================================================== */



/* END Chip Animation */
/* =================================================================================================================== */

ul.dots * {
    /* disable border-box from bootstrap */
    box-sizing: content-box;
    list-style-type: none;
}

ul.dots a {
    text-decoration: none;
    font-size: 20px;
    color: #34495e;
}

ul.dots a:hover {
    text-decoration: none;
    font-size: 20px;
    color: #bdc3c7;
}

ul.dots li {
    /*display: box;*/
    position: relative;
    width: 100%;
    padding: 10px 0;
}

ul.dots li:hover {
    background: #2c3e50;
}

ul.dots li span {
    display: block;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #FFF;
    -webkit-box-shadow: 1px 1px 5px #808080;
    -moz-box-shadow: 1px 1px 5px #808080;
    box-shadow: 1px 1px 5px #808080;
    /*padding: 10px;*/
    /*width: 30px;*/
    /*height: 30px;*/
    margin: 0 auto;
    line-height: 30px;
    text-align: center;
    position: relative;
}

ul.dots li mark {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 2px solid #FFF;
    width: 30px;
    height: 30px;
    /*background-color: #FF6B6B;*/
    background-color: #ED1C24;
    position: absolute;
    top: -10px;
    left: -20px;
    font-size: 15px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #FFF;
    font-weight: 700;
}

ul.dots li mark.big {
    width: 30px;
    height: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    line-height: 30px;
    font-size: 16px;
    top: -10px;
    left: -15px;
}

ul.dots li mark.green {
    background-color: #27ae60;
}

ul.dots li mark.blue {
    background-color: #3498db;
}

ul.dots > li > a > span > mark {
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
    -webkit-transform-origin: center bottom;
    -ms-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
}

ul.dots > li:hover > a > span > mark {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}

ul.dots > li:hover > a > span > mark.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
}

ul.dots > li:hover > a > span > mark.swing {
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
    -webkit-animation-name: swing;
    animation-name: swing;
}

ul.dots > li:hover > a > span > mark.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
}

ul.dots > li:hover > a > span > mark.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
}

code {
    background: #ecf0f1;
}

/* animation keyframes */
@-webkit-keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%, 43% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@keyframes bounce {
    0%, 20%, 53%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    40%, 43% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -30px, 0);
        transform: translate3d(0, -30px, 0);
    }

    70% {
        -webkit-transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        transition-timing-function: cubic-bezier(0.755, 0.050, 0.855, 0.060);
        -webkit-transform: translate3d(0, -15px, 0);
        transform: translate3d(0, -15px, 0);
    }

    90% {
        -webkit-transform: translate3d(0, -4px, 0);
        transform: translate3d(0, -4px, 0);
    }
}

@-webkit-keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes bounceIn {
    0%, 20%, 40%, 60%, 80%, 100% {
        -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
    }

    0% {
        opacity: 1;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    20% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1);
        transform: scale3d(1.1, 1.1, 1.1);
    }

    40% {
        -webkit-transform: scale3d(.9, .9, .9);
        transform: scale3d(.9, .9, .9);
    }

    60% {
        opacity: 1;
        -webkit-transform: scale3d(1.03, 1.03, 1.03);
        transform: scale3d(1.03, 1.03, 1.03);
    }

    80% {
        -webkit-transform: scale3d(.97, .97, .97);
        transform: scale3d(.97, .97, .97);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@-webkit-keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }

    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }

    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

/* END Chip Animation */
/* =================================================================================================================== */
