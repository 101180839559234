@media (max-width: 575.98px) {
  /*.custom-container {*/
  /*margin: 5px;*/
  /*}*/

  .container-links {
    display: none;
  }

  .container-links.container-links-alt {
    display: none;
  }

  .custom-container {
    padding: 5px 15px;
  }

  .custom-container > #eja-header > header > .navmenu > ul {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > .search {
    width: 50px;
    display: flex;
    justify-content: center;
  }

  .custom-container
    > #eja-header
    > header
    > .navmenu
    > .search
    > .input-search {
    display: none;
  }

  .article-item-popular:not(:last-child) {
    margin-bottom: 30px;
  }

  .img-categories-most-read {
    display: none;
  }

  .search > i {
    font-size: 1.2em;
    margin-right: 0 !important;
  }

  .sidebar > ul:nth-child(5) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .btn-change-forfait {
    border-radius: 16px;
  }

  #annuler-subscription {
    left: 8px;
    width: 96%;
    margin-left: 0;
  }

  .custom-modal > i {
    right: -5px;
    top: -30px;
    font-size: 1.2em;
  }

  .custom-modal-300 {
    height: 380px;
  }

  #cancel-subscription {
    width: 96%;
    left: 8px;
    margin-left: 0;
  }

  .custom-modal-200 {
    height: 250px;
  }

  .btn-confirm {
    margin: 5px 5px;
  }

  .popular-articles {
    padding: 15px;
  }

  .tags-container {
    display: none;
  }

  .tags {
    display: none;
  }

  .tags-alt {
    display: block !important;
    margin: 10px 15px !important;
  }

  .tags-mobile {
    display: block;
  }

  .tags-color > a {
    display: inline-block;
    margin: 5px;
    padding: 5px 10px;
  }

  .container-articles-tags {
    display: block !important;
  }

  .container-articles-tags img {
    margin-bottom: 12px;
    width: 100%;
  }

  .container-articles-border {
    min-height: auto !important;
    height: 2px;
    width: 100%;
    border-right: none !important;
    border-bottom: 2px solid gray;
    margin-bottom: 5px;
  }

  .content-article > p > img {
    width: 100%;
  }

  .third-ads {
    display: none;
  }

  .footer-home {
    padding: 5px 15px;
  }

  .tags-mobile {
    display: block;
    margin-top: 15px;
  }

  .leyend-most-title {
    display: none;
  }

  .article-title {
    font-size: 26px;
  }

  /* ARTICLE DETAIL BIG PICTURE */
  .main-image > img {
    height: 250px;
    margin-top: 5px;
  }

  .main-image > p {
    display: none;
  }

  .container-complete-article {
    margin: 0;
    padding: 5px;
    top: 0;
  }

  /* LIST ARTICLES */
  .field-search {
    width: 50%;
  }

  .image-list-articles {
    padding: 0;
    margin-bottom: 25px;
    max-height: none;
    min-height: 0;
  }

  .separated-line {
    display: none;
  }

  .date-publication {
    display: block;
  }

  .subtitle-article:nth-child(1) {
    font-size: 21px !important;
  }

  .vertical-line {
    display: none;
  }

  .article-item-popular {
    height: 100%;
    max-height: 100%;
    padding-left: 15px;
  }

  .wrapper-articles * {
    max-width: initial;
  }

  .img-categories-most-read {
    height: 220px;
    max-height: 220px;
  }

  .content-headlines > div {
    padding: 0;
  }

  .img-article-item {
    height: 220px;
    max-height: 220px;
  }

  .buttons-share {
    width: auto;
    position: static !important;
    display: flex;
  }

  #subscription-section {
    img {
      margin-bottom: 10px;
    }
    p {
      font-size: 13px;
      margin-bottom: 10px !important;
      text-align: center;
    }
    h1 {
      font-size: 23px;
      text-align: center;
    }
    div,
    form {
      justify-content: center;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .custom-container {
    padding: 0 15px;
  }

  .container-links {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > ul {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > .search {
    width: 50px;
    display: flex;
    justify-content: center;
  }

  .custom-container
    > #eja-header
    > header
    > .navmenu
    > .search
    > .input-search {
    display: none;
  }

  .container-articles-tags {
    grid-template-columns: 1fr 2px 1fr 2px 1fr 2px 1fr !important;
  }

  .container-articles-tags > div:first-child {
    display: none;
  }

  .container-articles-tags > div:nth-child(2) {
    padding-left: 0 !important;
  }

  .container-articles-tags > div:last-child {
    padding-right: 0 !important;
  }

  .search > i {
    font-size: 1.2em;
    margin-right: 0 !important;
  }

  .main-article-item {
    margin-bottom: 30px;
  }

  .subtitle-article:nth-child(1) {
    font-size: 21px !important;
  }

  .wrapper-articles * {
    max-width: initial !important;
  }

  .buttons-share {
    width: auto;
    position: static !important;
    display: flex;
  }

  .tags-alt {
    margin-bottom: 15px !important;
  }

  .footer-container {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .footer-container > div {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (max-width: 782px) {
  .content-first-ad {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .custom-container {
    padding: 0 15px;
  }

  .container-links {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > ul {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > .search {
    width: 50px;
    display: flex;
    justify-content: center;
  }

  .custom-container
    > #eja-header
    > header
    > .navmenu
    > .search
    > .input-search {
    display: none;
  }

  .container-articles-tags {
    grid-template-columns: 1fr 2px 1fr 2px 1fr 2px 1fr !important;
  }

  .container-articles-tags > div:first-child {
    display: none;
  }

  .container-articles-tags > div:nth-child(2) {
    padding-left: 0 !important;
  }

  .container-articles-tags > div:last-child {
    padding-right: 0 !important;
  }

  .search > i {
    font-size: 1.2em;
    margin-right: 0 !important;
  }

  .main-article-item {
    margin-bottom: 30px;
  }

  .subtitle-article:nth-child(1) {
    font-size: 21px !important;
  }

  .wrapper-articles * {
    max-width: initial !important;
  }

  .tags-alt {
    margin-bottom: 15px !important;
  }

  .footer-container {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .footer-container > div {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .custom-container {
    padding: 0 15px;
  }

  .container-links {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > ul {
    display: none;
  }

  .custom-container > #eja-header > header > .navmenu > .search {
    width: 50px;
    display: flex;
    justify-content: center;
  }

  .custom-container
    > #eja-header
    > header
    > .navmenu
    > .search
    > .input-search {
    display: none;
  }

  .search > i {
    font-size: 1.2em;
    margin-right: 0 !important;
  }

  .tags-mobile {
    display: none;
    margin-top: 15px;
  }

  .popular-articles {
    padding: 50px;
  }

  .custom-i {
    width: 35px !important;
    height: 35px !important;
    line-height: 25px !important;
    margin-right: 5px !important;
    font-size: 14px !important;
  }
  
  .modal.cancel-subscription {
     left: 20% !important;
  }
}

@media (min-width: 1200px) {
  .popular-articles {
    /*padding: 50px;*/
    /*padding: 0 50px 0 50px;*/
  }

  .main-container {
    display: flex;
    justify-content: center;
  }

  .custom-container {
    width: 1200px;
    max-width: calc(100% - 30px);
  }

  .tags-mobile {
    display: none;
    margin-top: 15px;
  }

  .article-item-popular:nth-child(even) {
    padding-left: 0;
  }

  .img-categories-most-read {
    max-height: 132px;
    object-fit: cover;
    height: 120px;
  }

  .img-article-item {
    object-fit: cover;
    height: 103px;
    max-height: 103px;
  }

  .main-article-item img {
    height: 225px;
    max-height: 225px;
    object-fit: cover;
  }

  .content-headlines .article-item {
    height: 276px;
    margin-bottom: 25px;
  }
}

/* CC Promo Responsive */
@media (min-width: 1205px) {
  // .cc-promo-v1-container,
  // .cc-promo-v2-container {
  //   height: 197.3px !important;
  // }

  // .cc-promo-left .orange-box,
  // .cc-promo-left .green-box {
  //   width: 199px;
  //   margin-top: -11.7px;
  // }

  // .cc-promo-left .green-box .cc-promo-logo {
  //   padding-bottom: 31px;
  // }

  .text-logo-green {
    padding-top: 19px;
    font-size: 18px;
  }

  // .cc-promo-left .orange-box .cc-promo-logo {
  //   padding-top: 39px;
  //   padding-bottom: 31px;
  // }

  // .cc-promo-left .orange-box .cc-promo-logo img,
  // .cc-promo-left .green-box .cc-promo-logo img {
  //   height: 42px;
  // }

  // .cc-promo-left .cc-promo-price-container {
  //   width: 199px;
  //   padding-left: 25px;
  // }

  // .cc-promo-left .cc-promo-price-container .price {
  //   font-size: 36px;
  //   line-height: 17.5px;
  // }

  // .cc-promo-left .cc-promo-price-container .price-text-sm {
  //   font-size: 17.5px;
  // }

  // .cc-promo-middle .cc-promo-illustration {
  //   height: 229px;
  //   margin-top: -31.7px;
  // }

  // .cc-promo-right {
  //   margin-top: 23.3px;
  // }

  // .cc-promo-right .cc-promo-title {
  //   font-size: 35px;
  // }

  // .cc-promo-right .cc-promo-title-alt {
  //   font-size: 29px;
  // }

  .promo-content ul {
    font-size: 16px;
  }

  .promo-content .promo-list-left ul {
    padding-left: 18px;
    margin-bottom: 12px;
  }

  .promo-content .promo-list-right ul li:first-child {
    margin-bottom: 12px;
  }

  .btn-sizing-custom {
    font-size: 16px !important;
    padding: 11px 22px !important;
  }

  .btn-sizing-custom-alt {
    font-size: 12px !important;
    padding: 8px 10px !important;
  }

  .form-close {
    top: 15px;
    right: 15px;
  }

  .close-x {
    height: 16px !important;
    width: 16px !important;
  }

  .justify-content-custom {
    justify-content: initial;
  }
}

@media (min-width: 900px) {
  .flex-custom-alt {
    flex-direction: row;
  }
  // .cc-promo-v1-container,
  // .cc-promo-v2-container {
  //   height: 151px;
  // }
  // .cc-promo-right {
  //   margin-top: 19px;
  //   padding-left: 0 !important;
  //   padding-right: 0 !important;
  // }

  .close-x {
    height: 10px;
    width: 10px;
  }

  .promo-content-alt {
    flex-direction: row;
  }
  .promo-content-alt .promo-list-right,
  .promo-content-alt .promo-list-left {
    margin-bottom: 0;
    width: initial;
  }

  // .cc-promo-v2-text {
  //   padding-left: 20px;
  //   text-align: initial;
  // }

  // .cc-promo-right .cc-promo-title-alt {
  //   text-align: left;
  // }
  // .visible-lg-custom {
  //   display: block;
  // }

  // .cc-promo-right .cc-promo-title {
  //   text-align: initial;
  // }
}

@media (min-width: 575px) {
  // .cc-promo-middle {
  //   display: block;
  // }

  // .cc-promo-right {
  //   margin-top: 19px;
  //   padding-left: 50px;
  //   padding-right: 50px;
  // }

  .promo-content-alt .promo-list-left {
    width: 60%;
  }
}
