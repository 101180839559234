body {
    overflow-x: hidden;
}

.title-tino {
    font-family: 'Tinos', serif;
    font-weight: 700;
    font-size: 70px;
    line-height: 53px;
    width: 380px;
}

.container-header {
    margin-top: 75px;
    margin-bottom: 40px
}

.container-header img {
    height: 200px;
}

.subtitle-list {
    font-family: Graphik-Semibold;
    font-size: 19px;
    line-height: 26px;
    width: 550px
}

.wrapper-line {
    height: 100px;
    display: flex;
    flex-wrap: wrap
}

.container-line {
    padding: 0;
    display: flex;
    flex-direction: column-reverse
}

.container-line>div {
    border-bottom: 2px solid #8d8d8d;
    /*margin: 10px 0;*/
    margin-bottom: 10px;
}

.container-ad {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    background-color: #FFF;
}

.container-ad>div {
    height: 100%;
    width: 1200px;
    position: relative;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    padding: 10px 0 20px 0;
}

.container-tags {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: Graphik-Semibold;
    font-size: 11px;
    color: var(--color-tag);
    /*line-height: 36px;*/
}

.wrapper-tag {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.container-package {
    color: var(--color-tag);
    font-family: Graphik-Semibold;
    font-size: 11px;
    height: 16px;
}

.item-article {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 120px;
    margin-bottom: 15px
}

.custom-line {
    border-bottom: 1px solid var(--color-tag);
    margin-top: 15px;
}

.subtitle-article {
    font-size: 18px;
}

.subtitle-article-new-tags {
    color: #000;
    font-family: Graphik-Semibold;
    font-size: 20px;
    line-height: 1;
}


/* Professional Responsive */
/* =================================================================================================================== */
@media (max-width: 575.98px) {
    .container-header {
        margin: 25px 0;
    }

    .title-tino {
        font-size: 40px;
        width: 100%;
    }

    .subtitle-list {
        width: 100%;
    }

    .item-article {}

    .container-ad {
        width: 100%;
        left: 0;
        right: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .container-ad>div {
        width: 100%;
        left: 0;
        transform: translateX(0);
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
    .subtitle-article-new-tags {
        font-size: 16px;
        line-height: 1;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .subtitle-article-new-tags {
        font-size: 20px;
        line-height: 1;
    }
}

@media (min-width: 1200px) {
    .subtitle-article-new-tags {
        font-size: 25px;
        line-height: 32px
    }
}

/* END Professional Responsive */
/* =================================================================================================================== */