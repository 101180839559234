/* HELPERS */

//FONTS

$titlefont: 'Montserrat', sans-serif;
$textfont: 'Open Sans', sans-serif;
$fancyfont: 'Roboto Slab', sans-serif;


/* RESET STUFF
=========================== */

@mixin resetul{
    margin: 0;
    padding: 0;
    list-style: none;
}

.no-link{
    pointer-events: none;
    cursor: default;
}


/* MIXINS
=========================== */

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin transit($time){

    -webkit-transition: all $time ease;
    -moz-transition: all $time ease;
    -o-transition: all $time ease;
    transition: all $time ease;

}

/* ICONS
=========================== */

.white-circle {
    font-size: 70px;
    background-color: $white;
    padding: 30px;
    border-radius: 120px;
    margin-bottom: 20px;
}

/* STRUCTURE
=========================== */

.sectionpadding-sm{
    padding-top: 20px;
    padding-bottom: 20px;
}

.sectionpadding{
    padding-top: 40px;
    padding-bottom: 40px;
}

.sectionpadding-lg {
    padding-top: 70px;
    padding-bottom: 70px;
}

.blockpadding{
    padding: 45px 45px 35px;
}

.no-padding{
    padding: 0;
}

hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border: 0;
    border-top: 2px solid $lightgrey;
}

hr.tinyhr{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 2px solid $darkgrey;
    width: 20px;
    margin-left: 0;
}

.col-nopad{
    padding: 0;
}

.container-nopad{
    padding: 0;
}

.vcenter-parent{
    display: table;
    width: 100%;
    height: 100%;
}

.vcenter {
    display: table-cell;
    vertical-align: middle;
}

.color-bloc{
    padding: 45px;
}

.white-bloc{
  background-color: #fff;
}

.grey-bloc{
    background-color: $lightgrey;
}

.lightgrey-bloc{
    background-color: $lightergrey;
}

.black-bloc{
    background-color: $darkgrey;
    color: $lightgrey;
}

.big-bloc {
    padding: 45px;
    min-height: 485px;
    h2 {
        margin-bottom: 30px;
    }
    h3 {
        margin-bottom: 20px;
        line-height: 1.5;
    }
    p {
        margin-bottom: 20px;
    }
}

.wide-bar {
    .square {
      height: 150px;
      color: white;
      padding: 40px 10px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
    }
    .square:nth-child(1) {background-color: darken($lightgrey, 40%);}
    .square:nth-child(2) {background-color: darken($lightgrey, 35%);}
    .square:nth-child(3) {background-color: darken($lightgrey, 30%);}
    .square:nth-child(4) {background-color: darken($lightgrey, 25%);}
    .square:nth-child(5) {background-color: darken($lightgrey, 20%);}
    .square:nth-child(6) {background-color: darken($lightgrey, 15%);}
    .square:nth-child(7) {background-color: darken($lightgrey, 10%);}
}

.page-intro{
  .col-page-intro{
    background: $maincolor;
    h1, h2{
      color: #fff;
    }
    p{
      color: #fff;
    }
  }
}

.contact-member{
  margin-bottom: 85px;
  p{
    color: $grey;
  }
  .post{
    display: block;
    margin-bottom: 10px;
    font-family: $fancyfont;
    font-weight: 300;
    font-style: italic;
  }
  img{
    width: 100%;
    max-width: 200px;
    margin-bottom: 15px;
  }
}

.thumb-lock-icon{
  color: $ja-yellow;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  text-align: center;
  font-size: 19px;
  line-height: 40px;
}

.more-link{
  margin-top: 20px;
  a{
    color: $ja-red;
    font-weight: bold;
    font-size: 14px;
    &:hover{
      color: $title-black;
    }
  }
}
//Five columns

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-15 {
    width: 20%;
    float: left;
}

@media (min-width: 768px) {
    .col-sm-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 992px) {
    .col-md-15 {
        width: 20%;
        float: left;
    }
}
@media (min-width: 1200px) {
    .col-lg-15 {
        width: 20%;
        float: left;
    }
} 

//CAT TAGS

.cat-tag-lg{
    font-size: 14px;
    color: white;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    padding: 0 10px;
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    &.cat-tag-lg-green{
        background: $ja-green;
    }

    &.cat-tag-lg-orange{
        background: $ja-orange;
    }

    &.cat-tag-lg-blue{
        background: $ja-blue;
    }

    &.cat-tag-lg-yellow{
        background: $ja-yellow;
    }

    &.cat-tag-lg-red{
        background: $ja-red;
    }
    &.cat-tag-lg-financial-planning, &.cat-tag-lg-dommages{background: $ja-yellow;}
    &.cat-tag-lg-life-health-insurance, &.cat-tag-lg-vie-et-collectif{background: $ja-blue;}
    &.cat-tag-lg-investment, &.cat-tag-lg-investissement{background: $ja-orange;}
    &.cat-tag-lg-regulation, &.cat-tag-lg-reglementation{background: $ja-green;}
}


//MODALS

body.modal-open{
//    #content{
//      -webkit-filter: blur(2px);
//      -moz-filter: blur(2px);
//      -o-filter: blur(2px);
//      -ms-filter: blur(2px);
//      filter: blur(2px);
//    }
//    header{
//      -webkit-filter: blur(2px);
//      -moz-filter: blur(2px);
//      -o-filter: blur(2px);
//      -ms-filter: blur(2px);
//      filter: blur(2px);
//          padding-right: 15px;
//    }
//    #extra-nav{
//      -webkit-filter: blur(2px);
//      -moz-filter: blur(2px);
//      -o-filter: blur(2px);
//      -ms-filter: blur(2px);
//      filter: blur(2px);
//      padding-right: 15px;
//    }
}

.modal-alt{
    .modal-content{
        .modal-header {
          background-color: $maincolor;
          h4{
            color: #fff;
          }
        }

      .modal-footer{
        padding: 0;
        .btn{
          width: 100%;
          margin: 0 !important;
        }
      }

        box-shadow: none !important;
        border-radius: 0;
        h2{
            color: #fff;
            text-align: center;
            margin-bottom: 40px;
        }
        a{
            color: $maincolor;
            text-align: center;
        }
        p{
            text-align: center;
            margin-top: 35px;
        }
    }
    /* FORMS
    =========================== */
    .form-group ::-webkit-input-placeholder {
      color: $grey;
    }
    .form-group :-moz-placeholder {
      /* Firefox 18- */
      color: $grey;
    }
    .form-group ::-moz-placeholder {
      /* Firefox 19+ */
      color: $grey;
    }
    .form-group :-ms-input-placeholder {
      color: $grey;
    }
    .form-group .form-control:focus {
      border-bottom: 2px solid $black;
    }

    // Change chrome autocomplete input background
    input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill  {
        -webkit-box-shadow: 0 0 0 1000px $lightergrey inset !important;
        color: $grey !important;
    }

    .form-group{
      .form-control{
        font-size: 16px;
      }
    }
    .form-group input {
      border-bottom: 2px solid $black;
      color: #000;
    }
    .form-group textarea {
      border-bottom: 2px solid $black;
      color: #000;
    }
    .form-group select {
      border-bottom: 2px solid $black;
      color: #000;
    }
  #password{
    background-color: transparent;
  }

  .errorlist{
    font-size: 12px;
    margin: 0;
      padding: 0;
  }
}


//LIGHTBOX

.lightbox{
  position: fixed;
  top: 15px !important;
}

.lb-outerContainer{
    width: 80% !important;
    overflow-x: scroll;
      max-height: 900px;

}

.lb-image{
    width: 100% !important;
    height: auto !important;
  }

body:after {
  content: url(../images/close.png) url(../images/loading.gif) url(../images/prev.png) url(../images/next.png);
  display: none;
}

.lb-cancel {
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav a.lb-prev {
  background: url(../images/prev.png) left 48% no-repeat;
}

.lb-nav a.lb-next {
  background: url(../images/next.png) right 48% no-repeat;
}

.lb-data .lb-close {
  background: url(../images/close.png) top right no-repeat;
}

.container-sm {
  max-width: 750px;
}

.row-no-padding {
  margin: 0;
  [class*="col-"] {
  padding-left: 0 !important;
  padding-right: 0 !important;
  }
}

.margin-bottom-sm {
    margin-bottom: 25px !important;
}

.bg-grey-light{
  background-color: #f0f0f0;
}