//GENERAL

html {
  height: 100%;
}

small {
  font-size: 80%;
}

#content {
  padding-top: 115px;

  main {
    min-height: 800px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  &.casefix {
    text-transform: lowercase;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

h2 {
  font-size: 20px;
}

h2.alt {
  border-bottom: 1px solid $maincolor;
  padding-bottom: 20px;
  margin-bottom: 50px;
}

h3 {
  font-size: 20px;
}

.low-medium-text {
  font-size: 21px;
}

.medium-text {
  font-size: 22px;
}

.big-text {
  font-size: 35px;
}

.fancyfont {
  font-family: $fancyfont;
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin: 30px 0;
  font-weight: 300;
}

.ninja {
  display: none;
}

/* BUTTONS
=========================== */

//.btn{
//    border-radius: 0;
//    border-width: 0;
//    text-transform: uppercase;
//    font-size: 14px;
//    font-weight: 900;
//    @include transit(.6s);
//}

.btn-sm {
  font-size: 12px;
  padding: 10px 25px;
  @include transit(0.6s);
}

.btn-full {
  width: 100%;
}

.btn-primary {
  color: #fff;
  background-color: $maincolor;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: darken($maincolor, 10%) !important;
}

.btn-primary:hover {
  color: #fff;
  background-color: darken($maincolor, 10%);
}

.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: darken($maincolor, 10%);
}

.btn-black {
  color: #fff;
  background-color: $darkgrey;
}

.btn-black.focus,
.btn-black:focus {
  color: #fff;
  background-color: darken($darkgrey, 10%) !important;
}

.btn-black:hover {
  color: #fff;
  background-color: darken($darkgrey, 10%);
}

.btn-black.active,
.btn-black:active,
.open > .dropdown-toggle.btn-black {
  color: #fff;
  background-color: darken($darkgrey, 10%);
}

.btn-blue {
  color: #fff;
  background-color: $pale-blue;
}

.btn-blue.focus,
.btn-blue:focus {
  color: #fff;
  background-color: darken($pale-blue, 10%) !important;
}

.btn-blue:hover {
  color: #fff;
  background-color: darken($pale-blue, 10%);
}

.btn-blue.active,
.btn-blue:active,
.open > .dropdown-toggle.btn-blue {
  color: #fff;
  background-color: darken($pale-blue, 10%);
}

input {
  border: 3px solid #eee;
}

.help-block {
  strong {
    display: none;
  }
}

.errorlist {
  list-style: none;

  li {
    color: $maincolor;
  }
}

#new-content {
  label {
    width: initial;
    max-width: 180px;
    padding-left: 0;
  }

  input {
    float: right;
    width: 310px;
    padding-top: 0;
  }
}

.noscroll {
  overflow: hidden;

  header {
    padding-right: 0px;
  }
}

.crop {
  width: 100%;
  height: 125px;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
  }
}

.aftercrop {
  padding-top: 20px;
}

.home-title {
  font-size: 20px;
  margin: 15px 0 10px 0 !important;
  margin-top: 0 !important;
}

.badge-new {
  border-radius: 3px;
  margin: 0px 10px;
}

// MODAL //
.modal {
  z-index: 200050 !important;
}

.modal-backdrop {
  z-index: 200000 !important;
}

/* base-home.css */

/* LINKS BOX */

/* HAMBURGER ICON */

/* NAVBAR */

/* LI HOVER */

/* LI HIGHLIGHTED */

/* LI ACTIVE */

/* A HOVER */

/* A STYLE */

/* SIDEBAR */

/* LINK HOVER */

/* LINK HOVER */

/* MODAL */

/* FOOTER PAGE */

/* DETAIL ARTICLE */

/* end base-home.css */

/* responsive.css */
/* end responsive.css */
